/**
 * HelpsCreateDiag
 *
 */
const HelpsCreateDiagName = () => {
  return (
    <>
      Renseignez votre nom et prénom dans le champ prévus à cet éffet.
      
   
    </>
  );
};

const HelpsCreateDiagDiscriminating = () => {
  return (
    <>
    Mot clé discriminant (facultatif) :
    Si vous le souhaitez, vous pouvez ajouter un mot clé facultatif qui nous aidera à affiner votre recherche.
    Par exemple, si vous êtes préoccupé par la fraude, vous pouvez entrer "Fraude" dans ce champ.
    </>
  );
};
const HelpsCreateDiagWait = () => {
  return (
    <>
Veuillez patienter quelques instants :

Une fois que vous avez rempli les informations nécessaires, cliquez sur le bouton Continuer.
Notre puissante IA va rassembler et analyser les pages web qui vous concernent pour vous fournir un rapport détaillé.
    </>
  );
};

const HelpsCreateDiagHelp = () => {
  return (
    <>
N'hésitez pas à contacter notre équipe si vous avez des questions ou besoin d'assistance pendant le processus de diagnostic. Nous sommes là pour vous aider à obtenir les informations dont vous avez besoin !
    </>
  );
};

export { HelpsCreateDiagName, HelpsCreateDiagDiscriminating, HelpsCreateDiagWait, HelpsCreateDiagHelp };
