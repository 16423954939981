import React, { FC } from "react";
export type AuthContextState = {
  accessToken: string | null;
  selectUser: (arg0: string) => void;
};

const contextDefaultValues: AuthContextState = {
  accessToken: "",
  selectUser: () => {},
};

const getAccessTokenFromStorage = () => {
  const accessToken = localStorage.getItem("accessToken");
  return accessToken;
};

export const AuthContext = React.createContext(contextDefaultValues);

export const AuthProvider: FC<any> = ({ children }) => {
  const [accessToken, setAccessToken] = React.useState(
    getAccessTokenFromStorage()
  );

  const selectUser = (accessToken: string) => {
    localStorage.setItem("accessToken", accessToken);
    setAccessToken(accessToken);
  };

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        selectUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
