import * as Yup from "yup";
import { firstNameRules, lastNameRules, emailRules } from "./rules/UserRules";
import { messageRules } from "./rules/ContactRules";

const ContactSchema = Yup.object().shape({
  firstName: firstNameRules.required("validator:global.required"),
  lastName: lastNameRules.required("validator:global.required"),
  email: emailRules.required("validator:global.required"),
  message: messageRules.required("validator:global.required")
});

export default ContactSchema;