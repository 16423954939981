import { NavLink, useNavigate } from "react-router-dom";
import CoreLayout from "../../layouts/core-layout";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import CheckoutInfoSchema from "../../validators/CheckoutInfoSchema";
import CheckoutInfoInterface from "../../interfaces/CheckoutInfoInterface";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { getUserInfosRequest, updateUserInfosRequest } from '../../api/AuthApi' // Errors pages
//Multi language
import { useTranslation } from 'react-i18next';

const CheckoutInfo = () => {
  const { t } = useTranslation(); // Multi Language
  let navigate = useNavigate();
  const [user, setUser] = useState<any>();
  const [infos, setInfos] = useState<any>();
  let data = {
    firstName: "",
    lastName: "",
    phone: "",
    city: "",
    address: "",
    zipCode: "",
    country: "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<CheckoutInfoInterface>({
    mode: "onBlur",
    defaultValues: { ...user, ...infos },
    resolver: yupResolver(CheckoutInfoSchema),
  });

  useEffect(() => {
    getUserInfosRequest().then(function (res: any) {
      setUser(res.data.user);
      setInfos(res.data.infos);
      data.firstName = res.data.user.firstName;
      data.lastName = res.data.user.lastName;

      if (res.data.info) {
        data.phone = res.data.info.phone;
        data.city = res.data.info.city;
        data.address = res.data.info.address;
        data.zipCode = res.data.info.zipCode;
        data.country = res.data.info.country;
      }
      reset(data);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);


  let countries = [
    { label: "France", value: "fr", isDefault: true },
    { label: "German", value: "de", isDefault: false },
    { label: "English", value: "en", isDefault: false },
    { label: "Spain", value: "es", isDefault: false },
    { label: "Italiano", value: "it", isDefault: false },
  ];

  const [userQuotationsInfo, setUserQuotationsInfo] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    city: "",
    address: "",
    zipCode: 0,
    country: "",
    message: "",
  });

  localStorage.setItem("quotations-info", JSON.stringify(userQuotationsInfo));

  const onSubmit = (data: any) => {
    setUserQuotationsInfo(data);
    updateUserInfosRequest(data).then(function (res: any) {
      localStorage.setItem("quotations-info", JSON.stringify(data));
      return navigate("/order/checkout-confirmation");
    }).catch(() => {

      Swal.fire({
        title: "Oups nous rencontrons un problème, veuillez réessayer ultérieurement",
        icon: "error",
        showCancelButton: false,
        showConfirmButton: false,
        //buttons: false,
        timer: 5000
      });
    });
  };

  return (
    <CoreLayout template="main">
      <div className="card mb-5 mb-xl-10 mt-10">
        <div
          className="card-header border-0 cursor-pointer"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_account_profile_details"
          aria-expanded="true"
          aria-controls="kt_account_profile_details"
        >
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">{t('core:pages.shopping.cart.order.checkout-info.title')}</h3>
          </div>
        </div>
        <div className="collapse show">
          <form
            className="form w-100"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <div className="card-body border-top p-9">
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label required fw-bold fs-6">
                  {t('core:pages.account.AccountEdit.forms.input.FullName')}
                </label>
                <div className="col-lg-8">
                  <div className="row">
                    <div className="col-lg-6 fv-row">
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        placeholder={t('core:pages.account.AccountEdit.forms.input.firstName')}
                        defaultValue={user ? user.firstName : ""}
                        {...register("firstName")}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="firstName"
                        render={({ message }) => (
                          <p className="errorMsg">{t(message)}</p>
                        )}
                      />
                    </div>
                    <div className="col-lg-6 fv-row">
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        placeholder={t('core:pages.account.AccountEdit.forms.input.lastName')}
                        defaultValue={user ? user.lastName : ""}
                        {...register("lastName")}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="lastName"
                        render={({ message }) => (
                          <p className="errorMsg">{t(message)}</p>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="required">{t('core:pages.account.AccountEdit.forms.input.Phone')}</span>
                </label>
                <div className="col-lg-8 fv-row">
                  <input
                    type="tel"
                    className="form-control form-control-lg form-control-solid"
                    placeholder={t('core:pages.account.AccountEdit.forms.input.Phone')}
                    defaultValue={infos ? infos.phone : ""}
                    {...register("phone")}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="phone"
                    render={({ message }) => (
                      <p className="errorMsg">{t(message)}</p>
                    )}
                  />
                </div>
              </div>


              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="required">{t('core:pages.account.AccountEdit.forms.input.Address')}</span>
                </label>
                <div className="col-lg-8 fv-row">
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    placeholder={t('core:pages.account.AccountEdit.forms.input.Address')}
                    defaultValue={infos ? infos.address : ""}
                    {...register("address")}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="address"
                    render={({ message }) => (
                      <p className="errorMsg">{t(message)}</p>
                    )}
                  />
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="required">{t('core:pages.account.AccountEdit.forms.input.City')}</span>
                </label>
                <div className="col-lg-8 fv-row">
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    placeholder={t('core:pages.account.AccountEdit.forms.input.City')}
                    defaultValue={infos ? infos.city : ""}
                    {...register("city")}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="city"
                    render={({ message }) => (
                      <p className="errorMsg">{t(message)}</p>
                    )}
                  />
                </div>
              </div>
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="required">{t('core:pages.account.AccountEdit.forms.input.zipCode')}</span>
                </label>
                <div className="col-lg-8 fv-row">
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    placeholder={t('core:pages.account.AccountEdit.forms.input.zipCode')}
                    defaultValue={infos ? infos.zipCode : ""}
                    {...register("zipCode")}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="zipCode"
                    render={({ message }) => (
                      <p className="errorMsg">{t(message)}</p>
                    )}
                  />
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="required">{t('core:pages.account.AccountEdit.forms.input.SelectCountry')}</span>
                </label>
                <div className="col-lg-8 fv-row">
                  <select
                    {...register("country")}
                    aria-label={t('core:pages.account.AccountEdit.forms.input.SelectCountry')}
                    data-control="select2"
                    data-placeholder={t('core:pages.account.AccountEdit.forms.input.SelectCountry')}
                    className="form-select form-select-solid form-select-lg fw-bold"
                  >
                    <option value="">{t('core:pages.account.AccountEdit.forms.input.SelectCountry')}</option>
                    {countries.map((country) => (
                      <option value={country.value}>{country.label}</option>
                    ))}
                  </select>
                  <ErrorMessage
                    errors={errors}
                    name="country"
                    render={({ message }) => (
                      <p className="errorMsg">{t(message)}</p>
                    )}
                  />
                </div>
              </div>
              <div className="separator my-15"></div>

              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="required">{t('core:pages.shopping.cart.order.checkout-info.forms.input.message')}</span>
                </label>
                <div className="col-lg-8 fv-row">
                  {t('core:pages.shopping.cart.order.checkout-info.text')}
                  <div className="form-group mt-5">
                    <textarea
                      {...register("message")}
                      className="form-control"
                      style={{
                        minHeight: "300px",
                      }}
                    ></textarea>
                  </div>
                  <ErrorMessage
                    errors={errors}
                    name="message"
                    render={({ message }) => (
                      <p className="errorMsg">{t(message)}</p>
                    )}
                  />
                </div>
                
              </div>
              <p>*{t('core:pages.shopping.cart.order.checkout-info.rgpdSentence')}</p>
            </div>
            

            <div className="card-footer d-flex justify-content-end py-6 px-9">
              <NavLink
                to="/"
                className="btn btn-light btn-active-light-primary me-2"
              >{t('core:pages.shopping.cart.order.checkout-info.button.cancel')}
              </NavLink>
              <button type="submit" className="btn btn-success px-6">
                {t('core:pages.shopping.cart.order.checkout-info.button.continue')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </CoreLayout>
  );
};

export default CheckoutInfo;
