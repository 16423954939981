
/**
 * HelpsCreateDiag
 *
 */
const HelpsDashboard = () => {
    return (
      <>
         Vous avez accès à l'ensemble de vos <strong>diagnostics</strong>. Cliquez sur 'Voir' pour obtenir un aperçu de votre diagnostic réalisé
        
     
      </>
    );
  };
  
  
  
  export { HelpsDashboard };