import {
  getDiagnosisByUIIDRequest,
  meNotme,
} from "../../api/DiagnosisApi";
import { useNavigate, useParams } from "react-router-dom";
import CoreLayout from "../../layouts/core-layout";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

//Multi language
import { useTranslation } from "react-i18next";
import React from "react";
import ResultsLayout from "./results/results-layout";

const MeNotMe = () => {
  const { diagUUID } = useParams();
  const { t } = useTranslation(); // Multi Language
  const [localisations, setLocalisations] = useState<LocType[]>([]);
  const [organistations, setOrganisation] = useState<OrgType[]>([]);
  const [others, setOthers] = useState<OtherType[]>([]);
  const [persons, setPersons] = useState<PersonType[]>([]);
  const [associated, setAssociated] = useState<string[]>([]);
  let keywordChecked: any[] = [];
  let navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const res = await getDiagnosisByUIIDRequest(diagUUID);
      const response = res.data.data;
      setLocalisations(response.locKeyword);
      setOrganisation(response.orgKeyword);
      setPersons(response.perKeyword);
      setOthers(response.otherKeyword);
      return res.data.data;
    };
    fetchData();
  }, [diagUUID]);

  const [isCheck, setIsCheck] = useState(false);

  const handleChange = (event: {
    target: {
      [x: string]: string;
      checked: string;
    };
  }) => {
    if (event.target.checked) {
      setAssociated([...associated, event.target.value]);
    } else {
      let arrayFilter = associated.filter((el) => {
        return el !== event.target.value;
      });
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      keywordChecked = arrayFilter;
      setAssociated(arrayFilter);
    }

    setIsCheck((current) => !current);
  };

  const submitMeNotMe = async () => {
    const body = {
      meNotMeKeywords: associated,
      diagUuid: diagUUID as string,
    };
    const response = await meNotme(body);

    if (response.data.statusCode === 201) {
      setTimeout(() => {
        navigate(`/result/${diagUUID}/overview`);
      }, 1000);
    } else {
      console.log("UUID n'existe pas");
    }
  };

  return (
    <>
      <CoreLayout template="main">
        <ResultsLayout
          description={t("core:pages.osculteo.results.section.overview.text")}
        >
          <div className="card card-custom">
            <div className="card-header">
              <h3 className="card-title">{t("core:pages.osculteo.results.section.meNotMe.categorizedKeywords")}</h3>
            </div>
            <div className="card-body">
              {t("core:pages.osculteo.results.section.meNotMe.selectCategorizedKeywords")}
              <div className="row g-5 m-5">
                <div className="col">
                  <div className="card card-custom card-stretch mb-5">
                    <div className="card-header">
                      <h3 className="card-title">{t("core:pages.osculteo.results.section.meNotMe.person")}</h3>
                    </div>
                    <div className="card-body">
                      {persons.map((person) => (
                        <CheckBoxIsMe
                          word={person?.perKeyword}
                          value={isCheck}
                          onChange={handleChange}
                        />
                      ))}
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="card card-custom card-stretch mb-5">
                    <div className="card-header">
                      <h3 className="card-title">{t("core:pages.osculteo.results.section.meNotMe.locations")}</h3>
                    </div>
                    <div className="card-body">
                      {localisations.map((localisation) => (
                        <CheckBoxIsMe
                          word={localisation?.locKeyword}
                          value={isCheck}
                          onChange={handleChange}
                        />
                      ))}
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card card-custom card-stretch mb-5">
                    <div className="card-header">
                      <h3 className="card-title">{t("core:pages.osculteo.results.section.meNotMe.organizations")}</h3>
                    </div>
                    <div className="card-body">
                      {organistations.map((organisation) => (
                        <CheckBoxIsMe
                          word={organisation.orgKeyword}
                          value={isCheck}
                          onChange={handleChange}
                        />
                      ))}
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card card-custom card-stretch mb-5">
                    <div className="card-header">
                      <h3 className="card-title">{t("core:pages.osculteo.results.section.meNotMe.other")}</h3>
                    </div>
                    <div className="card-body">
                      {others.map((other) => (
                        <CheckBoxIsMe
                          word={other?.miscKeyword}
                          value={isCheck}
                          onChange={handleChange}
                        />
                      ))}
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="card-footer">
              <div className="d-flex flex-column-fluid flex-center">
                <Button className="btn btn-primary" onClick={submitMeNotMe}>
                  {" "}
                  {t("core:pages.osculteo.results.section.meNotMe.send")}
                </Button>
              </div>
            </div>
          </div>
        </ResultsLayout>
      </CoreLayout>
    </>
  );
};
export default MeNotMe;

const CheckBoxIsMe: React.FC<any> = (props: CheckBoxProp) => {
  return (
    <div className="form-check m-5">
      <input
        className="form-check-input"
        type="checkbox"
        value={props.word}
        onChange={props.onChange}
        id="flexCheckIndeterminate"
      />
      <label className="form-check-label" htmlFor="flexCheckIndeterminate">
        {props.word}
      </label>
    </div>
  );
};

type CheckBoxProp = {
  word: string;
  value: any;
  onChange: (event: {
    target: {
      checked: any;
    };
  }) => void;
};

type LocType = {
  urls: string[];
  locKeyword: string;
};

type OrgType = {
  urls: string[];
  orgKeyword: string;
};

type OtherType = {
  urls: string[];
  miscKeyword: string;
};

type PersonType = {
  urls: string[];
  perKeyword: string;
};
