import { AxiosServicesByName } from "./AxiosServices";

/**
 * getPrivateDataRequest
 * - recupere les privates data sur un diag existant
 * @param {*} payload
 * @return {*}
 */
export const getPrivateDataRequest = async (diagId: any, payload: any) => {
  return await AxiosServicesByName.post(`/private-data/private-data/${diagId}`, payload);
};
  
  
/**
 * getAllPrivateDataRequest
 * - recupere toute les privates data
 * @param {*} payload
 * @return {*}
 */
export const getAllPrivateDataRequest = async (diagUuid: string) => {
    return await AxiosServicesByName.get(`/private-data/all-private-data/${diagUuid}`);
  };


/**
 * getPrivateDataByuuidRequest
 * - recupere une private data a partir de son uuid
 * @param {*} payload
 * @return {*}
 */
export const getPrivateDataByuuidRequest = async (privateDataUuid: any) => {
  const response = await AxiosServicesByName.get(`/private-data/private-data-by-uuid/${privateDataUuid}`); 
    return response
  };


/**
 * getPrivateDataRemainingRequest
 * - retourne le nombre d eprivate data restante
 * @param {*} payload
 * @return {*}
 */
export const getPrivateDataRemainingRequest = async (payload: any) => {
    return await AxiosServicesByName.get("/private-data/private-data-remaining", payload);
  };