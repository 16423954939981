import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from "react-router-dom";
import CoreLayout from "../../layouts/core-layout";
import DirectUrlSchema from "../../validators/DirectUrlSchema"
import DirectUrlInterface from "../../interfaces/DirectUrlInterface";
import { useCart } from "react-use-cart";

//Multi language
import { useTranslation } from 'react-i18next';
import { createUrl } from "../../api/LinksApi";
import ResultsLayout from "./results/results-layout";

export default function DirectURL() {

  const { t } = useTranslation(); // Multi Language
  const { addItem } = useCart();

  let navigate = useNavigate();

  const { register, handleSubmit, formState: { errors } } = useForm<DirectUrlInterface>({
    //mode: "onBlur",
    resolver: yupResolver(DirectUrlSchema),
  });


  /**
   *  onSubmit
   * @desc On envoie le formulaire et donc on lance les call API.
   */
  const onSubmit = async (data: any) => {
    const create = await createUrl({url: data.url})
    addItem({
      id: create?.data.data.id,
      uuid:create?.data.data.uuid,
      type: "custom",
      url: create?.data.data.url,
      price: 0
    });

    return navigate("/cart");
  };

  return (
    <>
      <CoreLayout template="main">
      <ResultsLayout description="Libérez Votre Contrôle en Ligne avec la Demande de Suppression d'URL">
    
       
        <div>
          <div className="w-lg-1000px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto mt-10">
            <form
              className="form w-100"
              onSubmit={handleSubmit(onSubmit)}
              noValidate
            >
              <div className="text-center mb-10">
                <h1 className="text-dark mb-3">{t('core:pages.osculteo.DirectURL.title')}</h1>
              </div>
              <div className="mb-10">{t('core:pages.osculteo.DirectURL.text')}</div>
              <div className="fv-row mb-10">
                <label className="form-label fs-6 fw-bolder text-dark">
                  {t('core:pages.osculteo.DirectURL.forms.input.url.label')}
                </label>
                <input
                  required
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  id="url"
                  placeholder={t('core:pages.osculteo.DirectURL.forms.input.url.placeholder')}
                  autoFocus
                  {...register("url")}
                />
                <ErrorMessage
                  errors={errors}
                  name="url"
                  render={({ message }) => <p className="errorMsg">{t(message)}</p>}
                />
              </div>

              <div className="text-center">
                <button type="submit" className="btn btn-lg btn-primary w-100 mb-5">
                  {t('core:pages.osculteo.DirectURL.button')}
                </button>
              </div>
            </form>
          </div>
        </div>

        </ResultsLayout>
      </CoreLayout>
    </>
  );
}
