import * as Yup from "yup";
import { simplePasswordRules } from "./rules/PasswordRules";
import { emailRules } from "./rules/UserRules";

//*********************************************************
// Schema
//*********************************************************
const LoginSchema = Yup.object().shape({
  email: emailRules.required("validator:global.required"),
  password: simplePasswordRules.required("validator:global.required"),
});

export default LoginSchema;