import React, { useState } from 'react';

interface SwitchyButtonProps {
  activeClass?: string;
  inactiveClass?: string;
  activeText?: string;
  inactiveText?: string;
  minWidth?: number;
  defaultActive?: boolean;
  onChange?: (isActive: boolean) => void; // Ajout de la propriété onChange
}

const SwitchyButton: React.FC<SwitchyButtonProps> = ({
  activeClass = 'switchyButton-primary',
  inactiveClass = 'switchyButton-secondary',
  activeText = 'actif',
  inactiveText = 'inactif',
  minWidth = 80,
  defaultActive = true,
  onChange
}) => {
  const [isActive, setIsActive] = useState(defaultActive);

  const handleClick = () => {
    const newIsActive = !isActive;
    setIsActive(newIsActive);
    if (onChange) {
      onChange(newIsActive);
    }
  };

  return (
    <span
      className={`switchyButton ${isActive ? activeClass : inactiveClass}`}
      onClick={handleClick}
      style={{ minWidth: `${minWidth}px` }}
    >
      {isActive ? activeText : inactiveText}
    </span>
  );
};

export default SwitchyButton;