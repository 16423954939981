import axios, { AxiosRequestConfig, RawAxiosRequestHeaders } from "axios";
import { t } from "i18next";
import Swal from "sweetalert2";
import { acceptCgu } from "./AuthApi";

//***********************************************************************
// A U T H   U S E R
//***********************************************************************

export const AxiosServices = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

/**
 * Action au moment de l'envoie d'une requete
 */
// Fonctions utilitaires pour obtenir les headers
function getAuthToken() {
  return localStorage.getItem("accessToken");
}

function getClientId() {
  return process.env.REACT_APP_API_CLIENT_ID;
}

// Configuration de l'intercepteur
AxiosServices.interceptors.request.use(
  async (config) => {
    if (config.headers) {
      config.headers['Authorization'] = `Bearer ${localStorage.getItem("accessToken")}`;
      config.headers['client-id'] = `${process.env.REACT_APP_API_CLIENT_ID}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

/**
 * Action au moment de la réception d'une requete
 */
AxiosServices.interceptors.response.use(
  function (response) {
    return response; // ici en cas de success
  },
  function (error) {
    switch (error.response.status) {
      case 400:
      case 401:
        console.log("redirect to /errors/401");

        Swal.fire({
          title: "Oops !! You are disconnected.",
          text: "Now redirect to login page.",
          icon: "error",
          //backdrop: false,
          allowOutsideClick: false,
          confirmButtonText: "Go to Login",
          confirmButtonColor: "#f27474",
        }).then((result) => {
          if (result.isConfirmed) {
            document.location = "/logout";
          }
        });

        //navigate("/dashboard/")
        //document.location = '/logout';
        break;
      case 404:
        console.log("document.location /errors/404");
        //document.location = '/errors/404';
        break;
      case 500:
      default:
        console.log("document.location /errors/500");
        //document.location = '/errors/500';
        break;
    }
    return Promise.reject(error);
  }
);

//***********************************************************************
// G U E S T   U S E R
//***********************************************************************

export const AxiosServicesGuestUser = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "client-id": `${process.env.REACT_APP_API_CLIENT_ID}`,
  },
});

AxiosServicesGuestUser.interceptors.response.use(
  function (response) {
    return response; // ici en cas de success
  },
  function (error) {
    switch (error.response.data.error) {
      case "CgvNotAcceptedError":
        Swal.fire({
          title: "Mentions legales pas acceptées!",
          text: "acceptez nos mentions légales pour continuer sur l'app",
          icon: "error",
          confirmButtonText: "Aller accepter les cgv",
          confirmButtonColor: "#f27474",
        }).then((result) => {
          if (result.isConfirmed) {
            document.location = "/mentions/legal";
          }
        });
        console.log("AxiosServicesByName :  to /errors/401");
        break;
      case "UserNotFoundError":
        Swal.fire({
          title: error.response.data.error,
          text: error.response.data.message,
          icon: "error",
          confirmButtonText: "User not found",
          confirmButtonColor: "#f27474",
        }).then((result) => {
          if (result.isConfirmed) {
            document.location = "/login";
          }
        });
        console.log("AxiosServicesByName :  to /errors/401");
        break;
      case "WrongPasswordResetTokenError":
        Swal.fire({
          title: error.response.data.error,
          text: error.response.data.message,
          icon: "error",
          confirmButtonText: "User Password reset token",
          confirmButtonColor: "#f27474",
        }).then((result) => {
          if (result.isConfirmed) {
            document.location = "/login";
          }
        });
        console.log("AxiosServicesByName :  to /errors/401");
        break;
      case "WrongValidationToken":
        Swal.fire({
          title: error.response.data.error,
          text: error.response.data.message,
          icon: "error",
          confirmButtonText: "Wrong validation token",
          confirmButtonColor: "#f27474",
        }).then((result) => {
          if (result.isConfirmed) {
            document.location = "/login";
          }
        });
        console.log("AxiosServicesByName :  to /errors/401");
        break;
      case "InvalidUuidException":
        Swal.fire({
          title: error.response.data.error,
          text: error.response.data.message,
          icon: "error",
          confirmButtonText: "invalid uuid",
          confirmButtonColor: "#f27474",
        }).then((result) => {
          if (result.isConfirmed) {
            document.location = "/dasboard";
          }
        });
        console.log("AxiosServicesByName :  to /errors/401");
        break;
      case "MissingKeywordException":
        Swal.fire({
          title: error.response.data.error,
          text: error.response.data.message,
          icon: "error",
          confirmButtonText: "missing keyword",
          confirmButtonColor: "#f27474",
        }).then((result) => {
          if (result.isConfirmed) {
            document.location = "/dasboard";
          }
        });
        console.log("AxiosServicesByName :  to /errors/401");
        break;
      case "NoOfferForProductError":
        Swal.fire({
          title: error.response.data.error,
          text: error.response.data.message,
          icon: "error",
          confirmButtonText: "No offer coresponding to stripe product",
          confirmButtonColor: "#f27474",
        }).then((result) => {
          if (result.isConfirmed) {
            document.location = "/dasboard";
          }
        });
        console.log("AxiosServicesByName :  to /errors/401");
        break;
      case "OrderNotFoundError":
        Swal.fire({
          title: error.response.data.error,
          text: error.response.data.message,
          icon: "error",
          confirmButtonText: "Order not found",
          confirmButtonColor: "#f27474",
        }).then((result) => {
          if (result.isConfirmed) {
            document.location = "/dasboard";
          }
        });
        console.log("AxiosServicesByName :  to /errors/401");
        break;
      case "B2bPlatformUserValidationError":
        Swal.fire({
          title: error.response.data.error,
          text: error.response.data.message,
          icon: "error",
          confirmButtonText: "b2b client is invalid",
          confirmButtonColor: "#f27474",
        }).then((result) => {
          if (result.isConfirmed) {
            document.location = "/register";
          }
        });
        console.log("AxiosServicesByName :  to /errors/401");
        break;
      case "InvalidClientIdError":
        Swal.fire({
          title: error.response.data.error,
          text: "Invalid clientId",
          icon: "error",
          confirmButtonText: "Go to register",
          confirmButtonColor: "#f27474",
        }).then((result) => {
          if (result.isConfirmed) {
            document.location = "/register";
          }
        });
        console.log("AxiosServicesByName :  to /errors/401");
        break;
      case "JsonWebTokenError":
        Swal.fire({
          title: "Oops !! You are disconnected",
          text: error.response.data.message,
          icon: "error",
          confirmButtonText: "go to login",
          confirmButtonColor: "#f27474",
        }).then((result) => {
          if (result.isConfirmed) {
            document.location = "/login";
          }
        });
        console.log("AxiosServicesByName :  to /errors/401");
        break;
      case "TokenExpiredError":
        Swal.fire({
          title: "Oops !! You are disconnected",
          text: "please login",
          icon: "error",
          confirmButtonText: "go to login",
          confirmButtonColor: "#f27474",
        }).then((result) => {
          if (result.isConfirmed) {
            document.location = "/login";
          }
        });
        console.log("AxiosServicesByName :  to /errors/401");
        break;
      case "OldUserException":
        Swal.fire({
          title: "Mot de passe expiré",
          text: "merci de réinitialiser votre mot de passe",
          icon: "error",
          confirmButtonText: "Changer mon mot de passe",
          confirmButtonColor: "#f27474",
        }).then((result) => {
          if (result.isConfirmed) {
            document.location = "/forgot-password";
          }
        });
        console.log("AxiosServicesByName :  to /errors/401");
        break;
      case "MethodNotAllowedException":
        Swal.fire({
          title: t("guest:login.Swal.ValidateYourEmail.title"),
          text: t("guest:login.Swal.ValidateYourEmail.text"),
          cancelButtonText: t(
            "guest:login.Swal.ValidateYourEmail.cancelButtonText"
          ),
          icon: "error",
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonColor: "#f27474",
          //buttons: false,
          //timer: 1000
        }).then((result) => {
          if (result.isConfirmed) {
            document.location = "/login";
          }
        });
        console.log("AxiosServicesByName :  to /errors/405");
        break;

      case "QueryFailedError":
        Swal.fire({
          title: "Fail to register",
          text: "this email already exist",
          icon: "error",
          confirmButtonText: "go to login",
          confirmButtonColor: "#f27474",
        }).then((result) => {
          if (result.isConfirmed) {
            document.location = "/login";
          }
        });
        console.log("AxiosServicesByName :  to /errors/401");
        break;
      case "UnauthorizedException":
        Swal.fire({
          title: t("guest:login.Swal.WrongLoginOrPassword.title"),
          text: t("guest:login.Swal.WrongLoginOrPassword.text"),
          cancelButtonText: t(
            "guest:login.Swal.WrongLoginOrPassword.cancelButtonText"
          ),
          icon: "error",
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonColor: "#f27474",
          //buttons: false,
          //timer: 1000
        });
        break;
      case "PasswordResetError":
        // Swal.fire({
        //   title: t("guest:ResetPassword.Swal.title"),
        //   text: t("guest:ResetPassword.Swal.text"),
        //   cancelButtonText: t(
        //     "guest:ResetPassword.Swal.cancelButtonText"
        //   ),
        //   icon: "success",
        //   showCancelButton: true,
        //   showConfirmButton: false,
        //   cancelButtonColor: "#f27474",
        //   //buttons: false,
        //   //timer: 1000
        // });
        break;

      default:
        Swal.fire({
          title: error.response.data.error,
          text: error.response.data.message,
          icon: "error",
          confirmButtonText: "Close",
          confirmButtonColor: "#f27474",
        });
        console.log("error 500");
        //document.location = '/errors/500';
        break;
    }
    return Promise.reject(error);
  }
);

//***********************************************************************
// BY NAME
//***********************************************************************

export const AxiosServicesByName = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "client-id": `${process.env.REACT_APP_API_CLIENT_ID}`,
  },
});

AxiosServicesByName.interceptors.request.use(
  async (config) => {
    if (config.headers) {
      config.headers['Authorization'] = `Bearer ${localStorage.getItem("accessToken")}`;
      config.headers['client-id'] = `${process.env.REACT_APP_API_CLIENT_ID}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
/**
 * Action au moment de la réception d'une requete
 */
AxiosServicesByName.interceptors.response.use(
  function (response) {
    return response; // ici en cas de success
  },
  function (error) {
    //console.log(error.response.data);

    switch (error.response.data.error) {
      case "CgvNotAcceptedError":
        Swal.fire({
          title: error.response.data.error,
          text: error.response.data.message,
          icon: "error",
          confirmButtonText: "Go to accept Cgv",
          confirmButtonColor: "#f27474",
        }).then((result) => {
          if (result.isConfirmed) {
            document.location = "/dashboard";
          }
        });
        console.log("AxiosServicesByName :  to /errors/401");
        break;
      case "CguNotAcceptedError":
        Swal.fire({
          title: "Nouvelles CGU",
          html: "Merci d'accepter les nouvelles conditions d'utilisations <br/><a href='/legals/cgu.html' target='blank'>voir les conditions d'utilisations</a>",
          icon: "error",
          confirmButtonText: "accepter",
          confirmButtonColor: "#f27474",
        }).then(async (result) => {
          await acceptCgu().then((res) => console.log(res));
          if (result.isConfirmed) {
            document.location = "/dashboard";
            setTimeout(() => {});
          }
        });
        console.log("AxiosServicesByName :  to /errors/401");
        break;
      case "UserNotFoundError":
        Swal.fire({
          title: error.response.data.error,
          text: error.response.data.message,
          icon: "error",
          confirmButtonText: "User not found",
          confirmButtonColor: "#f27474",
        }).then((result) => {
          if (result.isConfirmed) {
            document.location = "/login";
          }
        });
        console.log("AxiosServicesByName :  to /errors/401");
        break;
      case "WrongPasswordResetTokenError":
        Swal.fire({
          title: error.response.data.error,
          text: error.response.data.message,
          icon: "error",
          confirmButtonText: "User Password reset token",
          confirmButtonColor: "#f27474",
        }).then((result) => {
          if (result.isConfirmed) {
            document.location = "/login";
          }
        });
        console.log("AxiosServicesByName :  to /errors/401");
        break;
      case "WrongValidationToken":
        Swal.fire({
          title: error.response.data.error,
          text: error.response.data.message,
          icon: "error",
          confirmButtonText: "Wrong validation token",
          confirmButtonColor: "#f27474",
        }).then((result) => {
          if (result.isConfirmed) {
            document.location = "/login";
          }
        });
        console.log("AxiosServicesByName :  to /errors/401");
        break;
      case "InvalidUuidException":
        Swal.fire({
          title: error.response.data.error,
          text: error.response.data.message,
          icon: "error",
          confirmButtonText: "invalid uuid",
          confirmButtonColor: "#f27474",
        }).then((result) => {
          if (result.isConfirmed) {
            document.location = "/dasboard";
          }
        });
        console.log("AxiosServicesByName :  to /errors/401");
        break;
      case "MissingKeywordException":
        Swal.fire({
          title: error.response.data.error,
          text: error.response.data.message,
          icon: "error",
          confirmButtonText: "missing keyword",
          confirmButtonColor: "#f27474",
        }).then((result) => {
          if (result.isConfirmed) {
            document.location = "/dasboard";
          }
        });
        console.log("AxiosServicesByName :  to /errors/401");
        break;
      case "NoOfferForProductError":
        Swal.fire({
          title: error.response.data.error,
          text: error.response.data.message,
          icon: "error",
          confirmButtonText: "No offer coresponding to stripe product",
          confirmButtonColor: "#f27474",
        }).then((result) => {
          if (result.isConfirmed) {
            document.location = "/dasboard";
          }
        });
        console.log("AxiosServicesByName :  to /errors/401");
        break;
      case "OrderNotFoundError":
        Swal.fire({
          title: error.response.data.error,
          text: error.response.data.message,
          icon: "error",
          confirmButtonText: "Order not found",
          confirmButtonColor: "#f27474",
        }).then((result) => {
          if (result.isConfirmed) {
            document.location = "/dasboard";
          }
        });
        console.log("AxiosServicesByName :  to /errors/401");
        break;
      case "B2bPlatformUserValidationError":
        Swal.fire({
          title: error.response.data.error,
          text: error.response.data.message,
          icon: "error",
          confirmButtonText: "b2b client is invalid",
          confirmButtonColor: "#f27474",
        }).then((result) => {
          if (result.isConfirmed) {
            document.location = "/register";
          }
        });
        console.log("AxiosServicesByName :  to /errors/401");
        break;
      case "InvalidClientIdError":
        Swal.fire({
          title: error.response.data.error,
          text: "Invalid clientId",
          icon: "error",
          confirmButtonText: "Go to register",
          confirmButtonColor: "#f27474",
        }).then((result) => {
          if (result.isConfirmed) {
            document.location = "/register";
          }
        });
        console.log("AxiosServicesByName :  to /errors/401");
        break;
      case "JsonWebTokenError":
        Swal.fire({
          title: "Oops !! You are disconnected",
          text: "please login",
          icon: "error",
          confirmButtonText: "go to login",
          confirmButtonColor: "#f27474",
        }).then((result) => {
          if (result.isConfirmed) {
            document.location = "/login";
          }
        });
        console.log("AxiosServicesByName :  to /errors/401");
        break;
      case "TokenExpiredError":
        Swal.fire({
          title: "Oops !! You are disconnected",
          text: "please login",
          icon: "error",
          confirmButtonText: "go to login",
          confirmButtonColor: "#f27474",
        }).then((result) => {
          if (result.isConfirmed) {
            document.location = "/login";
          }
        });
        console.log("AxiosServicesByName :  to /errors/401");
        break;
      case "QueryFailedError":
        Swal.fire({
          title: "Fail to register",
          text: "this email already exist",
          icon: "error",
          confirmButtonText: "go to login",
          confirmButtonColor: "#f27474",
        }).then((result) => {
          if (result.isConfirmed) {
            document.location = "/login";
          }
        });
        console.log("AxiosServicesByName :  to /errors/401");
        break;
      case "OldUserException":
        Swal.fire({
          title: "Mot de passe expiré",
          text: "merci de réinitialiser votre mot de passe",
          icon: "error",
          confirmButtonText: "Changer mon mot de passe",
          confirmButtonColor: "#f27474",
        }).then((result) => {
          if (result.isConfirmed) {
            document.location = "/forgot-password";
          }
        });
        console.log("AxiosServicesByName :  to /errors/401");
        break;

      case "MaxPrivateDataException":
        Swal.fire({
          title: "Vous n'avez plus de donnée privée disponible",
          text: "pour avoir plus de donnée privée, vous pouvez souscrire à l'offre premium",
          icon: "error",
          confirmButtonText: "voir l'offre Osculteo Premium ",
          confirmButtonColor: "#f27474",
        }).then((result) => {
          if (result.isConfirmed) {
            document.location = "/offers";
          }
        });
        console.log("AxiosServicesByName :  to /errors/401");
        break;

      default:
        Swal.fire({
          title: error.response.data.error,
          text: error.response.data.message,
          icon: "error",
          confirmButtonText: "Close",
          confirmButtonColor: "#f27474",
        });
        console.log("error 500");
        //document.location = '/errors/500';
        break;
    }
    return Promise.reject(error);
  }
);

export default AxiosServices;
