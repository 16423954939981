import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';


const detectionOptions = {
  order: ['localStorage', 'navigator'],
  caches: ['localStorage'],
  lookupLocalStorage: 'i18nextLng'
}

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector) // Pour une detection du language
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .use(resourcesToBackend((language, namespace, callback) => {
    import(`./locales/${language}/${namespace}.json`)
      .then((resources) => {
        callback(null, resources)
      })
      .catch((error) => {
        callback(error, null)
      })
  }))
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  //.use(ChainedBackend)
  .init({
    detection: detectionOptions,
    wait: true,
    // backend: {
    //   loadPath: './locales/{{lng}}/{{ns}}.json', // for all available options read the backend's repository readme file
    //   allowMultiLoading:true
    // },
    ns: [ "guest", "validator", "core", "faq", "helps", "generic", "errors","chatbot"],
    nsSeparator: ':',
    react: {
      wait: true
    },
    fallbackLng: ["en", "fr"],
    supportedLngs: ['en','fr'],
    debug: false,
    //preload:true,
    //lng: "fr",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  }).then(function (t) {
  console.log(t('Translator is ready'));
  });

export default i18n;


