import * as React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import CoreLayout from '../../layouts/core-layout';

// Gestion du formulaire
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import ForgotPasswordSchema from "../../validators/ForgotPasswordSchema"
import ForgotPasswordInterface from "../../interfaces/ForgotPasswordInterface"

import { authForgotPasswordRequest } from "../../api/AuthApi";

//Multi language
import { useTranslation } from 'react-i18next';

export default function ForgotPassword() {
  const { t } = useTranslation(); // Multi Language
  let navigate = useNavigate(); //Permet de changer de page après validation
  const { register, handleSubmit, formState: { errors } } = useForm<ForgotPasswordInterface>({
    mode: "onBlur",
    resolver: yupResolver(ForgotPasswordSchema),
  });

  const onSubmit = (data: any) => {
    authForgotPasswordRequest(data)
      .then(function (response: any) {
      })
      .catch(function (err: any) {
        console.log(`forgotPassword: ${err}`);
      });
    navigate('/forgot-password-confirm');
  }


  return (
    <>
      <CoreLayout template="auth">
        <div className="">
          <div className="platform-topBar w-lg-500px mx-auto"></div>
          <div className="w-lg-500px bg-body shadow-sm p-10 p-lg-15 mx-auto">
            <div className='text-center platform-logoBlock mb-10' ><img alt="" src={`${process.env.REACT_APP_CDN_PLATFORMS}/logo.png`} className='platform-auth-logo' /></div>
            <form className="form w-100" onSubmit={handleSubmit(onSubmit)} noValidate>
              <div className="text-center mb-10">
                <h1 className="text-dark mb-3"> {t('guest:ForgotPassword.title')}</h1>
                <div className="text-gray-400 fw-bold fs-4">{t('guest:ForgotPassword.subtitle')}</div>
              </div>
              <div className="fv-row mb-10">
                <label className="form-label fw-bolder text-gray-900 fs-6">{t('guest:ForgotPassword.forms.input.email')}</label>
                <input className="form-control form-control-solid" type="email" placeholder={t('guest:ForgotPassword.forms.input.email')} autoComplete="email" required id="email" autoFocus     {...register("email")} />
                <ErrorMessage
                  errors={errors}
                  name="email"
                  render={({ message }) => <p className="errorMsg">{t(message)}</p>}
                />
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="btn btn-lg btn-primary w-100 mb-5"
                >
                  {t('guest:ForgotPassword.continue')}
                </button>
                <div className="mt-5"><NavLink to="/login" className="text-primary" >{t('guest:ForgotPassword.BackToLogin')}</NavLink></div>
              </div>
            </form>
          </div>
        </div>
      </CoreLayout>
    </>
  );
}
