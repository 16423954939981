/* eslint-disable array-callback-return */
import { useQuery } from "react-query";
import { NavLink, useNavigate } from "react-router-dom";
import { getUserRequest } from "../../api/AuthApi";
import CoreLayout from "../../layouts/core-layout";
import { useTranslation } from "react-i18next";
import { useCart } from "react-use-cart";
import { createOrderRequest } from "../../api/OrderApi";
import { useState } from "react";
import { Button, ButtonGroup, ToggleButton } from "react-bootstrap";


const CheckoutConfirmation = () => {
  const [userEmail, setUserEmail] = useState<string>("");
  const navigate = useNavigate();

  const { t } = useTranslation(); // Multi Language
  const { data } = useQuery("user", async () => {
    const response = await getUserRequest();
    setUserEmail(response.data.email);
    return response;
  });
  const quotationsInfo = JSON.parse(
    localStorage.getItem("quotations-info") || "vide"
  );
  const { items, emptyCart } = useCart();

  // solution pour eviter les effets de bords


  const submitQuotation = async () => {
    const linkToDelete = [];
    const imageToDelete = [];
    const suugestToDelete = [];
    const urlToDelete = [];
    for (let item of items) {
      if (item.type === "link") {
        linkToDelete.push(item);
      }
      if (item.type === "image") {
        imageToDelete.push(item);
      }
      if (item.type === "suggest") {
        suugestToDelete.push(item);
      }
      if (item.type === "custom") {
        urlToDelete.push(item);
      }
    }
    const payload = {
      ...quotationsInfo,
      linkToDelete: linkToDelete,
      imageToDelete: imageToDelete,
      urlToDelete: urlToDelete,
      suugestToDelete: suugestToDelete,
    };
    const response = await createOrderRequest(payload);

    if (response.data.statusCode === 201) emptyCart();

    navigate("/order/checkout-success", {
      state: {
        ref: response.data.data?.quotationref,
        sentence: response.data.data?.sentencePerso,
        contact: response.data.data?.phoneContact
      },
    });
  };

  const [radioValue, setRadioValue] = useState("1");
  const radios = [
    { name: "Liens", value: "1" },
    { name: "Images", value: "2" },
    // { name: "Suggests ", value: "3" },
    { name: "Urls personalisées", value: "4" },
  ];
  return (
    <>
      <CoreLayout template="main">
        <div className="row mt-10">
          <div className="col-md-4">
            <div className="mb-10">
              <h2>
                {t(
                  "core:pages.shopping.cart.order.checkout-confirmation.billingInformation"
                )}
              </h2>
            </div>
            <div className="card">
              <div className="card-body p-9">
                <div className="row mb-7">
                  <label className="col-lg-4 fw-bold text-muted">
                    {t("core:pages.account.AccountEdit.forms.input.FullName")}
                  </label>
                  <div className="col-lg-8">
                    <span className="fw-bolder fs-6 text-gray-800">
                      {quotationsInfo.firstName} {quotationsInfo.lastName}
                    </span>
                  </div>
                </div>
                <div className="row mb-7">
                  <label className="col-lg-4 fw-bold text-muted">
                    {t("core:pages.account.AccountEdit.forms.input.Phone")}
                  </label>
                  <div className="col-lg-8 d-flex align-items-center">
                    <span className="fw-bolder fs-6 text-gray-800 me-2">
                      {quotationsInfo.phone}
                    </span>
                  </div>
                </div>
                <div className="row mb-7">
                  <label className="col-lg-4 fw-bold text-muted">
                    {t("core:pages.account.AccountEdit.forms.input.Email")}
                  </label>
                  <div className="col-lg-8 d-flex align-items-center">
                    <span className="fw-bolder fs-6 text-gray-800 me-2">
                      {userEmail}
                    </span>
                  </div>
                </div>
                <div className="row mb-7">
                  <label className="col-lg-4 fw-bold text-muted">
                    {t("core:pages.account.AccountEdit.forms.input.Address")}
                  </label>
                  <div className="col-lg-8 d-flex align-items-center">
                    <span className="fw-bolder fs-6 text-gray-800 me-2">
                      {quotationsInfo.address}
                    </span>
                  </div>
                </div>
                <div className="row mb-7">
                  <label className="col-lg-4 fw-bold text-muted">
                    {t("core:pages.account.AccountEdit.forms.input.City")}
                  </label>
                  <div className="col-lg-8 d-flex align-items-center">
                    <span className="fw-bolder fs-6 text-gray-800 me-2">
                      {quotationsInfo.city} ({quotationsInfo.zipCode})
                    </span>
                  </div>
                </div>
                <div className="row mb-7">
                  <label className="col-lg-4 fw-bold text-muted">
                    {t("core:pages.account.AccountEdit.forms.input.Country")}
                  </label>
                  <div className="col-lg-8">
                    <span className="fw-bolder fs-6 text-gray-800">
                      {quotationsInfo.country}
                    </span>
                  </div>
                </div>
                <div className="text-center mt-15">
                  <Button
                    // to="/order/checkout-success"
                    className="btn btn-success"
                    onClick={submitQuotation}
                    style={{
                      display: "block",
                      width: "100 %",
                    }}
                    // state={quotationRef}
                  >
                    {t(
                      "core:pages.shopping.cart.order.checkout-confirmation.FinaliseOrder"
                    )}
                  </Button>
                  <NavLink
                    to="/"
                    className="btn btn-link btn-color-gray-500 btn-active-color-primary me-5 mb-2"
                  >
                    {t(
                      "core:pages.shopping.cart.order.checkout-confirmation.CancelOrder"
                    )}
                  </NavLink>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-8">
            <div className="mb-10">
              <h2>
                {t(
                  "core:pages.shopping.cart.order.checkout-confirmation.title"
                )}
              </h2>
            </div>
            {/* {cartItems.find(x => x.diagUUID === diagUUID)?.links.map((e) => (
            <><ResultRow
              url={e.url}
              title={e.title}
            /></>
          ))} */}
            <div className="card">
              <ButtonGroup>
                {radios.map((radio, idx) => (
                  <ToggleButton
                    key={idx}
                    id={`radio-${idx}`}
                    type="radio"
                    variant={"outline-success"}
                    name="radio"
                    value={radio.value}
                    checked={radioValue === radio.value}
                    onChange={(e) => setRadioValue(e.currentTarget.value)}
                  >
                    {radio.name}
                  </ToggleButton>
                ))}
              </ButtonGroup>
            </div>

            {items.map((item) => {
              if (item.type === "link" && radioValue === "1")
                return <ResultRowLink key={item.id} item={item} />;
              if (item.type === "image" && radioValue === "2")
                return <ResultRowImage key={item.id} item={item} />;
              if (item.type === "suggest" && radioValue === "3")
                return <ResultRowSuggest key={item.id} item={item} />;
              if (item.type === "custom" && radioValue === "4")
                return <ResultRowUrl key={item.id} item={item} />;
            })}
          </div>
        </div>
      </CoreLayout>
    </>
  );
};

/**
 *
 *
 * @param {*} props
 */
const ResultRowLink = (props: any) => {
  const item = props.item;
  return (
    <>
      <div className="row mt-10">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <div>
                    <b>
                      <img
                        alt="img"
                        src={
                          "https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=" +
                          item.url +
                          "&size=16"
                        }
                      />
                      &nbsp;&nbsp;
                      <span className="resultsRowTitle">{item.title}</span>
                    </b>
                  </div>
                  <div>{item.description}</div>
                  {/* <div><b>Type</b> : xxxxxxxxxxxxxxxx</div>
                <div><b>Mot clef</b> : xxxxxxxxxxxxxxxx</div> */}
                  <div>
                    <b>Position</b> : {item.position}
                  </div>
                  <div>
                    <b>Page</b> : {item.page}
                  </div>
                  <div>
                    <b>URL</b> : <a href={item.url}>{item.url}</a>
                  </div>
                  <div>
                    <b>mots clé de recherche</b> : {item.keyword}
                  </div>
                  <div>
                    <b>mots clé negatif</b> : {item.negativeKeyword}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ResultRowImage = (props: any) => {
  const item = props.item;
  return (
    <>
      <div className="row mt-10">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <div>
                    <b>
                      <img
                        alt="img"
                        src={
                          "https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=" +
                          item.url +
                          "&size=16"
                        }
                      />
                      &nbsp;&nbsp;
                      <span className="resultsRowTitle">{item.title}</span>
                    </b>
                  </div>
                  {/* <div><b>Type</b> : xxxxxxxxxxxxxxxx</div>
                <div><b>Mot clef</b> : xxxxxxxxxxxxxxxx</div> */}
                  <div>
                    <b>Position</b> : {item.position}
                  </div>
                  <div>
                    <b>URL</b> :<a href={item.href}>{item.href}</a>{" "}
                  </div>
                  <div>
                    <b>mots clé de recherche</b> : {item.keyword}
                  </div>
                  <div>
                    <b>mots clé negatif</b> : {item.negativeKeyword}
                  </div>
                  <div>
                    <b>Image</b> :{" "}
                    <div
                      className="imgResults"
                      style={{
                        backgroundImage: `url(${item.src})`,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ResultRowSuggest = (props: any) => {
  const item = props.item;
  return (
    <>
      <div className="row mt-10">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  {/* <div><b>Type</b> : xxxxxxxxxxxxxxxx</div>
                <div><b>Mot clef</b> : xxxxxxxxxxxxxxxx</div> */}
                  <div>
                    <b>Position</b> : {item.position}
                  </div>
                  <div>
                    <b>Suggest</b> : {item.suggest}
                  </div>
                  <div>
                    <b>mots clé de recherche</b> : {item.keyword}
                  </div>
                  <div>
                    <b>mots clé negatif</b> : {item.negativeKeyword}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ResultRowUrl = (props: any) => {
  const item = props.item;
  return (
    <>
      <div className="row mt-10">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  {/* <div><b>Type</b> : xxxxxxxxxxxxxxxx</div>
                <div><b>Mot clef</b> : xxxxxxxxxxxxxxxx</div> */}
                  <div>
                    <b>lien</b> : <a href={item.url}>{item.url}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckoutConfirmation;
