import React, { useEffect, useRef, useState } from 'react';

import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const HelpsModals = (props) => {
  const { t } = useTranslation(); // Multi Language
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(props.initialIndex || 0);
  const [prevIndex, setPrevIndex] = useState(null);
  const texts = props.texts || [];

  
  const btnRef = useRef(null);

    useEffect(() => {
    if (btnRef.current) {
      btnRef.current.classList.add('pulse-animation');

      // Si vous souhaitez supprimer l'animation après un certain temps, vous pouvez le faire comme ceci :
      // setTimeout(() => {
      //   btnRef.current.classList.remove('pulse-animation');
      // }, 5000); // Supprime l'animation après 5 secondes
    }
  }, []);


  const handleCloseHelpModal = () => {
    setShowHelpModal(false);
    setCurrentIndex(0);
    setPrevIndex(null);
  };

  const handleShowHelpModal = () => setShowHelpModal(true);

  const handleNext = () => {
    setPrevIndex(currentIndex);
    setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
  };

  const handlePrev = () => {
    if (prevIndex !== null) {
      setCurrentIndex((prevIndex) => (prevIndex - 1 + texts.length) % texts.length);
      setPrevIndex(null);
    }
  };


  const isLastText = currentIndex === texts.length - 1;

  return (
    <>
    <div className="topbar d-flex align-items-stretch flex-shrink-0">
        <div className="d-flex align-items-center ms-2 ms-lg-3">
        <div
          ref={btnRef}
          className="btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary "
          onClick={handleShowHelpModal}
        >
          {t('core:global.needHelp')}
        </div>
      </div>
    </div>
      <Modal dialogClassName="modal-Help" show={showHelpModal} onHide={handleCloseHelpModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t('core:global.needHelp')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {texts[currentIndex]}
        </Modal.Body>
        <Modal.Footer>
          {currentIndex !== 0 && (
            <Button variant="secondary" onClick={handlePrev}>
              Précédent
            </Button>
          )}
          {!isLastText ? (
            <Button variant="primary" onClick={handleNext}>
              Suivant
            </Button>
          ) : (
            <Button variant="secondary" onClick={handleCloseHelpModal}>
              Fermer
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default HelpsModals;
