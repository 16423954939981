import CoreLayout from "../layouts/core-layout";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from 'react-i18next';
const Faq = () => {

  const { t } = useTranslation(); // Multi Language
  return (
    <CoreLayout template="main">
      <div className="card mt-10">
                    <div
              className="card-header border-0 cursor-pointer"
            >
              <div className="card-title m-0">
                <h3 className="fw-bolder m-0 ">{t('faq:title')}</h3>
              </div>
            </div>
        <div className="card-body">


    <Tabs
      defaultActiveKey="definition"
      id="uncontrolled-tab-example"
      className="mb-3 nav-line-tabs-2x"
    >
      <Tab eventKey="definition" title={t('faq:definitions_and_principles.title')}>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header><b> {t('faq:definitions_and_principles.identity_title')} </b></Accordion.Header>
                  <Accordion.Body>
                    <b>{t('faq:definitions_and_principles.identity_body_1')}</b>{t('faq:definitions_and_principles.identity_body_2')}<br/>{t('faq:definitions_and_principles.identity_body_3')}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header><b> {t('faq:definitions_and_principles.e_reputation_title')} </b></Accordion.Header>
              <Accordion.Body>
              {t('faq:definitions_and_principles.e_reputation_body')}
              </Accordion.Body>
                </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header><b> {t('faq:definitions_and_principles.protect_e_reputation_title')} </b></Accordion.Header>
              <Accordion.Body>{t('faq:definitions_and_principles.protect_e_reputation_body')}
              </Accordion.Body>
            </Accordion.Item>
                
            <Accordion.Item eventKey="3">
              <Accordion.Header><b>{t('faq:definitions_and_principles.google_person_title')} </b></Accordion.Header>
              <Accordion.Body>{t('faq:definitions_and_principles.google_person_body')}
              </Accordion.Body>
                </Accordion.Item>
                
            <Accordion.Item eventKey="4">
              <Accordion.Header><b>{t('faq:definitions_and_principles.google_forget_title')} </b></Accordion.Header>
              <Accordion.Body>{t('faq:definitions_and_principles.google_forget_body')}
              </Accordion.Body>
                </Accordion.Item>
                
            <Accordion.Item eventKey="5">
              <Accordion.Header><b> {t('faq:definitions_and_principles.commitment_private_data_title')}</b></Accordion.Header>
              <Accordion.Body>{t('faq:definitions_and_principles.commitment_private_data_body_1')} <br/><br/>{t('faq:definitions_and_principles.commitment_private_data_body_2')}<br/><br/> {t('faq:definitions_and_principles.commitment_private_data_body_3')}<br/><br/> {t('faq:definitions_and_principles.commitment_private_data_body_4')} <br/> {t('faq:definitions_and_principles.commitment_private_data_body_5')}
              </Accordion.Body>
                </Accordion.Item>
          </Accordion>
      </Tab>
      <Tab eventKey="decouvrir" title={t('faq:discover_osculteo.title')}>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header><b> {t('faq:discover_osculteo.why_use_osculteo_title')}</b></Accordion.Header>
              <Accordion.Body><br/>{t('faq:discover_osculteo.why_use_osculteo_body_1')}<b>{t('faq:discover_osculteo.why_use_osculteo_body_2')}</b>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header><b> {t('faq:discover_osculteo.osculteo_for_company_title')} </b></Accordion.Header>
              <Accordion.Body>
              {t('faq:discover_osculteo.osculteo_for_company_body_1')}
<br/>{t('faq:discover_osculteo.osculteo_for_company_body_2')}
              </Accordion.Body>
                </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header><b> {t('faq:discover_osculteo.osculteo_search_engine_title')}</b></Accordion.Header>
              <Accordion.Body>
              {t('faq:discover_osculteo.osculteo_search_engine_body_1')}<b>{t('faq:discover_osculteo.osculteo_search_engine_body_2')}</b>{t('faq:discover_osculteo.osculteo_search_engine_body_3')}
              </Accordion.Body>
                </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header><b>{t('faq:discover_osculteo.who_is_osculteo_title')}</b></Accordion.Header>
              <Accordion.Body>
              {t('faq:discover_osculteo.who_is_osculteo_body_1')} <a href="http://www.iprotego.com" target="_blank" rel="noreferrer">{t('faq:discover_osculteo.who_is_osculteo_body_2')}</a><br/>{t('faq:discover_osculteo.who_is_osculteo_body_3')}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
      </Tab>
      <Tab eventKey="utiliser" title={t('faq:use_osculteo.title')}>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header><b> {t('faq:use_osculteo.forgot_login_title')} </b></Accordion.Header>
              <Accordion.Body>{t('faq:use_osculteo.forgot_login_body_1')}  <br/>{t('faq:use_osculteo.forgot_login_body_2')}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header><b> {t('faq:use_osculteo.delete_search_title')} </b></Accordion.Header>
              <Accordion.Body>
              {t('faq:use_osculteo.delete_search_body_1')} <br/> {t('faq:use_osculteo.delete_search_body_2')}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Tab>
      <Tab eventKey="services" title={t('faq:service_osculteo.title')}>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header><b> {t('faq:service_osculteo.after_deletion_title')} </b></Accordion.Header>
              <Accordion.Body>{t('faq:service_osculteo.after_deletion_body_1')}<br/>{t('faq:service_osculteo.after_deletion_body_2')}<br/>{t('faq:service_osculteo.after_deletion_body_3')}<br/> {t('faq:service_osculteo.after_deletion_body_4')}<br/> {t('faq:service_osculteo.after_deletion_body_5')}<br/><br/>{t('faq:service_osculteo.after_deletion_body_6')}<br/><br/> {t('faq:service_osculteo.after_deletion_body_7')}<br/> {t('faq:service_osculteo.after_deletion_body_8')}<br/><br/>{t('faq:service_osculteo.after_deletion_body_9')}<br/><br/>{t('faq:service_osculteo.after_deletion_body_10')}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header><b> {t('faq:service_osculteo.delete_google_image_title')}</b></Accordion.Header>
              <Accordion.Body>
              {t('faq:service_osculteo.delete_google_image_body')}
              </Accordion.Body>
                </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header><b> {t('faq:service_osculteo.delete_facebook_image_title')} </b></Accordion.Header>
              <Accordion.Body>
              {t('faq:service_osculteo.delete_facebook_image_body')}
              </Accordion.Body>
                </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header><b> {t('faq:service_osculteo.page_deletion_request_approval_title')}</b></Accordion.Header>
              <Accordion.Body>
              {t('faq:service_osculteo.page_deletion_request_approval_body')}
              </Accordion.Body>
                </Accordion.Item>
                            <Accordion.Item eventKey="4">
              <Accordion.Header><b>{t('faq:service_osculteo.deleted_content_confirmation_title')} </b></Accordion.Header>
              <Accordion.Body>
              {t('faq:service_osculteo.deleted_content_confirmation_body_1')} <br/>{t('faq:service_osculteo.deleted_content_confirmation_body_2')}<b>{t('faq:service_osculteo.deleted_content_confirmation_body_3')} </b>{t('faq:service_osculteo.deleted_content_confirmation_body_4')} 
              </Accordion.Body>
                </Accordion.Item>
                            <Accordion.Item eventKey="5">
              <Accordion.Header><b>{t('faq:service_osculteo.content_deletion_delay_title')}</b></Accordion.Header>
              <Accordion.Body>
              {t('faq:service_osculteo.content_deletion_delay_body_1')} <b>{t('faq:service_osculteo.content_deletion_delay_body_2')}</b> {t('faq:service_osculteo.content_deletion_delay_body_3')}
              </Accordion.Body>
                </Accordion.Item>
                            <Accordion.Item eventKey="6">
              <Accordion.Header><b> {t('faq:service_osculteo.missing_url_title')}</b></Accordion.Header>
              <Accordion.Body>
              {t('faq:service_osculteo.missing_url_body')}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Tab>
        
      <Tab eventKey="other" title={t('faq:other_question.title')}>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header><b> {t('faq:other_question.using_osculteo_on_smartphone_title')} </b></Accordion.Header>
              <Accordion.Body>{t('faq:other_question.using_osculteo_on_smartphone_body_1')}<br/>{t('faq:other_question.using_osculteo_on_smartphone_body_2')}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header><b> {t('faq:other_question.delete_acount_title')}</b></Accordion.Header>
              <Accordion.Body>
              {t('faq:other_question.delete_acount_body_1')}<br/>{t('faq:other_question.delete_acount_body_2')}<br/>{t('faq:other_question.delete_acount_body_3')}
              </Accordion.Body>
                </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header><b> {t('faq:other_question.contact_title')}</b></Accordion.Header>
              <Accordion.Body>
              {t('faq:other_question.contact_body_1')}<br/> {t('faq:other_question.contact_body_2')}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>      </Tab>
        </Tabs>
      </div>
      </div>
  </CoreLayout>)
}


export default Faq;