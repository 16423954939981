

/**
 * Cookie SentimentBadgeHelpers
 *
 * @return {*} 
 */
const ExtensionsHelpers = (props: any) => {
  switch (props.ext) {
    case "doc":
      return (
        <img src="/images/ext/doc.png" className="img-fluid" style={{ maxWidth: "20px" }} alt="Doc"></img>
      );

    case "docx":
      return (
        <img src="/images/ext/docx.png" className="img-fluid" style={{ maxWidth: "20px" }} alt="Docx"></img>
      );

    case "pdf":
      return (
        <img src="/images/ext/pdf.png" className="img-fluid" style={{ maxWidth: "20px" }} alt="Pdf"></img>
      );

    case "ppt":
      return (
        <img src="/images/ext/ppt.png" className="img-fluid" style={{ maxWidth: "20px" }} alt="Ppt"></img>
      );


    case "txt":
      return (
        <img src="/images/ext/txt.png" className="img-fluid" style={{ maxWidth: "20px" }} alt="Txt"></img>
      );

    case "xlsx":
      return (
        <img src="/images/ext/xlsx.png" className="img-fluid" style={{ maxWidth: "20px" }} alt="Xlsx"></img>
      );

    case "xls":
      return (
        <img src="/images/ext/xls.png" className="img-fluid" style={{ maxWidth: "20px" }} alt="Xls"></img>
      );

  }
  return <></>
}

export default ExtensionsHelpers;