import * as React from "react";
import { Navigate } from 'react-router-dom';
import { useCart } from "react-use-cart";
import ReactGA from "react-ga4";

export default function Logout() {
  ReactGA.event({
    category: "Auth",
    action: "logout",
    label: window.location.pathname + window.location.search
  });
  const { emptyCart } = useCart();
  emptyCart();
  localStorage.clear();
  
  return <Navigate to="/" />;
}
