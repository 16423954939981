import { useNavigate } from "react-router-dom";
import CoreLayout from "../../layouts/core-layout";
import { useTranslation } from "react-i18next";


const Cancel = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(); // Multi Language
    return (
      <CoreLayout template="main">
      <div className="card shadow-sm ">
     <div className="card-header">
         <h3 className="card-title">
          <div className="text-danger">
          {t('core:pages.account.Cancel.paymentNotSucceed')} </div></h3>
     </div>
     <div className="card-body p-0">
         <div className="card-p mb-10">{t('core:pages.account.Cancel.errorPayment')} 
 
         <div className="text-center px-4 m-5">
          <div className="row">
            <div className="col">
         <button type="button" className="btn btn-sm btn-primary" onClick={() => navigate("/dashboard")}>
         {t('core:pages.account.Cancel.dashboard')} 
             </button>
             </div>
             <div className="col">
         <button type="button" className="btn btn-sm btn-success" onClick={() => navigate("/offers")}>
         {t('core:pages.account.Cancel.showOffers')} 
             </button>
             </div>
             </div>
         </div>
     </div>
 </div>
 
 </div>
       </CoreLayout>
    );
  };
  
  export default Cancel;
  