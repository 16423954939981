import { AxiosServicesByName } from "./AxiosServices";

export const getvideos = async (payload: any) => {
  return await AxiosServicesByName.get(`/yt-videos/${payload}`);
};

export const getAllVideos = async (payload: any) => {
  return await AxiosServicesByName.get(`/yt-videos/all-videos/${payload}`);
};

export const updateTonalityVideo = async (payload: any) => {
  try {
    return await AxiosServicesByName.put(`/yt-videos/update-tonality`, payload);
  } catch (error) {
    console.log("error");
  }
};
