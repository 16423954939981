import * as Yup from "yup";



export const passwordRules = Yup.string()
  .min(8, ({ min }) => {

    return `Le mot de passe doit contenir au moins ${min} caractères`;
  })
  .max(40, ({ max }) => `Le mot de passe doit contenir au maximum ${max} caractères`)


  .matches(/^(?=.*[a-z])/, "Le mot de passe doit contenir au moins une lettre miniscule")


  .matches(
    /^(?=.*[A-Z])/,
    "Le mot de passe doit contenir au moins une lettre majuscule"
  )
  .matches(
    /^(?=.*[0-9])/,
    "Le mot de passe doit contenir au moins un chiffre"
  )
  .matches(
    /^(?=.*[!@#$%^&*-?])/,
    "Le mot de passe doit contenir au moins un caractère spécial"
  );

export const simplePasswordRules = Yup.string()
  .min(8, "validator:rules.PasswordRules.passwordRules.min")
  .max(40, "validator:rules.PasswordRules.passwordRules.max")

