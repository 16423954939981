import React, { useState } from "react";
import { FaThumbsUp } from "react-icons/fa";

function CommentComponent(props: any) {
  const [visibleComments, setVisibleComments] = useState(20);
  const [load, setLoad] = useState(false);

  const loadMoreComments = () => {
    setLoad(true);
    setTimeout(() => {
      setVisibleComments((prev: number) => prev + 15);
      setLoad(false);
    }, 2000);
    
  };

  function getTimeAgo(date: Date): string {
    const now = new Date();
    const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);
    const diffInMinutes = diffInSeconds / 60;
    const diffInHours = diffInMinutes / 60;
    const diffInDays = diffInHours / 24;
    const diffInMonths = diffInDays / 30;
    const diffInYears = diffInDays / 365;

    if (diffInYears >= 1) {
      return `il y a ${Math.round(diffInYears)} an(s)`;
    } else if (diffInMonths >= 1) {
      return `il y a ${Math.round(diffInMonths)} mois`;
    } else if (diffInDays >= 1) {
      return `il y a ${Math.round(diffInDays)} jour(s)`;
    } else if (diffInHours >= 1) {
      return `il y a ${Math.round(diffInHours)} heure(s)`;
    } else if (diffInMinutes >= 1) {
      return `il y a ${Math.round(diffInMinutes)} minute(s)`;
    } else {
      return "il y a quelques secondes";
    }
  }
  return (
    <>
      <div className="m-2" style={{ overflowY: "scroll", height: "300px" }}>
        {props.video.comments.length === 0 && (
          <div className="d-flex justify-content-center m-10 ">
            <p className="grey-p">
              {" "}
              il n'y a pas de commentaire pour cette video.
            </p>
          </div>
        )}
        {props.video.comments.slice(0, visibleComments).map((el: any) => {
          return (
            <div className="mt-10">
              <div className="row">
                <div className="col-1">
                  {" "}
                  {/* <span> */}
                  <img
                    src={JSON.parse(el).image}
                    className="rounded-circle m-2"
                  />{" "}
                </div>
                <div className="col-8">
                  <b>{JSON.parse(el).author}</b>
                  {/* </span> */}
                  <span className="m-2" style={{ color: "grey" }}>
                    {getTimeAgo(new Date(JSON.parse(el).date))}
                  </span>

                  <div>{replaceHtmlApostrophe(JSON.parse(el).message)}</div>
                  <div>
                    {" "}
                    <FaThumbsUp /> {JSON.parse(el).likes}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {visibleComments < props.video.comments.length && (
        <div className="d-flex justify-content-center mt-3">
          
          <button onClick={loadMoreComments} disabled={load} className="btn btn-primary">
          
           <div>Charger plus de commentaires </div>
           {load  && (
                      <div
                        className="spinner-border mt-5"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
          </button>
        </div>
      )}
    </>
  );
}

export default CommentComponent;

function replaceHtmlApostrophe(input: string): string {
  return input.replace(/&#39;/g, "'");
}
