import { Trash } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { useCart } from "react-use-cart";

const ButtonRemove = (props: any) => {
  const { t } = useTranslation();
  const { removeItem } = useCart();
  return (
    <div>
      <button onClick={() => removeItem(props.itemId)} className="btn btn-sm btn-danger">
        {t("core:pages.osculteo.results.button.removeToCart")}
      </button>
    </div>
  );
};
export default ButtonRemove;