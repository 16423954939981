import React from "react";

import { getSuggestsRequest } from "../../../api/LinksApi";
import ResultsLayout from "./results-layout";
import CoreLayout from "../../../layouts/core-layout";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCart } from "react-use-cart";
import SentimentBadgeHelpers from "../../../helpers/SentimentBadgeHelpers";
import LoadingResults from "./elements/LoadingResults";
import NoResultsBlock from "./elements/NoResultsBlock";
import { useQuery } from "react-query";
import ButtonRemove from "../components/ButtonRemove";
import ButtonAdd from "../components/ButtonAdd";
import { ChangeSentimentModalSuggest } from "../components/ChangeModal";

export default function GoogleSuggestResults() {
  const { t } = useTranslation(); // Multi Language
  const { diagUUID } = useParams();
  const { data, isLoading } = useQuery("suggest", async () =>
    getSuggestsRequest(diagUUID)
  );

  if (isLoading) {
    return (
      <CoreLayout template="main">
        <ResultsLayout
          description={t("core:pages.osculteo.results.section.suggest.text")}
        >
          <div className="text-center mt-20 mb-20">
            <LoadingResults />
          </div>
        </ResultsLayout>
      </CoreLayout>
    );
  }

  if (data?.length === 0) {
    return (
      <CoreLayout template="main">
        <ResultsLayout
          description={t("core:pages.osculteo.results.section.suggest.text")}
        >
          <NoResultsBlock />
        </ResultsLayout>
      </CoreLayout>
    );
  }

  return (
    <>
      <CoreLayout template="main">
        <ResultsLayout
          description={t("core:pages.osculteo.results.section.suggest.text")}
        >
          {data?.map((item: any, index) => (
              <SuggestComponent key={item.id} item={item} />
          ))}
        </ResultsLayout>
      </CoreLayout>
    </>
  );
}

const SuggestComponent = (props: any) => {
  const { diagUUID } = useParams();
  const { inCart } = useCart();

  return (
    <>
      <div className="card mb-3">
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <h3>{props.item.suggest}</h3>
            </div>
            <div className="col-md-4">
              <div className="btn">
                <SentimentBadgeHelpers tonality={props.item.tonality} uuid={props.item.uuid} type={"suggest"} />
              </div>
            </div>
            <div className="col-md-2 text-right">
              {inCart(props.item.id) ? (
                <ButtonRemove itemId={props.item.id} />
              ) : (
                <ButtonAdd item={props.item} diag={diagUUID} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
