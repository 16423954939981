/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-useless-escape */

export const getScore = (value: string) => {

  return getCheckScore(value)

}


/**
 * Detect lowercase
 *
 * @param {string} value
 * @return {*} 
 */
const checkLowerCase = (value: string) => {
  const val = value ? value : ''
  return /[a-z]/.test(val) // 20 score
}
/**
 * Detect Uppercase
 *
 * @param {string} value
 * @return {*} 
 */
const checkUppercase = (value: string) => {
  const val = value ? value : ''
  return /[A-Z]/.test(val) // 20 score
}
/**
 * Detect digits
 *
 * @param {string} value
 * @return {*} 
 */
const checkDigit = (value: string) => {
  const val = value ? value : ''
  return /[0-9]/.test(val) // 20 score
}
/**
 * Detect specialChar
 *
 * @param {string} value
 * @return {*} 
 */
const checkChar = (value: string) => {
  const val = value ? value : ''
  return /[~`!#$%\^&*+=\-\[\]\\';,/{@}|\\":<>\?]/g.test(val) // 20 score
}


/**
 * Detect if miniman requirement length
 *
 * @param {string} value
 * @return {*} 
 */
const checkminLength = (value: string) => {
  const val = value ? value : ''
  return (value.length >= 10)
}



const getCheckScore = (value: string) => {
  let count = 0

  if (checkUppercase(value)) {
    count++
  }

  if (checkLowerCase(value)) {
    count++
  }

  if (checkDigit(value)) {
    count++
  }

  if (checkChar(value)) {
    count++
  }

  if (checkminLength(value)) {
    count++
  }
  return count
}

