import { Button } from "react-bootstrap";
import CoreLayout from "../../layouts/core-layout";
import ReactGA from "react-ga4";
import { acceptCgu } from "../../api/AuthApi";
const Cgu = () => {
  const accesToken = localStorage.getItem("accessToken");
  ReactGA.event({
    category: "Auth",
    action: "show-cgu",
    label: window.location.pathname + window.location.search
  });
  if (accesToken) {
     return (
    <CoreLayout template="main">
      <Button onClick={() => acceptCgu}> Accepter les cgu</Button>
      
  </CoreLayout>) 
  } else {
      return (
        <CoreLayout template="auth">
         
  </CoreLayout>)
  }
}




export default Cgu;