import React from 'react';
import { Navigate } from 'react-router-dom';
const Home = () => {
  //on se charge de la redirection
  const accesToken = localStorage.getItem("accessToken");
  if (accesToken !== null) {
    return <Navigate to="/login" />;
  }
  return <Navigate to="/login" />;

}
export default Home;