import { ButtonKeywords } from "./YoutubeVideoComponent";

export default function CaptionsComponent(props: any) {
  function formatTimestamp(timestamp: number) {
    let totalSeconds = Math.floor(timestamp / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  return (
    <>
      {props.video.captions.length === 0 && (
        <div className="d-flex justify-content-center m-10">
          <p className="grey-p">
            Nous vous informons que notre service n'est actuellement pas en
            mesure d'analyser ce genre de contenu, cependant, cette
            fonctionnalité sera bientôt disponible grâce à de nouvelles mises à
            jour. Entretemps, nous vous invitons à visionner la vidéo et à
            rester à l’affût des améliorations à venir !
          </p>
        </div>
      )}
      {props.keywords && (
        <div>
          <h5>Filtres</h5>
          <p>
            {" "}
            Cliquez sur les filtres pour trouver facilement les moments dans la
            video ou l'on parle de ce sujet
          </p>
          <div>
            {props.keywords.slice(0, 20).map((el: any) => (
              <ButtonKeywords
                text={el.word}
                type={el.type}
                action={() => props.func(el.offset[0])}
              />
            ))}
          </div>
        </div>
      )}
      <div className="m-2" style={{ overflowY: "scroll", height: "300px" }}>
        {props.video.captions.map((el: any) => {
          return (
            <div>
              [{formatTimestamp(JSON.parse(el).offset)}] - {JSON.parse(el).text}
            </div>
          );
        })}
      </div>
    </>
  );
}
