import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getAllDiagnosisRequest,
  getAvailableDiagnosisRequest,
} from "../../api/DiagnosisApi";
import CoreLayout from "../../layouts/core-layout";

//Multi language
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import AvalaibleOfferModale from "./components/AvailableOfferModal";
import { DiagnosisContext } from "../../contexts/DiagnosisContext";
import { getSubscription } from "../../api/PlatformApi";
//import LoadingResults from './results/elements/LoadingResults';
import HelpsModals from "../helps/helps-modals";
import { HelpsDashboard } from "../helps/contents/helps-dashboard";

const texts = [
  <HelpsDashboard />,

];
const Dashboard = () => {
  const value = React.useContext(DiagnosisContext);
  const [available, setAvailable] = useState<AvailableType[]>([]);
  useQuery("diagAvailable", async () =>
    getAvailableDiagnosisRequest()
      .then(function (res: any) {
        const av = [];
        const result = res.data.data;

        for (const [key, value] of Object.entries(result)) {
          av.push({ ...(value as AvailableType), offerSlug: key });
        }
        const sortData = [...av].sort((a, b) => {
          return a.diagTotal < b.diagTotal ? 1 : -1;
        });
        setAvailable(sortData);
        return res.data.data;
      })
      .catch(() => {
        console.log("error");
      })
  );
  const navigate = useNavigate();

  useEffect(() => {
    let offerSlug: any;
    getSubscription()
      .then(function (res: any) {
        offerSlug = res.data.data[0].offer.slug;
      })
      .catch(() => { });

    getAllDiagnosisRequest().then(function (diagnosisResponse: any) {
      if (diagnosisResponse.data.data.length === 0) {
        value.offerSlug = offerSlug;
        navigate("/diagnosis");
      } else {
        navigate("/dashboard");
      }
    });
  }, []);

  return (
    <>
    
      <CoreLayout template="main">
      <div style={{ margin: '30px' }}></div>
      <HelpsModals texts={texts} initialIndex={0} />
      <div style={{ margin: '10px' }}></div>
        {available.map((el, index) => {
          return (

            <AvalaibleOfferModale
              key={index}
              slug={el.offerSlug}
              rest={el.diagRest}
              offerName={el.offerName}
              totalDiag={el.diagTotal}
            />
            
            
          );
          
        })}
        
      </CoreLayout>

    </>
  );
};

export default Dashboard;

export type AvailableType = {
  offerName: string;
  diagTotal: number;
  diagRest: number;
  pages: number;
  offerSlug: string;
};
