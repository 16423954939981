import React, { useEffect, useState } from "react";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { getDiagnosisByUIIDRequest } from "../../../api/DiagnosisApi";

//Multi language
import { useTranslation } from "react-i18next";

// aide
import HelpsModals from "../../helps/helps-modals";
import {
  HelpsShowdashboardGlobal,
  HelpsShowdashboardImage,
  HelpsShowdashboardPrivateData,
  HelpsShowdashboardPrivateFacebook,
  HelpsShowdashboardPrivateUrl,
  HelpsShowdashboardSerp,
  HelpsShowdashboardSuggest,
} from "../../helps/contents/helps-show-dashbord";

const ResultsLayout = (props: any) => {
  const { t } = useTranslation(); // Multi Language
  const { diagUUID } = useParams();
  const [diagnosisInfo, setDiagnosisInfo] = React.useState({
    negativeKeyword: null,
    keyword: null,
  });

  React.useEffect(() => {
    getDiagnosisByUIIDRequest(diagUUID)
      .then(function (res: any) {
        setDiagnosisInfo(res.data);
      })
      .catch(() => {
        console.log("error");
      });
  }, [diagUUID]);

  const [infoKeyword, setInfoKeyword] = useState<any[]>([]);
  const [infoNegativeKeyword, setInfoNegativeKeyword] = useState<any[]>([]);
  useEffect(() => {
    const fetchData = async () => {
      const res = await getDiagnosisByUIIDRequest(diagUUID);
      const infoKeyword = res.data.data.keyword;
      const negativeKeyword = res.data.data.negativeKeyword;
      setInfoKeyword(infoKeyword);
      setInfoNegativeKeyword(negativeKeyword);
    };
    fetchData();
  }, [diagUUID]);

  const texts = [
    <HelpsShowdashboardGlobal />,
    <HelpsShowdashboardSerp />,
    <HelpsShowdashboardImage />,
    // < HelpsShowdashboardSuggest/>,
    <HelpsShowdashboardPrivateData />,
    <HelpsShowdashboardPrivateUrl />,
    <HelpsShowdashboardPrivateFacebook />,
  ];

  const menu_links = [
    {
      path: "/result/" + diagUUID + "/overview",
      label: t("core:pages.osculteo.results.layout.menu.overview"),
    },
    {
      path: "/result/" + diagUUID + "/links",
      label: t("core:pages.osculteo.results.layout.menu.links"),
    },
    {
      path: "/result/" + diagUUID + "/news",
      label: "Google News",
    },
    {
      path: "/result/" + diagUUID + "/images",
      label: t("core:pages.osculteo.results.layout.menu.images"),
    },
    // {
    //   path: "/result/" + diagUUID + "/suggest",
    //   label: t("core:pages.osculteo.results.layout.menu.suggest")
    // },
    {
      path: "/result/" + diagUUID + "/pseudo",
      label: t("core:pages.osculteo.results.layout.menu.pseudo"),
    },
    {
      path: "/result/" + diagUUID + "/private-data",
      label: "Donnée privée",
    },
    {
      path: "/result/" + diagUUID + "/direct-url",
      label: "Ajouter URL personnalisée",
    },
    {
      path: "/result/" + diagUUID + "/youtube",
      label: "Videos",
    },
    {
      path: "/result/" + diagUUID + "/twinning-facebook",
      label: "Jumeler votre compte Facebook",
    },
    {
      path: "/result/" + diagUUID + "/darkweb",
      label: "darkweb",
    },
  ];
  const navigate = useNavigate();
  const handleChangeSelectMenu = (event: any) => {
    navigate(event.target.value);
  };
  return (
    <>
      <div className="card mb-5 mb-xxl-8 mt-10">
        <div className="card-header cursor-pointer">
          <div className="card-title m-0">
            <h5 className="fw-bolder m-0">
              {t("core:pages.osculteo.results.layout.title")}{" "}
              <span className="badge badge-success">{infoKeyword}</span>
            </h5>{" "}
            <span className="m-2">
              {" "}
              {infoNegativeKeyword && (
                <h5 className="fw-bolder m-0">
                  {" "}
                  avec le mot clé{" "}
                  {infoNegativeKeyword && (
                    <span className="badge badge-info">
                      {infoNegativeKeyword}
                    </span>
                  )}
                </h5>
              )}
            </span>
          </div>
          {
            <div className="card-toolbar">
              <HelpsModals texts={texts} initialIndex={0} />
            </div>
          }
        </div>
        <div className="card-body pt-9 pb-0">
          {props.description}
          <div className="d-md-none  mt-10 mb-5">
            <div>
              <b>Choisir un rapport :</b>
            </div>
            <select
              className="nav-select form-select"
              onChange={handleChangeSelectMenu}
            >
              {menu_links.map((item, index) => (
                <option value={item.path} key={index}>
                  {item.label}
                </option>
              ))}
            </select>
          </div>
          <div className="d-none d-md-block">
            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder">
              {menu_links.map((item, index) => (
                <li className="nav-item mt-2" key={index}>
                  <NavLink
                    className="nav-link text-active-primary ms-0 me-10 py-5"
                    to={item.path}
                  >
                    {item.label}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {props.children}
    </>
  );
};

export default ResultsLayout;
