
import { createDiagnosisBasicRequest } from "../../api/DiagnosisApi";
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from "react-router-dom";
import CoreLayout from "../../layouts/core-layout";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import DiagnosisAnalysisSchema from "../../validators/DiagnosisAnalysisSchema"
import DiagnosisAnalysisInterface from "../../interfaces/DiagnosisAnalysisInterface";

//Multi language
import { useTranslation } from 'react-i18next';
import React from "react";
import { DiagnosisContext } from "../../contexts/DiagnosisContext";

export default function DiagnosisAnalysis() {

  const value  = React.useContext(DiagnosisContext);
  const { t } = useTranslation(); // Multi Language
  const [showLoadingAdvertisingModal, setShowLoadingAdvertisingModal] = useState(false);
  const handleCloseLoadingAdvertisingModal = () => setShowLoadingAdvertisingModal(false);


  let navigate = useNavigate();

  const { register, handleSubmit, formState: { errors } } = useForm<DiagnosisAnalysisInterface>({
    //mode: "onBlur",
    resolver: yupResolver(DiagnosisAnalysisSchema),
  });

  const [isCliqued, setIsCliqued] = useState(false)

  /**
   *  onSubmit
   * @desc On envoie le formulaire et donc on lance les call API.
   * @param {*} diagnosisData
   */
  const onSubmit = async (diagnosisData: any) => {
    setIsCliqued(true)
    setShowLoadingAdvertisingModal(true);
    const data = { ...diagnosisData, offerSlug: value.offerSlug }
    await createDiagnosisBasicRequest(data).then(async function (response: any) {

      if (response.data.data) {
        setTimeout(() => {
          setShowLoadingAdvertisingModal(false);
          navigate(`/result/${response.data.data?.uuid}/menotme`);
        }, 1600);
      } else {
        console.log("UUID error");
      }
    }).catch(function (err: any) {
      console.log(err);
    });
  };

  return (
    <>
      <CoreLayout template="main">
        <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto mt-10 animated tada">
          <form
            className="form w-100"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <div className="text-center mb-10">
              <h1 className="text-dark mb-3">{t('core:pages.osculteo.DiagnosisWithNegativeKeyword.title')}</h1>
            </div>
            <div className="mb-10">{t('core:pages.osculteo.DiagnosisWithNegativeKeyword.text')}</div>
            <div className="fv-row mb-10">
              <label className="form-label fs-6 fw-bolder text-dark">
                {t('core:pages.osculteo.DiagnosisWithNegativeKeyword.forms.input.keyword.label')}
              </label>
              <input
                required
                className="form-control form-control-lg form-control-solid"
                type="text"
                id="keyword"
                placeholder={t('core:pages.osculteo.DiagnosisWithNegativeKeyword.forms.input.keyword.placeholder')}
                autoFocus
                {...register("keyword")}
              />
              <ErrorMessage
                errors={errors}
                name="keyword"
                render={({ message }) => <p className="errorMsg">{t(message)}</p>}
              />
            </div>
            <div className="text-center">
              <button type="submit" className="btn btn-lg btn-primary w-100 mb-5">
                {t('core:pages.osculteo.DiagnosisWithNegativeKeyword.forms.button')}
                {isCliqued ? (
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                ) : null}
              </button>
            </div>
          </form>
        </div>
      </CoreLayout>

      <Modal dialogClassName="modal-LoadingAdvertising" show={showLoadingAdvertisingModal} onHide={handleCloseLoadingAdvertisingModal} backdrop="static"
        keyboard={false}>
        <Modal.Body>
          <div><img src={`${process.env.REACT_APP_CDN_PLATFORMS}/slider-1.jpg`} className="img-fluid" alt="wait..." /></div>
          <div className="text-center modal-LoadingAdvertising-title mt-5">{t('core:pages.osculteo.DiagnosisWithNegativeKeyword.modal.title')}</div>
          <div className="text-center modal-LoadingAdvertising-desc">{t('core:pages.osculteo.DiagnosisWithNegativeKeyword.modal.text')}</div>
          {/* <div className="text-center">Annuler l'analyse</div> */}
          <div className="progress mt-5 mb-5" >
            <div className="progress-bar progress-bar-striped indeterminate">
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
