
import * as Yup from "yup";

/** Email */
export const emailRules = Yup.string()
  .email("validator:rules.UserRules.emailRules.email")
  .max(255, "validator:rules.UserRules.emailRules.max");

/** firstName */
export const firstNameRules = Yup.string()
  .min(4, "validator:rules.UserRules.firstNameRules.min")
  .max(100, "validator:rules.UserRules.firstNameRules.max");

/** lastName */
export const lastNameRules = Yup.string()
  .min(4, "validator:rules.UserRules.lastNameRules.min")
  .max(100, "validator:rules.UserRules.lastNameRules.max");

/** phone */
export const phoneRules = Yup.string()
  .min(4, "validator:rules.UserRules.phoneRules.min")
  .max(100, "validator:rules.UserRules.phoneRules.max");

/** city */
export const cityRules = Yup.string()
  .min(4, "validator:rules.UserRules.cityRules.min")
  .max(100, "validator:rules.UserRules.cityRules.max");

/** address */
export const addressRules = Yup.string()
  .min(4, "validator:rules.UserRules.addressRules.min")
  .max(140, "validator:rules.UserRules.addressRules.max");

/** zipCode */
export const zipCodeRules = Yup.string()
  .min(4, "validator:rules.UserRules.zipCodeRules.min")
  .max(128, "validator:rules.UserRules.zipCodeRules.max");


/** country */
export const countryRules = Yup.string();

/** languageRules */
export const languageRules = Yup.string();


