/**
 * Renvoie un loader
 *
 * @return {*} 
 */
export default function LoadingResults() {

  return (
    <div className="osc-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
     
  )
}