
import { useEffect, useState } from "react";
import {  useParams, NavLink } from "react-router-dom";
import { authValideEmailRequest } from "../../api/AuthApi";
import CoreLayout from '../../layouts/core-layout';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from "react-i18next";

export default function ValideEmail() {
  const { t } = useTranslation(); // Multi Language
  const { userId, validation_token } = useParams();
  const [validateEmail, setValidateEmail] = useState(false);

  useEffect(() => {
    var data = JSON.stringify({
      userId: userId,
      validationToken: validation_token,
    });

    authValideEmailRequest(data)
      .then(function (response: any) {
        setValidateEmail(true);
        console.log(response)
        localStorage.setItem("accessToken", response.data.data.access_token);
        setTimeout(() => {
          window.location.href = "/diagnosis";
        }, 2000)
      })
      .catch(function (error: any) {
        console.log(error);
      });
  });

  return validateEmail ? (
    <CoreLayout template="auth">
      <div className="">
        <div className="platform-topBar w-lg-800px mx-auto"></div>
        <div className="w-lg-800px bg-body shadow-sm p-10 p-lg-20 mx-auto text-center">
          <div className='text-center platform-logoBlock mb-10' ><img src={`${process.env.REACT_APP_CDN_PLATFORMS}/logo.png`} className='platform-auth-logo' alt="Logo" /></div>
          <h1>{t('core:pages.validateEmail.successfuly')}</h1>
          {t('core:pages.validateEmail.emailValidation')}
          {/* <div className="mt-5"><NavLink to="/diagnosis" className="btn btn-primary">{t('core:pages.validateEmail.backApp')}</NavLink></div> */}
          <div className="mt-5"><Spinner animation="border" variant="primary">
    </Spinner></div>
      </div>
          </div>
          
    </CoreLayout>
  ) : (
    <CoreLayout template="auth">
      <div className="animated tada">
        <div className="w-lg-600px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto text-center">
          <h1>Error</h1>
          {t('core:pages.validateEmail.isNotValid')}
          <div className="mt-5"><NavLink to="/login" className="btn btn-primary">{t('core:pages.validateEmail.backApp')}</NavLink></div></div>
      </div>
    </CoreLayout>
  );
}
