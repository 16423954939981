/**
 * HelpsCreateDiag
 *
 */
const HelpsShowdashboardGlobal = () => {
  return (
    <>
      Votre page contient des informations sur votre réputation en ligne et son
      classement dans Google. Cliquez sur les boutons ci-dessous pour accéder à
      plus de détails.
    </>
  );
};

export { HelpsShowdashboardGlobal };

const HelpsShowdashboardSerp = () => {
  return (
    <>
      L'onglet Google SERP vous permet de visualiser les pages web apparaissant
      dans les résultats de recherche de Google et qui mentionnent votre nom.
      Cette section vous offre la possibilité de confirmer si ces mentions vous
      concernent réellement. Si vous constatez des informations erronées ou
      indésirables, n'hésitez pas à demander la suppression de ces pages web.
      Votre réputation en ligne est importante, et nous sommes là pour vous
      aider à la gérer de manière efficace et sécurisée.
    </>
  );
};

export { HelpsShowdashboardSerp };

const HelpsShowdashboardImage = () => {
  return (
    <>
      L'onglet Images vous permet de rechercher les images associées à votre nom
      dans la section Images de Google. Cette fonctionnalité vous offre la
      possibilité de trouver les images qui sont liées à votre identité en
      ligne. Vous pouvez examiner ces images pour vous assurer de leur
      pertinence et de leur adéquation avec votre image personnelle ou
      professionnelle. Si vous trouvez des images inappropriées ou indésirables,
      vous pouvez prendre des mesures pour les gérer ou les supprimer.
    </>
  );
};

export { HelpsShowdashboardImage };

const HelpsShowdashboardSuggest = () => {
  return (
    <>
      L'onglet Suggest vous permet de découvrir les suggestions de recherche de
      Google qui sont associées à votre nom ou à votre identité en ligne. Cette
      fonctionnalité vous offre un aperçu des termes de recherche que les
      utilisateurs de Google associent fréquemment à votre nom. En examinant ces
      suggestions, vous pouvez mieux comprendre comment vous êtes perçu en ligne
      et prendre des mesures pour influencer votre image de manière positive.
      Cette page vous permet de surveiller et de gérer les suggestions de Google
      qui vous concernent, vous aidant ainsi à façonner votre présence en ligne
      de manière stratégique.
    </>
  );
};

export { HelpsShowdashboardSuggest };

const HelpsShowdashboardPrivateData = () => {
  return (
    <>
      L'onglet Donnée privée vous permet de rechercher les sites web où vous
      pourriez avoir des comptes utilisateur. Cette fonctionnalité vise à
      identifier les sites et les plateformes en ligne où votre nom ou vos
      informations personnelles peuvent être associés à des comptes utilisateur.
      En examinant cette liste, vous pouvez mieux gérer vos informations
      personnelles en ligne, vérifier la sécurité de vos comptes, et prendre des
      mesures pour protéger votre vie privée. Cette page vous donne un aperçu
      des endroits où vos données pourraient être enregistrées et vous aide à
      garder le contrôle sur votre présence numérique
    </>
  );
};

export { HelpsShowdashboardPrivateData };

const HelpsShowdashboardPrivateUrl = () => {
  return (
    <>
      L'onglet "Ajouter URL personnalisée" vous permet de demander la
      suppression d'URL spécifiques sur diverses plateformes en ligne. Que ce
      soit des résultats de recherche, des articles, des commentaires, ou
      d'autres types de contenus, vous avez la possibilité de soumettre vos
      demandes de suppression en suivant quelques étapes simples. Cette
      fonctionnalité vous permet de contrôler votre présence en ligne en
      éliminant les informations indésirables ou nuisibles. Nous sommes là pour
      vous guider tout au long du processus, vous aidant à maintenir une
      réputation en ligne positive et à protéger vos informations personnelles.
    </>
  );
};

export { HelpsShowdashboardPrivateUrl };

const HelpsShowdashboardPrivateFacebook = () => {
  return (
    <>
      L'onglet "Facebook"vous permet l'accès aux données
      personnelles utilisées par ce réseau social. Cette fonctionnalité vous
      permet de centraliser et de gérer vos informations Facebook depuis
      Oscultéo. Vous pourrez ainsi examiner et contrôler les données que vous
      partagez sur la plateforme, vous assurant que votre présence sur les
      médias sociaux est conforme à vos souhaits en matière de confidentialité.
      Nous sommes là pour faciliter cette intégration et vous aider à tirer le
      meilleur parti de votre compte Facebook tout en préservant votre vie
      privée en ligne
    </>
  );
};

export { HelpsShowdashboardPrivateFacebook };
