import { NavLink, useNavigate } from "react-router-dom";
import CoreLayout from "../layouts/core-layout";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import ContactSchema from "../validators/ContactSchema";
import ContactInterface from "../interfaces/ContactInterface";
import Swal from "sweetalert2";
import {  sendMessageWithoutLog } from '../api/ContactApi' // Errors pages
//import { sendMessageRequest } from '../../../api/ContactApi' // Errors pages

//Multi language
import { useTranslation } from 'react-i18next';
import ReactGA from "react-ga4";



const Contact = () => {
  const { t } = useTranslation(); // Multi Language
  const navigate = useNavigate();
  ReactGA.event({
    category: "Auth",
    action: "contact",
    label: window.location.pathname + window.location.search
  });

  const { register, handleSubmit, formState: { errors } } = useForm<ContactInterface>({
    //mode: "onBlur",
    resolver: yupResolver(ContactSchema),
  });

  const onSubmit = (data: any) => {
    sendMessageWithoutLog(data).then(function (res: any) {
      Swal.fire({
        title: t('core:pages.account.AccountContact.Swal.success'),
        icon: "success",
        showCancelButton: false,
        showConfirmButton: false,
        //buttons: false,
        timer: 1000
      });
      navigate("/");
    }).catch(() => {
      Swal.fire({
        title: t("core:global.error.generic"),
        icon: "error",
        showCancelButton: false,
        showConfirmButton: false,
        //buttons: false,
        timer: 1000
      });
    });
  };

  return (
    <CoreLayout template="auth">
      <div className="">
        <div className="platform-topBar w-lg-600px mx-auto"></div>
        <div className="w-lg-600px bg-body shadow-sm p-10 p-lg-15 mx-auto">
          <div className='text-center platform-logoBlock mb-10' ><img src={`${process.env.REACT_APP_CDN_PLATFORMS}/logo.png`} className='platform-auth-logo' alt="Logo" /></div>
          <form className="form w-100" onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className="mb-10 text-center">
              <h1 className="text-dark mb-3">{t('guest:Contact.title')}</h1>
             
            </div>

            {/* <ShowErrorsApi errors={errors} /> */}

            <div className="row fv-row mb-7">
              <div className="col-xl-6">
                <label className="form-label fw-bolder text-dark fs-6" htmlFor="firstName">{t('guest:Contact.forms.input.firstName')} </label>
                <input className="form-control form-control-lg form-control-solid" type="text" required autoFocus placeholder={t('guest:Contact.forms.input.firstName')} autoComplete="given-name" id="firstName" {...register("firstName")} />
                <ErrorMessage
                  errors={errors}
                  name="firstName"
                  render={({ message }) => <p className="errorMsg">{t(message)}</p>}
                />
              </div>
              <div className="col-xl-6">
                <label className="form-label fw-bolder text-dark fs-6" htmlFor="lastName">{t('guest:Contact.forms.input.lastName')}</label>
                <input className="form-control form-control-lg form-control-solid" required type="text" placeholder={t('guest:Contact.forms.input.lastName')} id="lastName" autoComplete="family-name"  {...register("lastName")} />
                <ErrorMessage
                  errors={errors}
                  name="lastName"
                  render={({ message }) => <p className="errorMsg">{t(message)}</p>}
                />
              </div>
            </div>

            <div className="fv-row mb-7">
              <label className="form-label fw-bolder text-dark fs-6" htmlFor="email">{t('guest:Contact.forms.input.email')}</label>
              <input className="form-control form-control-lg form-control-solid" type="email" placeholder={t('guest:Contact.forms.input.email')} required id="email" autoComplete="email"   {...register("email")} />
              <ErrorMessage
                errors={errors}
                name="email"
                render={({ message }) => <p className="errorMsg">{t(message)}</p>}
              />
            </div>

            <div className="fv-row mb-7">
              <label className="form-label fw-bolder text-dark fs-6" htmlFor="message">{t('guest:Contact.forms.input.message')}</label>
              <textarea
                {...register("message")}
                className="form-control form-control-lg form-control-solid"
                style={{
                  minHeight: "300px",
                }}
              ></textarea>
              <ErrorMessage
                errors={errors}
                name="message"
                render={({ message }) => <p className="errorMsg">{t(message)}</p>}
              />
            </div>


            <div className="text-center">
              <button
                type="submit"
                className="btn btn-lg btn-primary w-100 mb-5"
              >
                {t('guest:register.continue')}
              </button>
              <div className="mt-5"><NavLink to="/login" className="text-primary" >{t('guest:register.BackToLogin')}</NavLink></div>
            </div>
          </form>
        </div>
      </div>
    </CoreLayout>
  );
};

export default Contact;

