import * as Yup from "yup";
import { firstNameRules, lastNameRules, phoneRules, cityRules, addressRules, zipCodeRules, countryRules } from "./rules/UserRules";

const CheckoutInfoSchema = Yup.object().shape({
  firstName: firstNameRules.required("validator:global.required"),
  lastName: lastNameRules.required("validator:global.required"),
  phone: phoneRules.required("validator:global.required"),
  city: cityRules.required("validator:global.required"),
  address: addressRules.required("validator:global.required"),
  zipCode: zipCodeRules.required("validator:global.required"),
  country: countryRules.required("validator:global.required"),
  message: Yup.string()
    .required("validator:global.required")
    .min(100, "validator:CheckoutInfoSchema.message.min")
    .max(1000, "validator:CheckoutInfoSchema.message.max")
});

export default CheckoutInfoSchema;