import { NavLink } from "react-router-dom";

//Multi language
import { useTranslation } from 'react-i18next';

const AccountLayout = (props: any) => {
	const { t } = useTranslation(); // Multi Language
	return (
		<div className='row mt-10'>
			<div className="col-md-3">
				<div className="card">
					<div className="card-body">

						<div className="menu menu-rounded menu-column menu-title-gray-700 menu-state-title-primary menu-active-bg-light-primary fw-bold">

							<div className="menu-item mb-1">
								<NavLink to="/account" className="menu-link py-3">{t('core:pages.account.layout.menu.myaccount')}</NavLink>
							</div>
							{/* 
							<div className="menu-item mb-1">
								<NavLink to="/" className="menu-link py-3">{t('core:pages.account.layout.menu.deletionRequest')}</NavLink>
							</div> */}

							<div className="menu-item mb-1">
								<NavLink to="/account/contact" className="menu-link py-3">{t('core:pages.account.layout.menu.contact')}</NavLink>
							</div>
							<div className="menu-item mb-1">
								<NavLink to="/account/quotations" className="menu-link py-3">{t('core:pages.account.layout.menu.quotations')}</NavLink>
							</div>
							<div className="menu-item mb-1">
								<NavLink to="/account/bills" className="menu-link py-3">{t('core:pages.account.layout.menu.bills')}</NavLink>
							</div>
							<div className="menu-item">
								<NavLink to="/logout" className="menu-link py-3">{t('core:pages.account.layout.menu.logout')}</NavLink>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="col-md-9">{props.children}</div>
		</div>
	)
}

export default AccountLayout;