import React from 'react';
import { NavLink } from "react-router-dom";
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const AuthLayout = (props: any) => {
	const { t } = useTranslation(); // Multi Language
	document.body.classList.add('auth-body-background-color'); // On ajoute la couleur de fond de la platforme
	return (<div className='auth-body'>
		<div className="d-flex flex-column flex-root">
			<div
				className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
				<div className="mt-10">{props.children}</div>
				<div className="text-center mt-3">v.{process.env.REACT_APP_VERSION}</div>
				<div className="text-center mt-3 text-dark"><NavLink to="/contact" className="text-dark">{t('core:pages.account.layout.menu.contact')}</NavLink> | <a href="/legals/cgu.html" target="blank" className="text-dark">{t("core:layout.footer.cgu")}</a> | <a href="/legals/cgv.html" target="blank" className="text-dark">{t("core:layout.footer.cgv")}</a> | <a href="/legals/privacy.html" target="blank" className="text-dark">{t("core:layout.footer.privacy")}</a></div>

			</div>
		</div>
	</div>)
}

AuthLayout.propTypes = {
	children: PropTypes.any,
};



export default AuthLayout;