import { useTranslation } from "react-i18next";
import { updateMeNotMe } from "../api/LinksApi";
import SwitchyButton from './SwitchyButton';

/**
 * Cookie IsMeBadgeHelpers
 *
 * @return {*}
 */
const IsMeBadgeHelpers = (props: any) => {
  const { t } = useTranslation(); // Multi Language

  const onSubmitIsMe = async (values: any) => {
    await updateMeNotMe(values);
  };

  return (
    <>
      <SwitchyButton
        defaultActive={props.me}
        activeText='Moi'
        inactiveText='Pas moi'
        activeClass='switchyButton-primary'
        inactiveClass='switchyButton-secondary'
        onChange={(isActive: boolean) =>
          onSubmitIsMe({ isMe: isActive, linkUuid: props.uuid })
        }
      />
    </>
  );
};

export default IsMeBadgeHelpers;





