import { NavLink, useNavigate } from "react-router-dom";
import CoreLayout from "../../layouts/core-layout";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountContactSchema from "../../validators/AccountContactSchema";
import AccountContactInterface from "../../interfaces/AccountContactInterface";
import Swal from "sweetalert2";

import AccountLayout from "./account-layout";

import { sendMessageRequest } from '../../api/ContactApi' // Errors pages

//Multi language
import { useTranslation } from 'react-i18next';


export const AccountContact = () => {
  const { t } = useTranslation(); // Multi Language
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AccountContactInterface>({
    mode: "onBlur",
    resolver: yupResolver(AccountContactSchema),
  });


  const onSubmit = (data: any) => {
    sendMessageRequest(data).then(function (res: any) {

      Swal.fire({
        title: t('core:pages.account.AccountContact.Swal.success'),
        icon: "success",
        showCancelButton: false,
        showConfirmButton: false,
        //buttons: false,
        timer: 1000
      });
      navigate("/");
    }).catch(() => {
      Swal.fire({
        title: t("core:global.error.generic"),
        icon: "error",
        showCancelButton: false,
        showConfirmButton: false,
        //buttons: false,
        timer: 1000
      });
    });
  };

  return (
    <CoreLayout template="main">
      <AccountLayout>
        <div className="card mb-5 mb-xl-10 mt-10">
          <div
            className="card-header border-0 cursor-pointer"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_account_profile_details"
            aria-expanded="true"
            aria-controls="kt_account_profile_details"
          >
            <div className="card-title m-0">
              <h3 className="fw-bolder m-0">{t('core:pages.account.AccountContact.title')}</h3>
            </div>
          </div>
          <div className="collapse show">
            <form
              className="form w-100"
              onSubmit={handleSubmit(onSubmit)}
              noValidate
            >
              <div className="card-body border-top p-9">

                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-bold fs-6">
                    <span className="required">{t('core:pages.account.AccountContact.forms.input.message')}</span>
                  </label>

                  <div className="col-lg-8 fv-row">
                    {t('core:pages.account.AccountContact.text')}
                    <div className="form-group mt-5">
                      <textarea
                        {...register("message")}
                        className="form-control"
                        style={{
                          minHeight: "300px",
                        }}
                      ></textarea>
                    </div>
                    <ErrorMessage
                      errors={errors}
                      name="message"
                      render={({ message }) => (
                        <p className="errorMsg">{t(message)}</p>
                      )}
                    />
                  </div>
                </div>


              </div>

              <div className="card-footer d-flex justify-content-end py-6 px-9">
                <NavLink
                  to="/"
                  className="btn btn-light btn-active-light-primary me-2"
                >
                  {t('core:global.button.cancel')}
                </NavLink>
                <button type="submit" className="btn btn-primary px-6">
                  {t('core:global.button.continue')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </AccountLayout >
    </CoreLayout >
  );
};
