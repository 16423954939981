import React from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import CoreLayout from "../../layouts/core-layout";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import PasswordEditInterface from '../../interfaces/PasswordEditInterface';
import passwordEditSchema from '../../validators/PasswordEditSchema';
import { editUserPasswordRequest } from '../../api/AuthApi';
import { ErrorMessage } from '@hookform/error-message';
import Swal from 'sweetalert2'

//Multi language
import { useTranslation } from 'react-i18next';

export function AccountPasswordEdit() {
	const { t } = useTranslation(); // Multi Language
	let navigate = useNavigate(); //Permet de changer de page après validation
	const { register, handleSubmit, formState: { errors } } = useForm<PasswordEditInterface>({
		mode: "onBlur",
		resolver: yupResolver(passwordEditSchema),
	});


	const onSubmit = async (editPasswordData: any) => {
		const status = await editUserPasswordRequest(editPasswordData)
		editPasswordData.oldPassword = editPasswordData.oldPassword.trim();
		editPasswordData.newPassword = editPasswordData.newPassword.trim();
		editPasswordData.confirmNewPassword = editPasswordData.confirmNewPassword.trim();
		switch (status) {
			case 401:
				Swal.fire({
					title: t('core:pages.account.AccountPasswordEdit.Swal.WrongOldpassword.title'),
					icon: 'error'
				}).then((result) => {
					/* Read more about isConfirmed, isDenied below */
					if (result.isConfirmed) {
						navigate("/dashboard/")
					}
				});
				break
			case 201:
				Swal.fire({
					title: t('core:pages.account.AccountPasswordEdit.Swal.SuccessfullyChanged.title'),
					icon: 'success'
				}).then((result) => {
					/* Read more about isConfirmed, isDenied below */
					if (result.isConfirmed) {
						navigate("/dashboard/")
					}
				});
				break
			case undefined:
				Swal.fire({
					title: t('core:pages.account.AccountPasswordEdit.Swal.SomethingWentWrong.title'),
					icon: 'error'
				}).then((result) => {
					/* Read more about isConfirmed, isDenied below */
					if (result.isConfirmed) {
						navigate("/dashboard/")
					}
				});
				break
		}
	};


	return (
		<CoreLayout template="main">
			<div className="card mb-5 mb-xl-10 mt-10">
				<div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
					<div className="card-title m-0">
						<h3 className="fw-bolder m-0">{t('core:pages.account.AccountPasswordEdit.title')}</h3>
					</div>
				</div>
				<div className="collapse show">
					<form className="form"
						onSubmit={handleSubmit(onSubmit)}
					>
						<div className="card-body border-top p-9">

							<div className="row mb-6">
								<label className="col-lg-4 col-form-label fw-bold fs-6">
									<span className="required">{t('core:pages.account.AccountPasswordEdit.forms.input.oldPassword')}</span>
								</label>
								<div className="col-lg-8 fv-row" >
									<input
										type="password"
										className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
										placeholder={t('core:pages.account.AccountPasswordEdit.forms.input.oldPassword')}
										{...register("oldPassword")}
									/>
									<ErrorMessage
										errors={errors}
										name="oldPassword"
										render={({ message }) => <p className="errorMsg">{t(message)}</p>}
									/>
								</div>
							</div>
							<div className="row mb-6">
								<label className="col-lg-4 col-form-label fw-bold fs-6">
									<span className="required">{t('core:pages.account.AccountPasswordEdit.forms.input.newPassword')}</span>
								</label>
								<div className="col-lg-8 fv-row">
									<input
										type="password"
										className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
										placeholder={t('core:pages.account.AccountPasswordEdit.forms.input.newPassword')}
										{...register("newPassword")}
									/>
									<ErrorMessage
										errors={errors}
										name="newPassword"
										render={({ message }) => <p className="errorMsg">{t(message)}</p>}
									/>
								</div>
							</div>
							<div className="row mb-6">
								<label className="col-lg-4 col-form-label fw-bold fs-6">
									<span className="required">{t('core:pages.account.AccountPasswordEdit.forms.input.confirmNewPassword')}</span>
								</label>
								<div className="col-lg-8 fv-row">
									<input
										type="password"
										className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
										placeholder={t('core:pages.account.AccountPasswordEdit.forms.input.confirmNewPassword')}
										{...register("confirmNewPassword")}
									/>
									<ErrorMessage
										errors={errors}
										name="confirmNewPassword"
										render={({ message }) => <p className="errorMsg">{t(message)}</p>}
									/>
								</div>
							</div>
						</div>
						<div className="card-footer d-flex justify-content-end py-6 px-9">
							<NavLink to="/account" className="btn btn-light btn-active-light-primary me-2">{t('core:pages.account.AccountPasswordEdit.button.discard')}</NavLink>
							<button type="submit" className="btn btn-primary px-6">
								{t('core:pages.account.AccountPasswordEdit.button.saveChange')}
							</button>
						</div>
					</form>
				</div>
			</div>

		</CoreLayout>)
}

