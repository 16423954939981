import CoreLayout from "../../layouts/core-layout";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React from "react";

export default function DiagnosisSelect() {
  const { t } = useTranslation(); // Multi Language
  return (
    <>
      <CoreLayout template="main">
        <div className="row mt-10">
          <div className="col-md-6">
            <div className="card mb-5 mb-xl-10 mt-10">
              <div
                className="card-header border-0 cursor-pointer"
                role="button"
              >
                <div className="card-title m-0">
                  <h3 className="fw-bolder m-0">
                    {t(
                      "core:pages.osculteo.DiagnosisSelect.IKnowMyProblem.title"
                    )}
                  </h3>
                </div>
              </div>
              <div className="collapse show">
                <div className="card-body border-top p-9">
                  {t("core:pages.osculteo.DiagnosisSelect.IKnowMyProblem.text")}
                  <div className="text-center mt-5">
                    <NavLink
                      to="/diagnosis"
                      className="btn btn-primary"
                    >
                      {t(
                        "core:pages.osculteo.DiagnosisSelect.IKnowMyProblem.button"
                      )}
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card mb-5 mb-xl-10 mt-10">
              <div className="card-header border-0">
                <div className="card-title m-0">
                  <h3 className="fw-bolder m-0">
                    {t(
                      "core:pages.osculteo.DiagnosisSelect.AnalysisOfMyProfile.title"
                    )}
                  </h3>
                </div>
              </div>
              <div className="collapse show">
                <div className="card-body border-top p-9">
                  {t(
                    "core:pages.osculteo.DiagnosisSelect.AnalysisOfMyProfile.text"
                  )}
                  <div className="text-center mt-5">
                    <NavLink
                      to="/diagnosis-analysis"
                      className="btn btn-primary"
                    >
                      {t(
                        "core:pages.osculteo.DiagnosisSelect.IKnowMyProblem.button"
                      )}
                    </NavLink>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CoreLayout>
    </>
  );
}
