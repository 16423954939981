
import CoreLayout from "../../layouts/core-layout";

const Legal = () => {
  const accesToken = localStorage.getItem("accessToken");
  if (accesToken) {
     return (
    <CoreLayout template="main">

  </CoreLayout>) 
  } else {
      return (
        <CoreLayout template="auth">
         
  </CoreLayout>)
  }
}

export default Legal;