import {  useState } from "react";
import { Badge, ButtonGroup, ToggleButton } from "react-bootstrap";
import { getlinksRequest } from "../../../api/LinksApi";
import { useTranslation } from "react-i18next";


const VeilleCardComponent = (props: any) => {
  const { t } = useTranslation();
  const monthVeilleTable = props.allDiagsVeille.map(
    (element: { createdAt: any }) =>
      String(new Date(element.createdAt).getMonth())
  );
  const dateNow = new Date();
  const [monthValue, setMonthValue] = useState(String(props.originalMonth));
  const searchLinksByMonth = async (value: string) => {
    for (const element of props.allDiagsVeille) {
      if (String(new Date(element.createdAt).getMonth()) === value) {
        const links = await getlinksRequest(element.uuid);
        return links;
      }
    }
  };
  const radios = [
    { name: `janvier ${dateNow.getFullYear()}`, value: "0" },
    { name: `février ${dateNow.getFullYear()}`, value: "1" },
    { name: `mars ${dateNow.getFullYear()}`, value: "2" },
    { name: `avril ${dateNow.getFullYear()}`, value: "3" },
    { name: `mai ${dateNow.getFullYear()}`, value: "4" },
    { name: `juin ${dateNow.getFullYear()}`, value: "5" },
    { name: `juillet ${dateNow.getFullYear()}`, value: "6" },
    { name: `aout ${dateNow.getFullYear()}`, value: "7" },
    { name: `septembre ${dateNow.getFullYear()}`, value: "8" },
    { name: `octobre ${dateNow.getFullYear()}`, value: "9" },
    { name: `novembre ${dateNow.getFullYear()}`, value: "10" },
    { name: `décembre ${dateNow.getFullYear()}`, value: "11" },
  ];

  return (
    <><div className="row mb-10">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <div>
              <b>{t(
                "core:pages.osculteo.results.veille.description"
              )} </b> 
     {String(props.originalMonth) === monthValue ? <Badge bg="primary">{t(
                "core:pages.osculteo.results.veille.original"
              )}</Badge> : <Badge bg="warning" text="dark"> {t(
                "core:pages.osculteo.results.veille.veille"
              )}</Badge>}
            </div>

            <div className="row mt-5">
              <ButtonGroup>
                {radios.map((radio, idx) => (
                  <ToggleButton className="toggleButton"
                    key={idx}
                    id={`radio-${idx}`}
                    type="radio"
                    variant={monthValue === radio.value ? "success" : "outline"}
                    name="radio"
                    value={radio.value}
                    disabled={!monthVeilleTable.includes(radio.value)}
                    checked={monthValue === radio.value}
                    onChange={(e) => setMonthValue(e.currentTarget.value)}
                    onClick={async () => props.setLinks(await searchLinksByMonth(radio.value))}
                  >
                    {radio.name}
                  </ToggleButton>
                ))}
              </ButtonGroup>
            </div>
          </div>
        </div>
      </div>
    </div></>
  );
};
export default VeilleCardComponent;
