import React, { useRef, useState } from "react";
import { Button, Card, Modal, Nav } from "react-bootstrap";
import ReactPlayer from "react-player";
import CaptionsComponent from "./CaptionsComponent";
import CommentComponent from "./CommentComponent";
import SentimentBadgeHelpers from "../../../../../helpers/SentimentBadgeHelpers";
import IsMeBadgeHelpers from "../../../../../helpers/IsMeBadgeHelpers";
import { updateTonality } from "../../../../../api/LinksApi";
import { updateTonalityVideo } from "../../../../../api/YoutubeApi";
import { useMutation, useQueryClient } from "react-query";

export default function YoutubeVideoComponent(props: any) {
  const [show, setShow] = useState(false);
  const [radioValue, setRadioValue] = useState("1");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [playing, setPlaying] = useState<boolean>(false);
  const playerRef = useRef<ReactPlayer>(null);

  const startVideoAt = (seconds: number): void => {
    if (playerRef && playerRef.current) {
      playerRef.current.seekTo(seconds);
      setPlaying(true);
    }
  };

  const pullData = (time: any) => {
    startVideoAt(time / 1000);
  };

  const parsedData: WordData[] = props.video?.keywordFind?.map((item: string) =>
    JSON.parse(item)
  );
  const sortedData = sortData(parsedData);

  return (
    <div className="row mt-10">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-1" style={{ fontSize: "38px" }}>
                <div className="text-center">
                  <b>{props.index}</b>
                </div>
              </div>
              <div className="col-md-3" onClick={handleShow}>
                <img
                  src={props.video?.image}
                  alt=""
                  className="rounded image-yt"
                />
              </div>
              <div className="col-md-3">
                <div className="text-center mt-5">
                  <div className="m-3">
                    <div className="miniTexte">Sentiment</div>
                    <SentimentBadgeHelpers
                      uuid={props.video?.uuid}
                      tonality={props.video?.tonality}
                      type={"video"}
                    />
                    <div className="miniTexte greymini">
                      Cliquez pour changer
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="row" onClick={handleShow}>
                  <h3 className="title-yt ">
                    {replaceHtmlApostrophe(props.video?.title)}
                  </h3>
                </div>
                <div style={{ color: "grey" }}>
                  <span>{props.video?.channelTitle}</span> -{" "}
                  <span>{getTimeAgo(new Date(props.video?.publishedAt))}</span>{" "}
                  - <span>{props.video?.views} vues</span>
                </div>
                <div className="row">
                  <p>{props.video?.description}</p>
                </div>
                <div className="row">
                  <div className="grid">
                    <div className="lg-col-1">
                      {" "}
                      {sortedData?.slice(0, 6).map((el: any) => (
                        <ButtonKeywords
                          text={el.word}
                          type={el.type}
                          action={handleShow}
                        />
                      ))}
                    </div>
                    <Modal show={show} onHide={handleClose} size="lg">
                      <Modal.Header>
                        <div className="col-md-5">
                          <div className="text-center mt-5">
                            <ReactPlayer
                              ref={playerRef}
                              url={props.video?.url}
                              className="react-player"
                              width="100%"
                              height="100%"
                              playing={playing}
                              controls={true}
                              style={{ borderRadius: "20px" }}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="row m-2">
                            <h3 className="mb-2">
                              {replaceHtmlApostrophe(props.video?.title)}
                            </h3>
                            <div style={{ color: "grey" }}>
                              <span>{props.video?.channelTitle}</span> -{" "}
                              <span>
                                {getTimeAgo(new Date(props.video?.publishedAt))}
                              </span>{" "}
                              - <span>{props.video?.views} vues</span>
                            </div>
                          </div>

                          <div className="row m-2">
                            <p>{props.video?.description}</p>
                          </div>
                          <div className="row m-2">
                            <div className="grid">
                              <div className="lg-col-1">
                                {" "}
                                {sortedData?.slice(0, 6).map((el: any) => (
                                  <ButtonKeywords
                                    text={el.word}
                                    type={el?.type}
                                    action={() => {
                                      startVideoAt(el.offset[0] / 1000);
                                    }}
                                  />
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal.Header>
                      <Modal.Body>
                        <Nav variant="tabs">
                          <Nav.Item>
                            <Nav.Link onClick={() => setRadioValue("1")}>
                              Contenu de la video
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link onClick={() => setRadioValue("2")}>
                              Commentaires
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                        <Card>
                          {radioValue === "1" && (
                            <CaptionsComponent
                              video={props.video}
                              keywords={sortedData}
                              func={pullData}
                            />
                          )}
                          {radioValue === "2" && (
                            <CommentComponent video={props.video} />
                          )}
                        </Card>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export const ButtonKeywords = (props: any) => {
  let classname = "btn btn-sm p-2 m-2 badgeKeyword-person";
  switch (props.type) {
    case "PERSON":
      classname = "btn btn-sm p-2 m-2 badgeKeyword-person";
      break;
    case "TITLE":
      classname = "btn btn-sm p-2 m-2 badgeKeyword-title";
      break;
    case "OTHER":
      classname = "btn btn-sm p-2 m-2 badgeKeyword-other";
      break;
    case "ORGANIZATION":
      classname = "btn btn-sm p-2 m-2 badgeKeyword-organization";
      break;
    default:
      break;
  }
  return (
    <button className={classname} onClick={props.action}>
      {" "}
      <span style={{ minWidth: `80px` }}>{props.text}</span>
    </button>
  );
};

function getTimeAgo(date: Date): string {
  const now = new Date();
  const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);
  const diffInMinutes = diffInSeconds / 60;
  const diffInHours = diffInMinutes / 60;
  const diffInDays = diffInHours / 24;
  const diffInMonths = diffInDays / 30;
  const diffInYears = diffInDays / 365;

  if (diffInYears >= 1) {
    return `il y a ${Math.round(diffInYears)} an(s)`;
  } else if (diffInMonths >= 1) {
    return `il y a ${Math.round(diffInMonths)} mois`;
  } else if (diffInDays >= 1) {
    return `il y a ${Math.round(diffInDays)} jour(s)`;
  } else if (diffInHours >= 1) {
    return `il y a ${Math.round(diffInHours)} heure(s)`;
  } else if (diffInMinutes >= 1) {
    return `il y a ${Math.round(diffInMinutes)} minute(s)`;
  } else {
    return "il y a quelques secondes";
  }
}

function replaceHtmlApostrophe(input: string): string {
  return input.replace(/&#39;/g, "'");
}

const sortData = (data: WordData[]) => {
  if (data) {
    return data.sort((a, b) => {
      if (a.type < b.type) {
        return -1;
      }
      if (a.type > b.type) {
        return 1;
      }
      // Si les types sont les mêmes, trier selon la longueur de 'offset'
      return b.offset.length - a.offset.length;
    });
  }
};

interface WordData {
  word: string;
  type: string;
  offset: number[];
}
