import * as Yup from "yup";
import { passwordRules } from "./rules/PasswordRules";
import { firstNameRules, lastNameRules, emailRules } from "./rules/UserRules";

const RegisterSchema = Yup.object().shape({
  firstName: firstNameRules.required("validator:global.required"),
  lastName: lastNameRules.required("validator:global.required"),
  email: emailRules.required("validator:global.required"),
  password: passwordRules.required("validator:global.required"),
  confirmPassword: passwordRules.required("validator:global.required").oneOf([Yup.ref('password'), null], 'validator:global.PasswordMmustMatch'),
  acceptCgu: Yup.boolean()
    .oneOf([true], 'validator:RegisterSchema.acceptCgu.oneOf'),
  acceptPrivacy: Yup.boolean()
    .oneOf([true], 'validator:RegisterSchema.acceptPrivacy.oneOf')
});

export default RegisterSchema;