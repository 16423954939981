import { useTranslation } from 'react-i18next';
import CoreLayout from '../../layouts/core-layout';
export const Maintenance = () => {
  const { t } = useTranslation(); // Multi Language
  return (
  <CoreLayout template="auth">
    <div className="row mt-10">
      <div className="col-md-6 offset-3">
        <div className="text-center" style={{ fontSize: "80px" }}><b>{t('errors:maintenance.maintenanceSite')}</b></div>
        <div className="text-center mt-5">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vel dolorem laudantium quos, repudiandae odit ipsam! Officia distinctio accusamus error est dignissimos, iste nam totam consectetur dolore similique voluptatem. Officia, quaerat?</div>
      </div>
</div>
  </CoreLayout>)
}
