
import CoreLayout from "../../layouts/core-layout";


const Privacy = () => {
  const accesToken = localStorage.getItem("accessToken");
  if (accesToken) {
     return (
    <CoreLayout template="main">
   
  </CoreLayout>) 
  } else {
      return (
        <CoreLayout template="auth">
      
  </CoreLayout>)
  }
}


export default Privacy;