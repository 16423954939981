import { NavLink } from "react-router-dom";
import CoreLayout from '../../layouts/core-layout';
import { useTranslation } from "react-i18next";

export const Error500 = () => {
  const { t } = useTranslation(); // Multi Language
  return (
  <CoreLayout template="auth">
    <div className="row mt-10">
      <div className="col-md-6 offset-3">
        <div className="text-center" style={{ fontSize: "250px" }}><b>{t('errors:error401.500')}</b></div>
        <div className="text-center mt-5">{t('errors:error401.message500')}</div>
				<div className="text-center mt-10"><NavLink to="/" className="btn btn-primary px-6">{t('errors:error401.return')}</NavLink></div>
      </div>
</div>

  </CoreLayout>)
}
