import React, { useState } from "react";
import CoreLayout from "../../../../layouts/core-layout";
import ResultsLayout from "../results-layout";
import { useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getAllNews, getNews } from "../../../../api/NewsApi";
import GoogleNewsCard from "./GoogleNewsCard";

function GoogleNewsSearchCard() {
  const { diagUUID } = useParams();
  const [isFetching, setIsFetching] = useState(false);

  const { data } = useQuery("news", () => getAllNews(diagUUID));

  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation(getNews);

  const scrapNiews = async () => {
    setIsFetching(true);
    try {
      await mutateAsync(diagUUID);
      queryClient.invalidateQueries("news");
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setIsFetching(false);
    }
  };
  console.log(data?.data);
  return (
    <CoreLayout template="main">
      <ResultsLayout description="Retrouver les articles qui parlent de vous.">
        {data?.data.data.length === 0 && (
          <div>
            <div className="w-lg-1000px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto mt-10">
              <div className="text-center mb-10">
                <h1 className="text-dark mb-3">
                  Recherche d'article de jounaux
                </h1>
              </div>
              <div className="mb-10">
                Notre service vous permet de trouver rapidement et facilement
                tous les articles de journaux qui parlent de vous. Il est idéal
                pour les personnes qui souhaitent suivre leur notoriété ou
                surveiller leur réputation.
              </div>
              <div className="text-center">
                <div
                  className="btn btn-flex flex-center btn-light btn-lg w-50 mb-10 mt-10"
                  onClick={async () => await scrapNiews()}
                >
                  {isFetching && (
                    <div
                      className="spinner-border text-primary m-5"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                  <img
                    alt="Logo"
                    src="/images/google-icon.svg"
                    className="h-20px me-3"
                  />{" "}
                  Rechercher les articles google news
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="row">
          {data?.data.data.length !== 0 && (
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div>
                    Nous avons trouvé {data?.data?.data?.length} articles de
                    journaux qui correspondent à votre recherche.
                    Voici les résultats de votre recherche pour le mot clé de
                  votre diagnostic.
                  </div>
                 
                </div>
              </div>
            </div>
          )}
          {data?.data.data?.map((news: any, index: number) => (
            <GoogleNewsCard news={news}/>
          ))}
        </div>
      </ResultsLayout>
    </CoreLayout>
  );
}

export default GoogleNewsSearchCard;
