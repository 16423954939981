
import CoreLayout from "../../layouts/core-layout";
import AccountLayout from "./account-layout";

//Multi language
import { useTranslation } from 'react-i18next';
import { Button } from "react-bootstrap";
import {  Download } from "react-bootstrap-icons";
import { useEffect, useState } from "react";
import { getAllStripeOrders } from "../../api/PlatformApi";
import Moment from "react-moment";


export const Bills = () => {
  const { t } = useTranslation(); // Multi Language
  const [stripeOrder, setStripeOrder] = useState<any[]>([])

  useEffect( () => {
   const fetchData = async () => {
    const res = await getAllStripeOrders();
    const response = res.data.data;
    setStripeOrder(response);
    return res.data.data;
   }
  
   fetchData();
  }, [])



  return (
    <CoreLayout template="main">
      <AccountLayout>
        <div className="card mb-5 mb-xl-10 mt-10">
          <div
            className="card-header border-0 cursor-pointer"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_account_profile_details"
            aria-expanded="true"
            aria-controls="kt_account_profile_details"
          >
            <div className="card-title m-0">
              <h2 className="fw-bolder m-0">{t('core:pages.account.acount-bills.title')}</h2>
            </div>
          </div>
          <div className="collapse show">
           

          <div data-kt-buttons="true">
            <label className="btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start p-6 mb-5">
              <div className="d-flex align-items-center me-2">
               
                <div className="flex-grow-1">
                  <h2 className="d-flex align-items-center fs-3 fw-bold flex-wrap">
                  {t('core:pages.account.acount-bills.freeSubscription')}
                  </h2>
                  <div className="fw-semibold opacity-50">
                  {t('core:pages.account.acount-bills.price')}
                  </div>
                </div>
              </div>

              <div className="ms-5">
                <span className="mb-2">€</span>
                <span className="fs-2x fw-bold">0</span>
                <span className="fs-7 opacity-50">
                  /<span data-kt-element="period">an</span>
                </span>
              </div>
              <div className="ms-5">
                <Button disabled> <Download size={20}/> </Button>
              </div>
            </label>
            </div>


          {
            stripeOrder.map(order => <OrderPaidComponent order={order} /> )
          }

            
          </div>
        </div>
      </AccountLayout >
    </CoreLayout >
  );
};

const OrderPaidComponent = (props: any) => {
  const { t } = useTranslation(); // Multi Language
  const getInvoice = () => {
    let alink = document.createElement("a");
        alink.href = props.order?.invoice;
        alink.download = props.order?.invoice;
        alink.click();
  }
  return(
    <div data-kt-buttons="true">
            <label className="btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start p-6 mb-5">
              <div className="d-flex align-items-center me-2">
               
                <div className="flex-grow-1">
                  <h2 className="d-flex align-items-center fs-3 fw-bold flex-wrap">
                  {t('core:pages.account.acount-bills.subscriptionName')} {props.order.lines[0].product.name}
                  </h2>
                  <div className="fw-semibold opacity-50">
                  {t('core:pages.account.acount-bills.paymentDate')} <Moment format="DD/MM/YYYY">{props.order.createdAt}</Moment> 
                  </div>
                </div>
              </div>

              <div className="ms-5">
                <span className="mb-2">€</span>
                <span className="fs-2x fw-bold">49</span>
                <span className="fs-7 opacity-50">
                  /<span data-kt-element="period">an</span>
                </span>
              </div>
              <div className="ms-5">
                <Button onClick={getInvoice}> <Download size={20}/> </Button>
              </div>
            </label>
            </div>
  )
}
