import { useTranslation } from 'react-i18next';
import { NavLink, useParams } from "react-router-dom";

/**
 * Renvoie un message si aucun resultats
 *
 * @return {*} 
 */
export default function NoResultsBlock() {
  const { t } = useTranslation(); // Multi Language
  let { diagUUID } = useParams();
  return (
    <div className="card mb-3">
      <div className="card-body">
        <div className="text-center mb-20  mt-20">
          <h3>{t('core:pages.osculteo.results.noData')}</h3>
          <NavLink className="btn btn-secondary mt-10" to={"/result/" + diagUUID + "/overview"}>{t('core:pages.osculteo.results.goToOverview')}</NavLink>
        </div>
      </div>
    </div>
  )
}