import CoreLayout from "../../layouts/core-layout";
import FacebookLogin from "@greatsumini/react-facebook-login";
import { useState } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaUser, FaEnvelope, FaFacebook } from "react-icons/fa";
import ResultsLayout from "./results/results-layout";

const TwinningFacebook = () => {
  const consigFacebook = process.env.REACT_APP_FACEBOOK_TWINNING_ID as string;
  const [twinning, setTwinning] = useState<any>({});
  const { t } = useTranslation(); // Multi Language

  return (
    <CoreLayout template="main">
      <ResultsLayout>

        <div>
          <div className="w-lg-1000px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto mt-10">
            <div className="text-center mb-10">
              <h1 className="text-dark mb-3">{t("core:pages.osculteo.dashboard.Facebook.title")}</h1>
            </div>
            <div className="mb-10">
            {t("core:pages.osculteo.dashboard.Facebook.text")}
            </div>
            <div className="fv-row mb-10"></div>

            <div className="text-center">
              <FacebookLogin
                appId={consigFacebook}
                render={(renderProps) => (
                  <button
                    type="submit"
                    className="btn btn-lg btn-primary w-150 mb-5 mt-10  p-3"
                    onClick={renderProps.onClick}
                  >
                    <FaFacebook /> {t("core:pages.facebook.facebookTwinning")}
                  </button>
                )}
                onSuccess={(response) => {
                  console.log("Login Success!", response);
                }}
                onFail={(error) => {
                  console.log("Login Failed!", error);
                }}
                onProfileSuccess={(response) => {
                  setTwinning(response);

                  console.log("Get Profile Success!", response);
                }}
              />
            </div>

            {twinning.email && (
              <div className="card-header border-0 cursor-pointer">
                <Card style={{ width: "18rem" }}>
                  <label className="form-label fs-6 fw-bolder text-dark">
                    {t("core:pages.facebook.facebookData")}
                  </label>
                  <Card.Body>
                    {twinning.name && (
                      <Card.Title>
                        <FaUser /> {twinning.name}
                      </Card.Title>
                    )}
                    {twinning.email && (
                      <Card.Text>
                        <FaEnvelope /> {twinning.email}
                      </Card.Text>
                    )}
                    <Card.Img
                      variant="bottom"
                      src={twinning.picture?.data?.url}
                    />
                  </Card.Body>
                </Card>
              </div>
            )}
          </div>
        </div>
      </ResultsLayout>
    </CoreLayout>
  );
};
export default TwinningFacebook;
