import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getlinksRequest, updateMeNotMe, updateTonality } from "../../../api/LinksApi";
import CoreLayout from "../../../layouts/core-layout";
import ResultsLayout from "./results-layout";
import { useTranslation } from "react-i18next";
import { useCart } from "react-use-cart";
import SentimentBadgeHelpers from "../../../helpers/SentimentBadgeHelpers";
import LoadingResults from "./elements/LoadingResults";
import IsMeBadgeHelpers from "../../../helpers/IsMeBadgeHelpers";
import { useMutation, useQuery, useQueryClient } from "react-query";
import NoResultsBlock from "./elements/NoResultsBlock";
import ButtonAdd from "../components/ButtonAdd";
import ButtonRemove from "../components/ButtonRemove";
import {
  ChangeIsMeModal,
  ChangeSentimentModal,
} from "../components/ChangeModal";
import VeilleCardComponent from "../components/VeilleDateComponent";
import {
  getDiagnosisByGroupUuid,
  getDiagnosisByUIIDRequest,
} from "../../../api/DiagnosisApi";

export default function LinksResults() {
  const [allDiagsVeille, setAllDiagsVeille] = useState<any[]>([]);
  const [originalDiag, setOriginalDiag] = useState<any>({});
  const [originalMonth, setOriginalMonth] = useState<any>();
  const { t } = useTranslation(); // Multi Language
  const { diagUUID } = useParams();
  const { data, isLoading } = useQuery("links", () =>
    getlinksRequest(diagUUID)
  );

  const [linksto, setLinks] = useState<any>(data);
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation(updateTonality);
  const onSubmitTonality = async (values: any) => {
    await mutateAsync(values);
    queryClient.invalidateQueries("links");
  };

  useEffect(() => {
    getDiagnosisByUIIDRequest(diagUUID).then(function (res: any) {
      setOriginalMonth(new Date(res?.data.data.createdAt).getMonth());
      setOriginalDiag(res?.data.data);
    });
    getDiagnosisByGroupUuid(diagUUID)
      .then(function (res: any) {
        setAllDiagsVeille(res?.data.data);
      })
      .catch((err) => console.log("veilleDateComponent", err));
  }, [diagUUID]);

  if (isLoading) {
    return (
      <CoreLayout template="main">
        <ResultsLayout
          description={t("core:pages.osculteo.results.section.suggest.text")}
        >
          <div className="text-center mt-20 mb-20">
            <LoadingResults />
          </div>
        </ResultsLayout>
      </CoreLayout>
    );
  }

  if (data?.length === 0) {
    return (
      <CoreLayout template="main">
        <ResultsLayout
          description={t("core:pages.osculteo.results.section.suggest.text")}
        >
          <NoResultsBlock />
        </ResultsLayout>
      </CoreLayout>
    );
  }

  return (
    <>
      <CoreLayout template="main">
        <ResultsLayout
          description={t("core:pages.osculteo.results.section.link.text")}
        >
          <br />

          {allDiagsVeille.length > 1 && (
            <VeilleCardComponent
              allDiagsVeille={allDiagsVeille}
              original={originalDiag}
              originalMonth={originalMonth}
              setLinks={setLinks}
            />
          )}
          {linksto === undefined
            ? data?.map((item: any) => (
              <div>
                <ResultRow item={item} />
              </div>
            ))
            : linksto?.map((item: any) => (
              <div key={item.id}>
                <ResultRow key={item.id} item={item} changeTonality={onSubmitTonality} />
              </div>
            ))}
        </ResultsLayout>
      </CoreLayout>
    </>
  );
}

/**
 * ResultRow
 *
 * @param {*} props
 * @return {*}
 */
const ResultRow = (props: any) => {
  const item = props.item;
  const { diagUUID } = useParams();
  const { inCart } = useCart();
  const alreadyAdded = inCart(item.id);

  return (
    <>
      <div className="row mt-10">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-1" style={{ fontSize: "38px" }}>
                  <div className="text-center">
                    <b>{item.position}</b>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="text-center mt-5">
                    <div className="miniTexte">Sentiment</div>
                    <SentimentBadgeHelpers uuid={item.uuid} tonality={item.tonality} type={"link"} />
                    <div className="miniTexte greymini">Cliquez pour changer</div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="text-center mt-5">
                    <div className="miniTexte">C'est vous?</div>
                    <IsMeBadgeHelpers me={item.itSMe} uuid={item.uuid} />
                    <div className="miniTexte greymini">Cliquez pour changer</div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div>
                    <b>
                      <img
                        alt="img"
                        src={
                          "https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=" +
                          item.url +
                          "&size=16"
                        }
                      />
                      &nbsp;&nbsp;
                      <span className="resultsRowTitle">{item.title}</span>
                    </b>
                  </div>
                  <div>{item.description}</div>
                  <a href={item.url} target="blank" className="text-primary">
                    {item.url}
                  </a>
                </div>

                <div className="col-md-2">
                  <div className="text-end mt-10">
                    {alreadyAdded ? (
                      <ButtonRemove itemId={item.id} />
                    ) : (
                      <ButtonAdd item={item} diag={diagUUID} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
