/* eslint-disable array-callback-return */
import React, { useState } from "react";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import {
  countTonality,
  getAllDiagnosisRequest,
  getDiagnosisByGroupUuid,
} from "../../../api/DiagnosisApi";

import { DiagnosisType } from "../../../types/DiagnosisType";
import Moment from "react-moment";


//Axios Instance

//Multi language
import { useTranslation } from "react-i18next";
import { DiagnosisContext } from "../../../contexts/DiagnosisContext";
//import LoadingResults from './results/elements/LoadingResults';

const AvalaibleOfferModale = (props: AvailableProps) => {
  const { slug, rest, offerName, totalDiag } = props;
  const { t } = useTranslation(); // Multi Language
  const [diagnosises, setDiagnosises] = useState<DiagnosisType[]>([]);

  useEffect(() => {
    getAllDiagnosisRequest()
      .then(function (res: any) {
        setDiagnosises(res.data.data);
      })
      .catch(() => {
        console.log("error");
      });
  }, []);

  return (
    <div>
      <>
        <div className="card">
          <div className="card-header border-0">
            <div className="card-title m-0">
              <div className="dashboard-offer-title">
                {offerName} : {rest}{" "}
                {t(
                  "core:pages.account.account-quotation.AvailableOfferModal.diagAvailable"
                )}{" "}
                {totalDiag}
              </div>
            </div>
            {/* <div className="card-toolbar">
              <HelpsModals>
                <HelpsListDiag />
              </HelpsModals>
            </div> */}
          </div>
          <div className="card m-0">
            {
              <div>
                {diagnosises?.map((e, index) => {
                  if (e.isVeille === null) {
                    if (e.offerSlug === slug)
                      return (
                        <DashboardOfferBlock
                          key={index}
                          diagnosis={e}
                          indexNumber={index + 1}
                        />
                      );
                  }
                })}
              </div>
            }
          </div>
          {[...Array(rest)].map((x, i) => (
            <DashboardOfferBlockEmpty
              key={i}
              slug={slug}
              rest={0}
              offerName={offerName}
              totalDiag={0}
            />
          ))}
        </div>
      </>
    </div>
  );
};

export default AvalaibleOfferModale;

const DashboardOfferBlockEmpty = (props: AvailableProps) => {
  const { t } = useTranslation(); // Multi Language
  const value = React.useContext(DiagnosisContext);
  return (
    <div className="dashboard-offer-block-disable mb-10">
      <div className="dashboard-offer-block-disable-subtitle">
        {t("core:pages.account.account-quotation.AvailableOfferModal.location")}{" "}
        {props.offerName}{" "}
        {t(
          "core:pages.account.account-quotation.AvailableOfferModal.available"
        )}
      </div>
      <div className="dashboard-offer-block-disable-subtext">
        {t(
          "core:pages.account.account-quotation.AvailableOfferModal.searchLocation"
        )}{" "}
      </div>
      <div className="text-center mt-10">
        <NavLink
          to="/diagnosis"
          className="btn btn-secondary btn-sm"
          onClick={() => {
            value.offerSlug = props.slug;
          }}
        >
          {t("core:pages.account.account-quotation.AvailableOfferModal.use")}
        </NavLink>
      </div>
    </div>
  );
};

const DashboardOfferBlock: React.FC<any> = (props: OfferBlockProps) => {
  const { diagnosis, indexNumber } = props;
  const { t } = useTranslation(); // Multi Language

  // const [percent, setPercent] = useState({
  //   positiveResult: 10,
  //   negativeResult: 0,
  //   neutralResult: 0,
  // });
  const [group, setGroup] = useState<any[]>([]);

  useEffect(() => {
    getDiagnosisByGroupUuid(diagnosis?.groupeUuid)
      .then(function (res) {
        setGroup(res.data.data);
      })
      .catch(() => console.log("error getDiagnosisByGroupUuid"));
    countTonality(diagnosis?.uuid)
      .then(function (res: any) {
        const count = res.data.data.links;
        percentResult(
          Number(count.neutral),
          Number(count.negative)
        );
        // setPercent(result);
      })
      .catch(() => {
        console.log("error");
      });
  }, [diagnosis?.groupeUuid, diagnosis?.uuid]);

  return (
    <div className="dashboard-offer-block-active m-10 ribbon ribbon-top ribbon-vertical">
      {group.length > 1 && <div className="ribbon-label bg-danger">{t(
        "core:pages.osculteo.results.veille.tag"
      )}</div>}
      <div className="dashboard-offer-block-active-subtitle">
        {" "}
        {t(
          "core:pages.account.account-quotation.AvailableOfferModal.diagnosisNumber"
        )}
        {indexNumber}
      </div>
      <div className="dashboard-offer-block-active-ref">
        {t(
          "core:pages.account.account-quotation.AvailableOfferModal.diagnosisRef"
        )}{" "}
        {diagnosis.uuid}
      </div>
      <div className="dashboard-offer-block-subtext"></div>
      <div className="dashboard-offer-block-subtext mt-5">
        <div className="row">
          <div className="col-md-6">
            <div>
              {t(
                "core:pages.account.account-quotation.AvailableOfferModal.keyword"
              )}{" "}
              <b>{diagnosis.keyword}</b>
            </div>
            <div>
              {t(
                "core:pages.account.account-quotation.AvailableOfferModal.negativKeyword"
              )}{" "}
              <b>{diagnosis.negativeKeyword}</b>
            </div>
            <div>
              {t(
                "core:pages.account.account-quotation.AvailableOfferModal.creationDate"
              )}{" "}
              <b>
                <Moment format="DD/MM/YYYY">{diagnosis.createdAt}</Moment>
              </b>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center mt-10">
        <NavLink
          to={"/result/" + diagnosis.uuid + "/overview"}
          className="btn btn-primary btn-sm"
        >
          {t("core:pages.osculteo.dashboard.YourDiagnosis.button.show")}
        </NavLink>
      </div>
    </div>
  );
};

type AvailableProps = {
  slug: string;
  rest: number;
  offerName: string;
  totalDiag: number;
};

type OfferBlockProps = {
  diagnosis: any;
  indexNumber: number;
};

export const percentResult = (
  neutral: number,
  negative: number
) => {
  const total = neutral + negative;
  return {
    negativeResult: Number(((negative / total) * 100).toPrecision(1)),
    neutralResult: Number(((neutral / total) * 100).toPrecision(1)),
  };
};
