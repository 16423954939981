import Logout from "../pages/auth/Logout";
import AxiosServices from "./AxiosServices";


export const sendDeleteDemandRequest = async () => {
    AxiosServices.post("/auth/delete-demand");
    return { success: true }
  };
  


export const sendDeleteOwnAccountRequest = async (data: any) => {
  try {
    const response = await AxiosServices.post("/auth/delete-own-account", data);
    return response.data;
  } catch (error) {
    // Gérer les erreurs ici
    console.error("Erreur lors de la suppression du compte :", error);
    throw error;
  }

};
