import { AxiosServicesByName } from "./AxiosServices";

/**
 * getPrivateDataRequest
 * - recupere les privates data sur un diag existant
 * @param {*} payload
 * @return {*}
 */
export const getPseudoRequest = async (diagUuid: any, payload: any) => {
  return await AxiosServicesByName.post(`/pseudo/pseudo/${diagUuid}`, payload);
};

/**
 * getAllPrivateDataRequest
 * - recupere toute les privates data
 * @param {*} payload
 * @return {*}
 */
export const getAllPseudoRequest = async (diagUuid: string) => {
  return await AxiosServicesByName.get(`/pseudo/all-pseudo/${diagUuid}`);
};

/**
 * getPrivateDataByuuidRequest
 * - recupere une private data a partir de son uuid
 * @param {*} payload
 * @return {*}
 */
export const getPseudoByuuidRequest = async (pseudoUuid: any) => {
  const response = await AxiosServicesByName.get(
    `/pseudo/pseudo-by-uuid/${pseudoUuid}`
  );
  return response;
};

/**
 * getPrivateDataRemainingRequest
 * - retourne le nombre d eprivate data restante
 * @param {*} payload
 * @return {*}
 */
export const getPseudoRemainingRequest = async (payload: any) => {
  const res = await AxiosServicesByName.get(
    `/pseudo/pseudo-remaing/${payload}`
  );

  return res;
};
