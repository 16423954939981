import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getStripeOrderByname } from "../../api/PlatformApi";
import CoreLayout from "../../layouts/core-layout";
import { useTranslation } from "react-i18next";

const Success = () => {
  const navigate = useNavigate();
  const [order, setOrder] = useState<any>({});
  const { t } = useTranslation(); // Multi Language

  useEffect(() => {
    getStripeOrderByname("Osculteo Premium")
      .then(function (res: any) {
        setOrder(res.data.data[0]);
      })
      .catch(() => {
        console.log("error");
      });
  }, []);

  const onButtonClick = () => {
        let alink = document.createElement("a");
        alink.href = order.invoice;
        alink.download = order.invoice;
        alink.click();
  };

  return (
    <CoreLayout template="main">
      <div className="card shadow-sm ">
        <div className="card-header">
          <h3 className="card-title">{t('core:pages.account.account-quotation.Succes.tySubscription')}</h3>
          <div className="card-toolbar">
            <button
              type="button"
              className="btn btn-sm btn-secondary"
              onClick={() => onButtonClick()}
            >
              {t('core:pages.account.account-quotation.Succes.downloadInvoice')}
            </button>
          </div>
        </div>
        <div className="card-body p-0">
          <div className="card-p mb-10">
          {t('core:pages.account.account-quotation.Succes.osculteoPremiumUnlock')}
            <div className="text-center px-4 m-5">
              <button
                type="button"
                className="btn btn-sm btn-primary"
                onClick={() => navigate("/dashboard")}
              >
                {t('core:pages.account.account-quotation.Succes.dashboard')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </CoreLayout>
  );
};

export default Success;
