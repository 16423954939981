import * as React from "react";
import { Link, NavLink } from "react-router-dom";
import CoreLayout from "../../layouts/core-layout";

import { useTranslation } from "react-i18next";

export default function ForgotPasswordConfirm() {
  const { t } = useTranslation(); // Multi Language
  return (
    <>
      <CoreLayout template="auth">
        <div className="">
          <div className="platform-topBar w-lg-800px mx-auto"></div>
          <div className="w-lg-800px bg-body shadow-sm p-10 p-lg-15 mx-auto text-center">
            <div className="text-center platform-logoBlock mb-10">
              <img
                src={`${process.env.REACT_APP_CDN_PLATFORMS}/logo.png`}
                className="platform-auth-logo"
                alt="Logo"
              />
            </div>
            <h1>{t("guest:ForgotPasswordConfirm.Successfuly")}</h1>
            <div className="mt-10 mb-10">
              <div className="mb-10">
                {t("guest:ForgotPasswordConfirm.text1")}
              </div>
              <div className="mb-10">
                {t("guest:ForgotPasswordConfirm.text2")}{" "}
              <Link to="/register"> <b> {t("guest:ForgotPasswordConfirm.register")}</b></Link>{" "}
                {t("guest:ForgotPasswordConfirm.or")}{" "}
                <Link to="/contact"> <b>  {t("guest:ForgotPasswordConfirm.contact")} </b></Link>{" "}
                {t("guest:ForgotPasswordConfirm.text3")}
              </div>
              <NavLink to="/" className="btn btn-primary">
                {t("guest:ForgotPasswordConfirm.BackToLogin")}
              </NavLink>
            </div>
          </div>
        </div>
      </CoreLayout>
    </>
  );
}
