import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import CoreLayout from '../../layouts/core-layout';
import Swal from 'sweetalert2'

import ResetPasswordSchema from "../../validators/ResetPasswordSchema"
import ResetPasswordInterface from "../../interfaces/ResetPasswordInterface"

import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';

import { authResetPasswordRequest } from "../../api/AuthApi";

//Multi language
import { useTranslation } from 'react-i18next';
import ReactGA from "react-ga4";

export default function ResetPassword() {
  const { t } = useTranslation(); // Multi Language
  let navigate = useNavigate(); //Permet de changer de page après validation
  ReactGA.event({
    category: "Auth",
    action: "password-lost",
    label: window.location.pathname + window.location.search
  });
  const { register, handleSubmit, formState: { errors } } = useForm<ResetPasswordInterface>({
    mode: "onBlur",
    resolver: yupResolver(ResetPasswordSchema),
  });

  const { userId, passwordResetToken } = useParams();

  const onSubmit = (data: any) => {
    data.newPassword = data.newPassword.trim();
    data.confirmNewPassword = data.confirmNewPassword.trim();

    var resetUserData = JSON.stringify({
      userId: userId,
      passwordResetToken: passwordResetToken,
      newPassword: data.newPassword,
    });
    authResetPasswordRequest(resetUserData).then(function (response: any) {
      Swal.fire({
        title: "Password has been changed successfuly",
        text: "Now redirect to login page.",
        cancelButtonText: "Close",
        icon: "success",
        showCancelButton: false,
        showConfirmButton: false,
        cancelButtonColor: "#f27474",
        timer: 2000
      });
      setTimeout(() => {
        return navigate("/login");
      }, 4000);
    })
      .catch(function (error: any) {
        console.log(`resetUserPassword: ${error}`);
      });
  };

  return (
    <CoreLayout template="auth">
      <div className="">
        <div className="platform-topBar w-lg-500px mx-auto"></div>
        <div className="w-lg-500px bg-body shadow-sm p-10 p-lg-15 mx-auto">
          <div className='text-center platform-logoBlock mb-10' ><img src={`${process.env.REACT_APP_CDN_PLATFORMS}/logo.png`} className='platform-auth-logo' alt="Logo" /></div>

          <form className="form w-100" onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className="text-center mb-10">
              <h1 className="text-dark mb-3">{t('guest:ResetPassword.title')}</h1>
            </div>

            <div className="fv-row mb-10">
              <label className="form-label fs-6 fw-bolder text-dark">{t('guest:ResetPassword.forms.input.newPassword')}</label>
              <input
                type="password"
                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                placeholder={t('guest:ResetPassword.forms.input.newPassword')}
                {...register("newPassword")}
              />
              <ErrorMessage
                errors={errors}
                name="newPassword"
                render={({ message }) => <p className="errorMsg">{t(message)}</p>}
              />

            </div>

            <div className="fv-row mb-10">
              <label className="form-label fs-6 fw-bolder text-dark">{t('guest:ResetPassword.forms.input.confirmNewPassword')}</label>
              <input
                type="password"
                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                placeholder={t('guest:ResetPassword.forms.input.confirmNewPassword')}
                {...register("confirmNewPassword")}
              />
              <ErrorMessage
                errors={errors}
                name="confirmNewPassword"
                render={({ message }) => <p className="errorMsg">{t(message)}</p>}
              />
            </div>

            <div className="text-center">
              <button type="submit" className="btn btn-lg btn-primary w-100 mb-5">{t('guest:ResetPassword.continue')}</button>
            </div>

          </form>
        </div>
      </div>
    </CoreLayout>
  );
}
