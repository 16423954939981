import { AxiosServicesByName } from "./AxiosServices";

export const getNews = async (payload: any) => {
  return await AxiosServicesByName.get(`/news/get-news/${payload}`);
};

export const getAllNews = async (payload: any) => {
    return await AxiosServicesByName.get(`/news/all-news/${payload}`);
  };

  export const updateTonalityNews = async (payload: any) => {
    try {
      return await AxiosServicesByName.put(`/news/update-tonality`, payload);
    } catch (error) {
      console.log("error");
    }
  };