import AuthLayout from "./auth/auth-layout";
import MainLayout from "./main/main-layout";
const CoreLayout = (props: any) => {
  switch (props.template) {
    case "auth":
      return <AuthLayout>{props.children}</AuthLayout>;
    case "main":
      return <MainLayout>{props.children}</MainLayout>;
    default:
      return <>{props.children}</>;
  }
};

export default CoreLayout;
