import { PlusLg } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { useCart } from "react-use-cart";
import { getDiagnosisByUIIDRequest } from "../../../api/DiagnosisApi";
import ReactGA from "react-ga4";

const ButtonAdd = (props: any) => {
  const { t } = useTranslation();
  const { addItem } = useCart();
  const submitItem = async () => {
    const diagnosis = await getDiagnosisByUIIDRequest(props.diag)
    const itemToSubmit = { ...props.item, keyword: diagnosis?.data.data.keyword, negativeKeyword: diagnosis?.data.data.negativeKeyword }
    addItem(itemToSubmit)
  }
  ReactGA.event({
    category: "Core",
    action: "add-to-cart",
    label: window.location.pathname + window.location.search
  });
  return (
    <div>
      <button onClick={submitItem} className="btn btn-sm btn-primary">
        Ajouter
      </button>
    </div>
  );
};
export default ButtonAdd;
