import React, { useState } from "react";
import CoreLayout from "../../../../layouts/core-layout";
import ResultsLayout from "../results-layout";
import {
  getAllDarkWebInfos,
  getDarkWebInfos,
} from "../../../../api/DarkWebApi";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import { t } from "i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import DarkWebInterface from "../../../../interfaces/DarkWebInterface";
import DarkwebSchema from "../../../../validators/DarkwebSchema";
import DarkWebResultCard from "./DarkWebResultCard";

function DarkWebSearchCard() {
  const { diagUUID } = useParams();
  const [isFetching, setIsFetching] = useState(false);

  const { data } = useQuery("dark", () => getAllDarkWebInfos(diagUUID));

  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation(getDarkWebInfos);

  const onSubmit = async (payload: any) => {
    setIsFetching(true);

    const request = { keywordEmail: payload.keywordEmail, diagUuid: diagUUID };

    try {
      await mutateAsync(request);
      queryClient.invalidateQueries("dark");
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setIsFetching(false);
    }
  };

  console.log("data", data);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<DarkWebInterface>({
    //mode: "onBlur",
    resolver: yupResolver(DarkwebSchema),
  });

  return (
    <CoreLayout template="main">
      <ResultsLayout description="Retrouver les données personelle qui ont fuitées.">
        {data?.data.data.length === 0 && <div>
          <div className="w-lg-1500px bg-body rounded shadow-sm p-10 p-lg-20 mx-auto mt-10">
            <form
              className="form w-100"
              onSubmit={handleSubmit(onSubmit)}
              noValidate
            >
              <div className="text-mb-10 font-weight-bold">
                <h1 className="text-dark mb-3 ">DarkWeb</h1>
              </div>
              <div className="mb-10">
                Dans un monde numérique en constante évolution, la protection de
                vos données est primordiale. Notre outil scanne rapidement le
                darkweb pour vérifier si votre adresse e-mail a été compromise.
                Les fuites peuvent exposer vos informations à des risques tels
                que le hamçonnage ou la fraude. En quelques secondes, soyez
                informé de la sécurité de votre e-mail. Pour votre tranquilité
                d'esprit, nous ne stockons pas votre adresse et assurons une
                recherche sécurisé. Prévenez les menaces. Vérifiez maintenant.
              </div>

              <div className="fv-row mb-5">
                <label className="form-label fs-5 fw-bolder">Email</label>
                <input
                  required
                  className="form-control form-control-lg  form-control-solid"
                  type="text"
                  id="keywordEmail"
                  placeholder={"Email"}
                  {...register("keywordEmail")}
                />
                <ErrorMessage
                  errors={errors}
                  name="keywordEmail"
                  render={({ message }) => (
                    <p className="errorMsg">Email requis</p>
                  )}
                />
              </div>

              <div className="text-center">
                <button
                  type="submit"
                  className="btn btn-lg btn-primary w-50 mb-5"
                >
                  valider
                  {isFetching ? (
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  ) : null}
                </button>
              </div>
            </form>
          </div>
        </div>}

        {data?.data.data.length !== 0 && <div className="mt-10 ">
          {" "}
          <p className="fs-3">Resultats</p>
        </div>}
        {data?.data.data.map((darkObject: any) => (
          <DarkWebResultCard result={darkObject} />
        ))}
      </ResultsLayout>
    </CoreLayout>
  );
}

export default DarkWebSearchCard;
