import React, { FC } from "react";
export type DiagnosisContextState = {
  offerSlug: string | null;
  selectOfferSlug: (arg0: string) => void;
};

const contextDefaultValues: DiagnosisContextState = {
  offerSlug: "osc_free",
  selectOfferSlug: () => {},
};

const getOfferSlugFromStorage = () => {
  const offerSlug = localStorage.getItem("offerSlug");
  return offerSlug;
};

export const DiagnosisContext = React.createContext(contextDefaultValues);

export const DiagnosisProvider: FC<any> = ({ children }) => {
  const [offerSlug, setOfferSlug] = React.useState(
    getOfferSlugFromStorage()
  );

  const selectOfferSlug = (offerSlug: string) => {
    localStorage.setItem("offerSlug", offerSlug);
    setOfferSlug(offerSlug);
  };

  return (
    <DiagnosisContext.Provider
      value={{
        offerSlug,
        selectOfferSlug,
      }}
    >
      {children}
    </DiagnosisContext.Provider>
  );
};