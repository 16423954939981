
/**
 *  ProgressBarColoredHelpers
 *
 * @return {*} 
 */
const ProgressBarColoredHelpers = (props: any) => {
  const { progress = 0 } = props;
  let barcolor_css = "bg-success";
  if (progress < 60) {
    barcolor_css = "bg-danger";
  } else if (progress < 90) {
    barcolor_css = "bg-warning";
  } else {
    barcolor_css = "bg-success";
  }
  return <div className={'rounded h-5px ' + barcolor_css} role="progressbar" style={{ width: progress + '%' }}></div>
}

export default ProgressBarColoredHelpers;