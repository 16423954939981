import { useEffect, useState } from "react";
import { NavLink, Navigate } from "react-router-dom";

import CoreLayout from "../../layouts/core-layout";
import AccountLayout from "./account-layout";
//Axios Instance
import { getUserInfosRequest } from "../../api/AuthApi"; // Errors pages
import LoadingResults from "../osculteo/results/elements/LoadingResults";
import Swal from "sweetalert2";
import ReactGA from "react-ga4";
//Multi language
import { useTranslation } from "react-i18next";
import { sendDeleteDemandRequest, sendDeleteOwnAccountRequest } from "../../api/DeleteDemandApi";
import Logout from "../auth/Logout";
export const AccountShow = () => {
  const { t } = useTranslation(); // Multi Language


  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    language: "",
  });

  const [infos, setInfos] = useState({
    email: "",
    phone: "",
    city: "",
    address: "",
    zipCode: "",
    country: "",
  });

  useEffect(() => {
    ReactGA.event({
      category: "Core",
      action: "show-my-account",
      label: window.location.pathname + window.location.search
    });

    getUserInfosRequest().then(function (res) {
      setInfos(res.data.data);
      setUser(res.data.data);
    });
  }, []);
  const [confirmChecked, setConfirmChecked] = useState(false);

  const handleClick = async () => {
    const clientId= process.env.REACT_APP_API_CLIENT_ID
    const data = {
      email: infos.email,
      clientId, 
    };
console.log("data", data);

    try {
      await sendDeleteOwnAccountRequest(data);
      Swal.fire({
        title: t("Votre compte a bien été supprimé. Vous allez être déconnecté."),
        icon: "success",
        showCancelButton: false,
        showConfirmButton: false,
        timer: 4000,
      });
      setTimeout(() => {
        window.location.href = "/logout"; // Redirection vers la page de déconnexion
      }, 4000);
    } catch (error) {
      console.error("Erreur lors de la tentative de suppression du compte", error);
      // Gérer l'erreur de manière appropriée
    }
  };

  return (
    <CoreLayout template="main">
      <AccountLayout>
        <div className="card mb-5 mb-xl-10">
          <div className="card-header cursor-pointer">
            <div className="card-title m-0">
              <h3 className="fw-bolder m-0">
                {t("core:pages.account.AccountShow.title")}
              </h3>
            </div>
            <div className="card-toolbar">
              <NavLink
                to="/account/password/edit"
                className="btn btn-warning btn-sm"
              >
                {t("core:pages.account.AccountShow.button.changePassword")}
              </NavLink>
              &nbsp;&nbsp;
              <NavLink to="/account/edit" className="btn btn-primary btn-sm">
                {t("core:pages.account.AccountShow.button.editProfil")}
              </NavLink>
            </div>
          </div>
          <div className="card-body p-9">
            {user.firstName.length ? (
              <>
                <div className="row mb-7">
                  <label className="col-lg-4 fw-bold text-muted">
                    {t("core:pages.account.AccountShow.FullName")}
                  </label>
                  <div className="col-lg-8">
                    <span className="fw-bolder fs-6 text-gray-800">
                      {user
                        ? user.firstName + " "
                        : t("core:pages.account.AccountShow.unknow")}
                      {user ? user.lastName : ""}
                    </span>
                  </div>
                </div>
                <div className="row mb-7">
                  <label className="col-lg-4 fw-bold text-muted">
                    {t("core:pages.account.AccountShow.Email")}
                  </label>
                  <div className="col-lg-8 d-flex align-items-center">
                    <span className="fw-bolder fs-6 text-gray-800 me-2">
                      {infos
                        ? infos.email + " "
                        : t("core:pages.account.AccountShow.unknow")}
                    </span>
                  </div>
                </div>
                <div className="row mb-7">
                  <label className="col-lg-4 fw-bold text-muted">
                    {t("core:pages.account.AccountShow.Phone")}
                  </label>
                  <div className="col-lg-8 d-flex align-items-center">
                    <span className="fw-bolder fs-6 text-gray-800 me-2">
                      {infos
                        ? infos.phone + " "
                        : t("core:pages.account.AccountShow.unknow")}
                    </span>
                  </div>
                </div>

                <div className="row mb-7">
                  <label className="col-lg-4 fw-bold text-muted">
                    {t("core:pages.account.AccountShow.City")}
                  </label>
                  <div className="col-lg-8 d-flex align-items-center">
                    <span className="fw-bolder fs-6 text-gray-800 me-2">
                      {infos
                        ? infos.city + " "
                        : t("core:pages.account.AccountShow.unknow")}{" "}
                      {infos
                        ? infos.zipCode
                        : t("core:pages.account.AccountShow.unknow")}
                    </span>
                  </div>
                </div>

                <div className="row mb-7">
                  <label className="col-lg-4 fw-bold text-muted">
                    {t("core:pages.account.AccountShow.Address")}
                  </label>
                  <div className="col-lg-8">
                    <span className="fw-bolder fs-6 text-gray-800">
                      {infos
                        ? infos.address
                        : t("core:pages.account.AccountShow.unknow")}
                    </span>
                  </div>
                </div>

                <div className="row mb-7">
                  <label className="col-lg-4 fw-bold text-muted">
                    {t("core:pages.account.AccountShow.Country")}
                  </label>
                  <div className="col-lg-8">
                    <span className="fw-bolder fs-6 text-gray-800">
                      {infos.country
                        ? t(
                            "core:global.languages.iso.countries." +
                              infos.country.toUpperCase()
                          )
                        : t("core:pages.account.AccountShow.unknow")}
                    </span>
                  </div>
                </div>

                <div className="row mb-7">
                  <label className="col-lg-4 fw-bold text-muted">
                    {t("core:pages.account.AccountShow.Language")}
                  </label>
                  <div className="col-lg-8">
                    <span className="fw-bolder fs-6 text-gray-800">
                      {user
                        ? t(
                            "core:global.languages.iso.language." +
                              user.language.toUpperCase()
                          )
                        : t("core:pages.account.AccountShow.unknow")}
                    </span>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="text-center mt-20 mb-20">
                  <LoadingResults />
                </div>
              </>
            )}
          </div>
        </div>

        <div className="card">
          <div
            className="card-header border-0 cursor-pointer"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_account_deactivate"
            aria-expanded="true"
            aria-controls="kt_account_deactivate"
          >
            <div className="card-title m-0">
              <h3 className="fw-bolder m-0">
                {t("core:pages.account.AccountEdit.deleteAccount.title")}
              </h3>
            </div>
          </div>
          <div id="kt_account_settings_deactivate" className="collapse show">
            <form id="kt_account_deactivate_form" className="form">
              <div className="card-body border-top p-9">
                <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-9 p-6">
                  <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <rect
                        opacity="0.3"
                        x="2"
                        y="2"
                        width="20"
                        height="20"
                        rx="10"
                        fill="black"
                      />
                      <rect
                        x="11"
                        y="14"
                        width="7"
                        height="2"
                        rx="1"
                        transform="rotate(-90 11 14)"
                        fill="black"
                      />
                      <rect
                        x="11"
                        y="17"
                        width="2"
                        height="2"
                        rx="1"
                        transform="rotate(-90 11 17)"
                        fill="black"
                      />
                    </svg>
                  </span>
                  <div className="d-flex flex-stack flex-grow-1">
                    <div className="fw-bold">
                      <h4 className="text-gray-900 fw-bolder">
                        {t(
                          "core:pages.account.AccountEdit.deleteAccount.warning"
                        )}
                      </h4>
                      <div className="fs-6 text-gray-700">
                        {t("core:pages.account.AccountEdit.deleteAccount.text")}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-check form-check-solid fv-row">
                  <input
                    name="deactivate"
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="deactivate"
                    onChange={(e) => setConfirmChecked(e.target.checked)}
                    checked={confirmChecked}
                  />
                  <label
                    className="form-check-label fw-bold ps-2 fs-6"
                    htmlFor="deactivate"
                  >
                    {t(
                      "core:pages.account.AccountEdit.deleteAccount.confirmCheckbox"
                    )}
                  </label>
                </div>
              </div>

              <div className="card-footer d-flex justify-content-end py-6 px-9">
                <button
                  type="button"
                  className="btn btn-primary px-6"
                  disabled={!confirmChecked}
                  onClick={handleClick}
                >
                  {" "}
                  {t(
                    "core:pages.account.AccountEdit.deleteAccount.button"
                  )}{" "}
                </button>
              </div>
            </form>
          </div>
        </div>
      </AccountLayout>
    </CoreLayout>
  );
};
