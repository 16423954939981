import { AxiosServicesByName } from "./AxiosServices";
import { LinkType } from "../types/LinkType";
import { ImageType } from "../types/ImageType";
import { SuggestType } from "../types/SuggestType";
import { PseudoType } from "../types/PseudoType";
import { DocType } from "../types/DocType";




//****************************************************
// Links
//****************************************************
/**
 * getLinks
 * - pour recupere les liens
 * @param {*} payload
 * @return {*}
 */
export const scrappLinksRequest = async (diagUUID: any) => {
  return await AxiosServicesByName.get(`/link/links/${diagUUID}`);
};

export const getLinkByUuid = async (uuid: string) => {
  return await AxiosServicesByName.get(`/link/get-link/${uuid}`)
}

/**
 * getRefreshlinks
 * - pour recupere les liens
 * @param {*} payload
 * @return {*}
 */
export const getlinksRequest = async (diagUUID: any) => {
  try {
    //const diagId = Number(localStorage.getItem('diagId'))
    let links: LinkType[] = []
    return await AxiosServicesByName.get(`/link/all-links/${diagUUID}`)
      .then((response) => {
        links = response.data.data
        links.sort((a, b) => a.position - b.position)
        const dataUpdated = links.map(function (item) {
          item["price"] = 0; //push fake price for cart
          item["type"] = "link"; //push fake price for cart
          item["id"] = item["uuid"]; //push fake price for cart
          return item;
        });
        return dataUpdated

      })
      .catch((error) => {
        console.log(error);
        return links
      });;
  } catch (error) {
    console.log("error");
  }
};


//****************************************************
// Images
//****************************************************
/**
 * getImagesRequest
 * - pour recupere les images
 * @param {*} payload
 * @return {*}
 */
export const scrappImagesRequest = async (diagUUID: any) => {
  return await AxiosServicesByName.get(`/image/scrap-images/${diagUUID}`);
};



/**
 * getImages
 * - pour recupere les images
 * @param {*} payload
 * @return {*}
 */
export const getImagesRequest = async (diagUUID: any) => {
  try {
    let images: ImageType[] = []
    return await AxiosServicesByName.get(`/image/all-images/${diagUUID}`)
      .then((response) => {
        images = response.data.data
        images.sort((a, b) => a.position - b.position)
        const dataUpdated = images.map(function (item) {
          item["price"] = 0; //push fake price for cart
          item["type"] = "image"; //push fake price for cart
          item["id"] = item["uuid"]; //push fake price for cart
          return item;
        });
        return dataUpdated
      })
      .catch((error) => {
        console.log(error);
        return images
      });;
  } catch (error) {
    console.log("error");
  }
};

export const getImageByUuid = async (uuid: string) => {
  return await AxiosServicesByName.get(`/image/get-image/${uuid}`)
}

export const updateTonalityImages = async (payload: any) => {
  try {
    return await AxiosServicesByName.put(`/image/update-tonality`, payload)
  } catch (error) {
    console.log("error");
  }
  
}


//****************************************************
// Suggests
//****************************************************

/**
 * getSuggestsRequest
 * - pour recupere les SuggestsSuggests
 * @param {*} payload
 * @return {*}
 */
export const scrappSuggestsRequest = async (diagUUID: any) => {
  return await AxiosServicesByName.get(`/suggest/get-suggest/${diagUUID}`);
};


/**
 * getSuggests
 * - pour recupere les google suggest
 * @param {*} payload
 * @return {*}
 */
export const getSuggestsRequest = async (diagUUID: any) => {
  try {
    let suggests: SuggestType[] = []
    return await AxiosServicesByName.get(`/suggest/all-suggest/${diagUUID}`)
      .then((response) => {
        suggests = response.data.data
        const dataUpdated = suggests.map(function (item) {
          item["price"] = 0; //push fake price for cart
          item["type"] = "suggest"; //push fake price for cart
          item["id"] = item["uuid"]; //push fake price for cart
          return item;
        });
        return dataUpdated
      })
      .catch((error) => {
        console.log(error);
        return suggests
      });
  } catch (error) {
    console.log("error");
  }
};


export const getSuggestByUuid = async (uuid: string) => {
  return await AxiosServicesByName.get(`/suggest/suggest-by-uuid/${uuid}`);
}


export const updateTonalitySuggest = async (payload: any) => {
  try {
    return await AxiosServicesByName.put(`/suggest/update-tonality`, payload)
  } catch (error) {
    console.log("error");
  }
  
}


export const updateMeNotMe = async (payload: any) => {
  try {
    return await AxiosServicesByName.post(`/link/is-me`, payload)
  } catch (error) {
    console.log("error");
  }
  
}

export const updateTonality = async (payload: any) => {
  try {
    console.log("payload",payload);
    
    return await AxiosServicesByName.put(`/link/update-tonality`, payload)
  } catch (error) {
    console.log("error");
  }
  
}


export const createPseudoRequest = async (diagUUID: any, payload: any) => {
  try {
    let pseudos: PseudoType[] = []
    return await AxiosServicesByName.post(`/pseudo`, payload)
      .then((response) => {
        pseudos = response.data.data
        const dataUpdated = pseudos.map(function (item) {
          item["price"] = 0; //push fake price for cart
          item["type"] = "pseudo"; //push fake price for cart
          item["id"] = item["uuid"]; //push fake price for cart
          return item;
        });
        return dataUpdated
      })
      .catch((error) => {
        console.log(error);
        return null;
      });
  } catch (error) {
    console.log("error");
  }
};


/**
 * getDocumentsRequest
 * - pour recupere les images
 * @param {*} payload
 * @return {*}
 */
export const getDocumentsRequest = async (diagUUID: any) => {
  try {
    let documents: DocType[] = []
    return await AxiosServicesByName.get(`/documents`)
      .then((response) => {
        documents = response.data.data
        const dataUpdated = documents.map(function (item) {
          item["price"] = 0; //push fake price for cart
          item["type"] = "documents"; //push fake price for cart
          item["id"] = item["uuid"]; //push fake price for cart
          return item;
        });
        return dataUpdated
      })
      .catch((error) => {
        console.log(error);
        return null
      });;
  } catch (error) {
    console.log("error");
  }
};





// export const getSuggestsRequest = async (diagUUID: any) => {
//   try {
//     //const diagId = Number(localStorage.getItem('diagId'))
//     let suggests: SuggestType[] = []
//     return await AxiosServices.get(`/suggest/all-suggest/${diagUUID}`)
//       .then((response) => {
//         suggests = response.data
//         const suggestsUpdated = suggests.map(function (suggest) {
//           suggest["price"] = 0; //push fake price for cart
//           suggest["type"] = "suggest"; //push fake price for cart
//           return suggest;
//         });
//         return suggestsUpdated;
//       })
//       .catch((error) => {
//         console.log(error);
//         return null
//       });;
//   } catch (error) {
//     console.log("error");
//   }
// };

//////////url /////////////
export const createUrl = async (payload: any) => {
  try {
    return await AxiosServicesByName.post(`/url`, payload)
  } catch (error) {
    console.log("error");
  }
}

export const getUrlByUuid = async (uuid: any) => {
  try {
    return await AxiosServicesByName.get(`/url/${uuid}`)
  } catch (error) {
    console.log("error");
  }
}
