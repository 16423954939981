import { useTranslation } from "react-i18next";
import { updateTonality, updateTonalitySuggest } from "../api/LinksApi";
import SwitchyButton from "./SwitchyButton";
import { updateTonalityVideo } from "../api/YoutubeApi";
import { updateTonalityNews } from "../api/NewsApi";

/**
 * Cookie SentimentBadgeHelpers
 *
 * @return {*}
 */
const SentimentBadgeHelpers = (props: any) => {
  const { t } = useTranslation(); // Multi Language

  const onSubmitTonality = async (values: any) => {
    if (props.type === "link") {
      await updateTonality(values);
    }
    if (props.type === "suggest") {
      await updateTonalitySuggest(values);
    }
    if (props.type === "video") {
      await updateTonalityVideo(values);
    }
    if (props.type === "news") {
      await updateTonalityNews(values);
    }
  };

  return (
    <SwitchyButton
      defaultActive={props.tonality === "Negative"}
      activeText="Negatif"
      inactiveText="Neutre"
      activeClass="switchyButton-danger"
      inactiveClass="switchyButton-secondary"
      onChange={async (isActive: boolean) => {
        let values;
        if (props.type === "link") {
          values = {
            linkUuid: props.uuid,
            tonality: props.tonality === "Neutral" ? "Negative" : "Neutral",
          };
        }

        if (props.type === "suggest") {
          values = {
            suggestUuid: props.uuid,
            tonality: props.tonality === "Neutral" ? "Negative" : "Neutral",
          };
        }
        if (props.type === "video") {
          values = {
            videoUuid: props.uuid,
            tonality: props.tonality === "Neutral" ? "Negative" : "Neutral",
          };
        }
        if (props.type === "news") {
          values = {
            newsUuid: props.uuid,
            tonality: props.tonality === "Neutral" ? "Negative" : "Neutral",
          };
        }
        console.log(values)
        onSubmitTonality(values);
      }}
    />
  );
};

export default SentimentBadgeHelpers;
