import CoreLayout from "../../../layouts/core-layout";
import ResultsLayout from "./results-layout";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import ProgressBarColoredHelpers from "../../../helpers/ProgressBarColoredHelpers";
import {
  countTonality, getDiagnosisByUIIDRequest,
} from "../../../api/DiagnosisApi";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getImagesRequest } from "../../../api/LinksApi";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function OverviewResults() {
  const { t } = useTranslation(); // Multi Language
  const { diagUUID } = useParams();
  const [linkCount, setLinkCount] = useState<any>({});
  const [percentImage, setPercentImage] = useState<any>({});
  const [percentSuggest, setPercentSuggest] = useState<any>({});

  const [keyword, setKeyword] = useState<any[]>([])
  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function(event) {
      window.history.pushState(null, document.title, window.location.href);
    });
    const fetchData = async () => {
      const res = await getDiagnosisByUIIDRequest(diagUUID);
      const keyword = (res.data.data.keyword);
      const negativeKeyword = (res.data.data.negativeKeyword);
      setKeyword(keyword);
    };
    fetchData();
  }, [diagUUID]);



  useEffect(() => {

    countTonality(diagUUID)
      .then(function (res: any) {
        setLinkCount(res.data.data.links);
        const countImages = res.data.data.images;
        const countSuggest = res.data.data.suugests;



        setPercentImage(
          percentResult(
            Number(countImages.neutral),
            Number(countImages.negative)
          )
        );
        setPercentSuggest(
          percentResult(
            Number(countSuggest.neutral),
            Number(countSuggest.negative)
          )
        );
      })
      .catch(() => {
        console.log("error");
      });

  }, [diagUUID]);

  const { data, isLoading } = useQuery("images", async () =>
    getImagesRequest(diagUUID)
  );

  return (
    <CoreLayout template="main">
      <ResultsLayout
        description={t("core:pages.osculteo.results.section.overview.text")}

      >

        <div className="row">
          <div className="col-md-6">
            <BlockStatsDoughnut
              uuid={diagUUID}
              title={t(
                "core:pages.osculteo.results.section.overview.blocks.serp.title"
              )}
              negative={linkCount?.negative}
              neutral={linkCount?.neutral}

              to={"/result/" + diagUUID + "/links"}
              text={t(
                "core:pages.osculteo.results.section.overview.blocks.serp.text"
              )}
            />
          </div>
          <div className="col-md-6">
            <BlockStats
              uuid={diagUUID}
              title={t(
                "core:pages.osculteo.results.section.overview.blocks.suggest.title"
              )}
              progress={percentSuggest.neutralResult}
              to={"/result/" + diagUUID + "/suggest"}
              text={t(
                "core:pages.osculteo.results.section.overview.blocks.suggest.text"
              )}
            />
            <BlockStats
              uuid={diagUUID}
              title={t(
                "core:pages.osculteo.results.section.overview.blocks.image.title"
              )}
              progress={percentImage.neutralResult}
              to={"/result/" + diagUUID + "/images"}
              text={t(
                "core:pages.osculteo.results.section.overview.blocks.image.text"
              )}
              images={data}

            />
          </div>
        </div>
      </ResultsLayout>
    </CoreLayout>
  );
}

export const percentResult = (
  neutral: number,
  negative: number
) => {
  const total = neutral + negative;
  return {
    negativeResult: Number(((negative / total) * 100).toPrecision(1)),
    neutralResult: Number(((neutral / total) * 100).toPrecision(1)),
  };
};

// /**
//  * BlockStats
//  *
//  * @param {*} props
//  */
const BlockStatsDoughnut = (props: any) => {
  const data = {
    labels: ["Negative", "Neutral"],
    datasets: [
      {
        data: [props.negative, props.neutral],
        backgroundColor: [
          "rgb(241, 65, 108)",
          "rgb(228, 230, 239)",

        ],
        hoverOffset: 4,
      },
    ],
  };

  return (
    <div className="card mb-xxl-8">
      <div className="card-header cursor-pointer">
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">{props.title}</h3>
        </div>
      </div>
      <div className="card-body">
        {props.text}
        <div>
          <Doughnut data={data} />
        </div>

        <div className="text-center mt-10">
          <NavLink className="btn btn-primary" to={props.to}>
            Voir
          </NavLink>
        </div>
      </div>
    </div>
  );
};

// /**
//  * BlockStats
//  *
//  * @param {*} props
//  */
const BlockStats = (props: any) => {
  const { progress = 0 } = props;
  return (
    <div className="card mb-xxl-8">
      <div className="card-header cursor-pointer">
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">{props.title}</h3>
        </div>
      </div>
      <div className="card-body">
        {props.text}
        <div>
          <div className="d-flex align-items-center flex-column mt-3">
            <div className="d-flex justify-content-between w-100 mt-auto mb-2">
              <span className="fw-bold fs-6 text-gray-400">Score</span>
              <span className="fw-bolder fs-6">{progress}%</span>
            </div>
            <div className="h-5px mx-3 w-100 bg-light mb-3">
              <ProgressBarColoredHelpers progress={progress} />
            </div>
          </div>
        </div>
 <div style={{display: "flex", justifyContent:"space-around"}}>
{
  props.images &&  props.images.slice(0,4).map( (element: { src: string | undefined; }) => {
      return(<div> <img style={{width: 100, height: 100, display: "inline-block"
      }} src={element?.src}></img></div>)
    })
  }
 </div>
        <div className="text-center mt-10">
          <NavLink className="btn btn-primary" to={props.to}>
            Voir
          </NavLink>
        </div>
      </div>
    </div>
  );
};
