import React from "react";
import { getImagesRequest } from "../../../api/LinksApi";
import ResultsLayout from "./results-layout";
import CoreLayout from "../../../layouts/core-layout";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useCart } from "react-use-cart";
import LoadingResults from "./elements/LoadingResults";
import { useQuery } from "react-query";
import NoResultsBlock from "./elements/NoResultsBlock";
import ButtonRemove from "../components/ButtonRemove";
import ButtonAdd from "../components/ButtonAdd";



function curUrl(url: any, max = 60, mid = "[...]") {
  if (url.length <= max) {
    return url;
  }

  let c = Math.floor(max / 3);
  let start_end = url.length - c;
  return url.substring(0, c * 2) + mid + url.substring(start_end, start_end + c);
}

export default function ImagesResults() {
  const { diagUUID } = useParams();
  const { t } = useTranslation(); // Multi Language
  const { data, isLoading } = useQuery("images", async () =>
    getImagesRequest(diagUUID)
  );



  if (isLoading) {
    return (
      <CoreLayout template="main">
        <ResultsLayout
          description={t("core:pages.osculteo.results.section.suggest.text")}
        >
          <div className="text-center mt-20 mb-20">
            <LoadingResults />
          </div>
        </ResultsLayout>
      </CoreLayout>
    );
  }

  if (data?.length === 0) {
    return (
      <CoreLayout template="main">
        <ResultsLayout
          description={t("core:pages.osculteo.results.section.suggest.text")}
        >
          <NoResultsBlock />
        </ResultsLayout>
      </CoreLayout>
    );
  }

  return (
    <CoreLayout template="main">
      <ResultsLayout
        description={t("core:pages.osculteo.results.section.images.text")}
      >
        <div className="row">
          {data?.map((item: any) => (

            <ImageComponent key={item.id} item={item} />
          ))}
        </div>
      </ResultsLayout>
    </CoreLayout>
  );
}
const ImageComponent = (props: any) => {
  const { diagUUID } = useParams();
  const { inCart } = useCart();


  return (
    <>
      <div className="col-md-3 mb-10" key={props.item.id}>

        <div className={`card overlay overflow-hidden ${inCart(props.item.id) ? "imageCardinCart " : ""}`}>
          <div className="card-body p-0">
            <div className="overlay-wrapper">
              <div className={`${inCart(props.item.id) ? "imageCardinCartBackground" : ""}`}>
                <div className={`${inCart(props.item.id) ? "blurImage" : ""}`}>
                  <div
                    className={`imgResults ${inCart(props.item.id) ? "" : ""}`}
                    style={{
                      backgroundImage: `url(${props.item.src})`,
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="overlay-layer bg-dark bg-opacity-25">
              {inCart(props.item.id) ? (
                <ButtonRemove itemId={props.item.id} />
              ) : (
                <ButtonAdd item={props.item} diag={diagUUID} />
              )}
            </div>
          </div>
        </div>

      </div >
      {/* <ChangeSentimentModalImages
        showHelpModal={showHelpModal}
        handleCloseHelpModal={handleCloseHelpModal}
        uuid={props.item.uuid}
        tonality={props.item.tonality}
      /> */}
    </>
  );
};
