import React, { useState } from "react";
import { Badge, Stack } from "react-bootstrap";

function DarkWebResultCard(props: any) {
  const [check, setCheck] = useState(true);
  return (
    <>
      <div className="w-lg-1500px bg-body rounded shadow-sm p-10 p-lg-5 mx-auto mt-10 mb-10">
        <div className="row mt-5">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div
                    className="col-md-4 d-flex align-items-center justify-content-center"
                    style={{ height: "100%" }}
                  >
                    <img
                      src={props.result?.logoPath}
                      alt=""
                      style={{ maxWidth: "80%" }}
                    />
                  </div>

                  <div className="col-md-8">
                    <Stack direction="horizontal" gap={2}>
                      <h3>{props.result.name}</h3>
                      {props.result.isVerified && (
                        <button className=" btn btn-sm p-2 m-2 dark-badge-verified">
                          vérifié
                        </button>
                      )}
                      {props.result.isSpamList && (
                        <button className="btn btn-sm p-2 m-2 dark-badge-spamlist">
                          Spam
                        </button>
                      )}
                      {props.result.isSensitive && (
                        <button className="btn btn-sm p-2 m-2 dark-badge-sensible">
                          Sensible
                        </button>
                      )}
                      {props.result.isFabricated && (
                        <button className="btn btn-sm p-2 m-2 dark-badge-theorie">
                          Theorie
                        </button>
                      )}
                    </Stack>

                    <div>
                      {check ? (
                        <>
                          <span>
                            {JSON.parse(props.result.description).fr}{" "}
                          </span>
                          <span>
                            {" "}
                            <button
                              type="button"
                              className="btn btn-link"
                              onClick={() =>
                                setCheck((prevCheck) => !prevCheck)
                              }
                            >
                              (Voir texte original)
                            </button>
                          </span>
                        </>
                      ) : (
                        <>
                          <span>
                            {JSON.parse(props.result.description).en}{" "}
                          </span>
                          <span>
                            {" "}
                            <button
                              type="button"
                              className="btn btn-link"
                              onClick={() =>
                                setCheck((prevCheck) => !prevCheck)
                              }
                            >
                              (Voir traduction en français)
                            </button>
                          </span>
                        </>
                      )}
                    </div>

                    <div className="mt-5">
                      <span className="font-weight-bold">
                        <strong>Nom de domaine:</strong>
                      </span>{" "}
                      <span>{props.result.domain}</span>
                    </div>
                    <div>
                      <span className="font-weight-bold">
                        <strong>Date de la faille:</strong>
                      </span>{" "}
                      <span>{props.result.breachDate}</span>
                    </div>
                    <div>
                      <span className="font-weight-bold">
                        <strong>Taille du fichier:</strong>
                      </span>{" "}
                      <span>{props.result.pwnCount} comptes</span>
                    </div>
                    <div className="mt-5">
                      <span className="font-weight-bold">
                        <strong>Type de data:</strong>
                      </span>{" "}
                      <span>
                        {props.result.dataClasses.map((classe: any) => (
                          <div className="ml-10">{classe.fr}</div>
                        ))}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DarkWebResultCard;
