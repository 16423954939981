import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import CoreLayout from "../../../layouts/core-layout";
import LoadingResults from "./elements/LoadingResults";
import { getPseudoByuuidRequest } from "../../../api/PseudoApi";
import { Button, Stack } from "react-bootstrap";
import { ArrowLeft } from "react-bootstrap-icons";

export default function SinglePseudoResults() {
  const { pseudoUuid } = useParams();
  const [data, setData] = React.useState<any>({});

  React.useEffect(() => {
    getPseudoByuuidRequest(pseudoUuid)
      .then(function (res: any) {
        setData(res.data.data);
      })
      .catch(() => {
        console.log("error");
      });
  }, [pseudoUuid]);

  return (
    <>
      <CoreLayout template="main">
        {data ? (
          <PseudoCard pseudo={data} />
        ) : (
          <>
            <div className="text-center mt-20 mb-20">
              <LoadingResults />
            </div>
          </>
        )}
      </CoreLayout>
    </>
  );
}

const PseudoCard = (props: any) => {
  const navigate = useNavigate();
  console.log(props.pseudo.urls);

  return (
    <>
      <div className="mt-10">
        <Button variant="light" onClick={() => navigate(-1)}>
          {" "}
          <ArrowLeft /> retour à la liste des pseudonymes
        </Button>
      </div>
      <div className="mb-5 mt-10">
        <div className="fs-5">
          <div className=" m-5">
            <div className="mt-10 ">
              {" "}
              <p className="fs-3">
                Resultats pour le pseudonyme{" "}
                <span className="badge badge-success">
                  {props.pseudo?.pseudonyme}
                </span>
              </p>
            </div>
            {props.pseudo.urls?.map((url: any) => {
              if (url?.exist) {
                return <DetailsComponent details={url} />;
              }
              return null; // Ne rend rien si url.exist n'est pas vrai
            })}
          </div>
        </div>
      </div>
    </>
  );
};

const DetailsComponent = (props: any) => {
  const details = props.details;
  return (
    <div className="w-lg-1500px bg-body rounded shadow-sm p-10 p-lg-5 mx-auto mt-10 mb-10">
      <div className="row mt-5">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div
                  className="col-md-2 d-flex align-items-center justify-content-center"
                  style={{ height: "100%" }}
                >
                  <img
                    src={`https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${details.url_account}/&size=128`}
                    alt=""
                    style={{ maxWidth: "80%" }}
                  />
                </div>

                <div className="col-md-8">
                  <Stack direction="horizontal" gap={2}>
                    <h3>{details.name}</h3>
                    {/* {props.result.isVerified && (
                      <button className=" btn btn-sm p-2 m-2 dark-badge-verified">
                        vérifié
                      </button>
                    )} */}
                  </Stack>

                  <div>
                    {
                      <>
                        <span>{details.description} </span>
                      </>
                    }
                  </div>
                  <div className="mt-5">
                    <span className="font-weight-bold">
                      <strong>Type de data:</strong>
                    </span>{" "}
                    <span>
                      {details.types.map((type: any) => (
                      
                          <span className=" badge badge-pseudo m-2">
                            {type}
                          </span>
                      
                      ))}
                    </span>
                  </div>
                  <div className="mt-5">
                    <span className="font-weight-bold">
                      <strong>Thematics:</strong>
                    </span>{" "}
                    <span>
                      {details.thematics.map((theme: any) => (
                       <span className=" badge badge-pseudo m-2">
                       {theme}
                     </span>
                      ))}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
