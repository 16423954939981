/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from "react";

import ResultsLayout from './../results-layout';
import CoreLayout from "../../../../layouts/core-layout";

//import LoadingResults from './../elements/LoadingResults';
import { getSocialInstagramRequest } from "../../../../api/DiagnosisApi";

export default function SocialInstagramResults() {

  const [data, setData] = useState()

  useEffect(() => {
    getSocialInstagramRequest().then(function (res: any) {
      setData(res.data)
    }).catch(() => {
      console.log("error");
    });
  }, [])

  const followings_phone_numbers = [
    {
      "id": 5607011792,
      "username": "storytellersontour",
      "full_name": "Storytellers",
      "contact_phone_number": "0695196475"
    },
    {
      "id": 15692771,
      "username": "konbini",
      "full_name": "Konbini",
      "contact_phone_number": "0142469692"
    },
    {
      "id": 367315644,
      "username": "reesewitherspoon",
      "full_name": "Reese Witherspoon",
      "contact_phone_number": "+16152355390"
    },
    {
      "id": 31102403,
      "username": "lancaster",
      "full_name": "LANCASTER",
      "contact_phone_number": "0142288881"
    },
    {
      "id": 7029924029,
      "username": "cap3000_cotedazur",
      "full_name": "CAP3000 Côte d'Azur",
      "contact_phone_number": "0493311035"
    },
    {
      "id": 20240005,
      "username": "kerrywashington",
      "full_name": "Kerry Washington",
      "contact_phone_number": "+17184007118"
    },
    {
      "id": 238865597,
      "username": "gemmyo",
      "full_name": "Gemmyo",
      "contact_phone_number": "0142469089"
    },
    {
      "id": 1826678039,
      "username": "studiobysol",
      "full_name": "STUDIOBYSOL & VISM STUDIO",
      "contact_phone_number": "+821023807030"
    },
    {
      "id": 50693119103,
      "username": "saleyahairandnails",
      "full_name": "Hair&Nails",
      "contact_phone_number": "0650311548"
    },
    {
      "id": 1446986608,
      "username": "nocibe_france",
      "full_name": "Nocibé",
      "contact_phone_number": "0969393040"
    }
  ];
  const followings_email = [{ "id": 550072490, "username": "dior", "full_name": "Dior Official", "email": "contactinstagram@dior.com" }, { "id": 15692771, "username": "konbini", "full_name": "Konbini", "email": "community@konbini.com" }, { "id": 5984789844, "username": "kamilaofficiel", "full_name": "KAMILA", "email": "pro.kamila@atypic-talent.com" }, { "id": 1609396227, "username": "havenpeckover", "full_name": "Haven Peckover", "email": "daohaven@gmail.com" }, { "id": 227497518, "username": "seblafrite", "full_name": "seb", "email": "fritsebastien@gmail.com" }, { "id": 4059149934, "username": "sephoracollection", "full_name": "Sephora Collection", "email": "madeinsephorawas@gmail.com" }, { "id": 756250556, "username": "messikajewelry", "full_name": "Messika", "email": "contact@messikagroup.com" }, { "id": 452458761, "username": "romaneinnc", "full_name": "Romane", "email": "romaneincpro@gmail.com" }, { "id": 318221330, "username": "giambattistavalliparis", "full_name": "Giambattista Valli Official", "email": "contactinstagram@giambattistavalli.com" }, { "id": 632790221, "username": "melancoliemedit", "full_name": "Cynaelle \ud83c\udf3b\ud83c\udf3f", "email": "melancoliemedit@gmail.com" }];
  return (
    <>
      <CoreLayout template="main">
        <ResultsLayout description="Connectez-vous avec vos réseaux sociaux favoris afin de faire des analyses profondes .">
          <h1 className="fw-bolder m-0"><img alt="Logo" src="/images/instagram-2-1.svg" className="h-40px me-3" /> Instagram</h1>
          <div className="row mt-10">
            <div className="col-md-4">
              <div className="card mb-3">
                <div className="card-header">
                  <div className="card-title m-0">
                    <h3 className="fw-bolder m-0">Info Personnel</h3>
                  </div>
                </div>
                <div className="card-body">
                  <img  src="http://127.0.0.1:7800/307296466_788260795935827_998342341479483098_n.jpg" className="img-fluid" alt=""></img>

                  <table className="table gy">
                    <tbody>
                      <tr>
                        <td>Id Instagram</td>
                        <td>55530248218</td>
                      </tr>
                      <tr>
                        <td>Nom</td>
                        <td>Eric Martin</td>
                      </tr>
                      <tr>
                        <td>edge_followed_by</td>
                        <td>12</td>
                      </tr>
                      <tr>
                        <td>edge_follow</td>
                        <td>19</td>
                      </tr>
                      <tr>
                        <td>is_verified</td>
                        <td>false</td>
                      </tr>

                      <tr>
                        <td>Nbr photos</td>
                        <td>67</td>
                      </tr>

                      <tr>
                        <td>Nbr videos</td>
                        <td>2</td>
                      </tr>

                    </tbody>
                  </table>
                </div>
              </div>
            </div>









            <div className="col-md-8">




              <div className="card mb-3">
                <div className="card-header">
                  <div className="card-title m-0">
                    <h3 className="fw-bolder m-0">Localisation & date de vos photos</h3>
                  </div>
                </div>
                <div className="card-body">
                  <table className="table gy">
                    <tbody>
                      <tr>
                        <td>2022-01-09 18:10:04</td>
                        <td>Hôtel de Solas, Rue Fournarié, Centre Historique, Centre, Montpellier, Hérault, Occitanie, France métropolitaine, 34062, France</td>
                      </tr>
                      <tr>
                        <td>2022-01-09 18:10:04</td>
                        <td>Café Bar No 9, 9, Uudenmaankatu, Viiskulma, Punavuori, Eteläinen suurpiiri, Helsinki, Helsingin seutukunta, Uusimaa, Etelä-Suomen aluehallintovirasto, Manner-Suomi, 00120, Suomi / Finland</td>
                      </tr>
                      <tr>
                        <td>2021-10-30 20:11:27</td>
                        <td>Postitalo, Postikatu, Keskusta, Kluuvi, Eteläinen suurpiiri, Helsinki, Helsingin seutukunta, Uusimaa, Etelä-Suomen aluehallintovirasto, Manner-Suomi, 00099, Suomi / Finland</td>
                      </tr>
                      <tr>
                        <td>2021-05-23 16:59:57</td>
                        <td>Aire de jeux des moyens, Avenue Mas Felipe Delavouet, Grans, Istres, Bouches-du-Rhône, Provence-Alpes-Côte d'Azur, France métropolitaine, 13450, France</td>
                      </tr>
                      <tr>
                        <td>2021-04-25 16:19:12</td>
                        <td>Cemenelum, Avenue Monte-Croce, Cimiez, Nice, Alpes-Maritimes, Provence-Alpes-Côte d'Azur, France métropolitaine, 06108, France</td>
                      </tr>

                      <tr>
                        <td>2020-11-28 20:53:02</td>
                        <td>Le Cécil, Rue de Belgique, Quartier Jean-Médecin, Nice, Alpes-Maritimes, Provence-Alpes-Côte d'Azur, France métropolitaine, 06000, France</td>
                      </tr>


                      <tr>
                        <td>2020-08-09 15:51:45</td>
                        <td>Nieuwe Brug, Damrak, Centrum, Amsterdam, Noord-Holland, Nederland, 1012 LG, Nederland</td>
                      </tr>


                      <tr>
                        <td>2019-12-20 07:38:55</td>
                        <td>Palais de la Méditerranée, Rue du Congrès, Carré d'Or, Vernier, Nice, Alpes-Maritimes, Provence-Alpes-Côte d'Azur, France métropolitaine, 06046, France</td>
                      </tr>


                      <tr>
                        <td>2019-11-17 19:34:55</td>
                        <td>Bassin Lympia, Rue de Lazaret, Le Port, Carabacel, Nice, Alpes-Maritimes, Provence-Alpes-Côte d'Azur, France métropolitaine, 06300, France</td>
                      </tr>

                      <tr>
                        <td>2019-08-08 14:10:48</td>
                        <td>MICHELANGELO, Passeig de Jaume I, Salou, Tarragonès, Tarragona, Catalunya, 43840, España</td>
                      </tr>

                      <tr>
                        <td>2019-04-19 07:46:38</td>
                        <td>Buckingham Palace, Buckingham Gate, Victoria, City of Westminster, London, Greater London, England, SW1A 1AA, United Kingdom</td>
                      </tr>

                      <tr>
                        <td>2019-03-14 08:34:47</td>
                        <td>Le Grand Louvre, Place du Musée du Louvre, Quartier Saint-Germain-l'Auxerrois, Paris 1er Arrondissement, Paris, Île-de-France, France métropolitaine, 75001, France</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>




              <div className="card mb-3 mt-10">
                <div className="card-header">
                  <div className="card-title m-0">
                    <h3 className="fw-bolder m-0">Téléphone de vos followers</h3>
                  </div>
                </div>
                <div className="card-body">
                  <table className="table gy">
                    <tbody>
                      {followings_phone_numbers?.map((item: any) => (
                        <tr>
                          <td><b>{item.full_name}</b> (@{item.username})</td>
                          <td>{item.contact_phone_number}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>


              <div className="card mb-3 mt-10">
                <div className="card-header">
                  <div className="card-title m-0">
                    <h3 className="fw-bolder m-0">Mail de vos followers</h3>
                  </div>
                </div>
                <div className="card-body">
                  <table className="table gy">
                    <tbody>
                      {followings_email?.map((item: any) => (
                        <tr>
                          <td><b>{item.full_name}</b> (@{item.username})</td>
                          <td>{item.email}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>




            </div>
          </div>
        </ResultsLayout>
      </CoreLayout>

    </>
  );
}

