import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CoreLayout from "../../../layouts/core-layout";
import LoadingResults from "./elements/LoadingResults";
import { getPrivateDataByuuidRequest } from "../../../api/PrivateDataApi";
import { Button } from "react-bootstrap";
import ResultsLayout from "./results-layout";
import { ArrowLeft } from "react-bootstrap-icons";

export default function SinglePrivateDataResults() {
  const { privateDataUuid, diagUUID } = useParams();
  const [data, setData] = React.useState<any>({});

  React.useEffect(() => {
    getPrivateDataByuuidRequest(privateDataUuid)
      .then(function (res: any) {
        setData(res.data.data);
      })
      .catch(() => {
        console.log("error");
      });
  }, [privateDataUuid]);

  return (
    <>
      <CoreLayout template="main">
        <ResultsLayout description="Trouver les sites où vous avez potentiellement des comptes utilisateur">
          {data ? (
            <PrivateDataCard privateData={data} />
          ) : (
            <>
              <div className="text-center mt-20 mb-20">
                <LoadingResults />
              </div>
            </>
          )}
        </ResultsLayout>
      </CoreLayout>
    </>
  );
}

const PrivateDataCard = (props: any) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="mt-10">
        <Button variant="light" onClick={() => navigate(-1)}>
          {" "}
          <ArrowLeft /> retour à la liste des données privées
        </Button>
      </div>
      <div className="card card-stretch card-bordered mb-5 mt-10">
        <div className="card-body fs-5">
          <h5 className="card-title d-flex align-items-center fs-2 fw-bold flex-wrap">
            Détaille de la donnéé privée
          </h5>
          <div className="row">
            <div className="col">
            Ce microservice sophistiqué balaye et identifie de manière proactive
            les URLs qui hébergent des informations privées spécifiées, assurant
            ainsi une surveillance efficace et une protection renforcée contre
            les fuites de données sensibles.Voici la liste des URLs qui contiennent la donnée privée <strong>{props.privateData.privateData}</strong>.
            </div>
          </div>
          <div className="col m-5">
        
              <h6>Urls ou votre donnée à été trouvée</h6>
            </div>
            {props.privateData.urls?.map((link: string) => {
              return (
                <div>
                  <a href={link}>{link}</a>
                </div>
              );
            })}
          </div>
        </div>
     
    </>
  );
};
