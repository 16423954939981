/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect, useState } from "react";
import ResultsLayout from "./results-layout";
import CoreLayout from "../../../layouts/core-layout";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  getAllPrivateDataRequest,
  getPrivateDataRequest,
} from "../../../api/PrivateDataApi";
import PrivateDataInterface from "../../../interfaces/PrivateDataInterface";
import PrivateDataSchema from "../../../validators/PrivateDataSchema";
import { t } from "i18next";
import { ArrowLeft } from "react-bootstrap-icons";

export default function PrivateDataResults() {
  const { t } = useTranslation(); // Multi Language
  const { diagUUID } = useParams();
  const navigate = useNavigate();

  const [showHelpModal, setShowHelpModal] = React.useState(false);
  const handleCloseHelpModal = () => setShowHelpModal(false);
  const handleShowHelpModal = () => setShowHelpModal(true);
  const [isCliqued, setIsCliqued] = useState(false);
  const [showLoadingAdvertisingModal, setShowLoadingAdvertisingModal] =
    useState(false);

  const [allPrivateData, setAllPrivateData] = useState<any[]>([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PrivateDataInterface>({
    //mode: "onBlur",
    resolver: yupResolver(PrivateDataSchema),
  });

  useEffect(() => {
    const fetchData = async () => {
      if (diagUUID) {
        const res = await getAllPrivateDataRequest(diagUUID);
        setAllPrivateData(res.data.data);
      }
    };
    fetchData();
  }, [diagUUID]);

  const onSubmit = async (data: any) => {
    setIsCliqued(true);
    setShowLoadingAdvertisingModal(true);
    const privateData = await getPrivateDataRequest(diagUUID, data);
    console.log("privateData", privateData)
    if (privateData.data.statusCode === 201)
      navigate(
        `/result/${privateData.data.data?.privateDataUuid}/${diagUUID}/single-private-data`,
      );
  };

  let dataType = [
    { label: "email", value: "email", isDefault: true },
    { label: "téléphone", value: "phone", isDefault: false },
    { label: "adresse", value: "address", isDefault: false },
    { label: "autre", value: "other", isDefault: false },
  ];

  return (
    <>
      <CoreLayout template="main">
        <ResultsLayout description="Trouver les sites où vous avez potentiellement des comptes utilisateur">
          <div className="row">
            <PrivateDataCard openModal={handleShowHelpModal} />

            {allPrivateData.length !== 0 && (
              <div className="mt-10 ">
                {" "}
                <p className="fs-3">Historique de vos données privées: </p>
              </div>
            )}
            <div className="col">
              {allPrivateData.map((privateData, index) => {
                return (
                  <ResultPrivateData key={index} privateData={privateData} diagUUID={diagUUID} />
                );
              })}
            </div>
          </div>
        </ResultsLayout>
      </CoreLayout>

      <Modal
        dialogClassName="modal-Help"
        show={showHelpModal}
        onHide={handleCloseHelpModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Recherchez une donnée privée</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p>
              Entrez une donée privée de type email, télephone, adresse ou autre
              afin de les rechercher sur le web. ATTENTION: il n'ai pas
              recommandé d'ajouter une donné sensible comme une numéro de
              securité sociale.
            </p>
          </div>
          <form
            className="form w-100"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="mb-5">
              <label className="fs-6 form-label fw-bold text-dark">
                Type de donnée privée:
              </label>
              {/* begin::Select */}
              <select
                {...register("privateDataType")}
                className="form-select form-select-solid form-select-lg fw-bold"
              >
                <option value="">sélectioner le type</option>
                {dataType.map((privateDataType) => (
                  <option value={privateDataType.value}>
                    {privateDataType.label}
                  </option>
                ))}
              </select>
              <ErrorMessage
                errors={errors}
                name="privateDataType"
                render={({ message }) => (
                  <p className="errorMsg">{t(message)}</p>
                )}
              />
            </div>

            <div className="fv-row mb-7">
              <label
                className="form-label fw-bolder text-dark fs-6"
                htmlFor="privateData"
              >
                Donnée privée
              </label>
              <input
                className="form-control form-control-lg form-control-solid"
                type="text"
                placeholder="donnée privée"
                required
                id="privateData"
                autoComplete="email"
                {...register("privateData")}
              />
              <ErrorMessage
                errors={errors}
                name="privateData"
                render={({ message }) => (
                  <p className="errorMsg">{t(message)}</p>
                )}
              />
            </div>

            <div className="text-center mt-10">
              <button
                type="submit"
                className="btn btn-lg btn-primary w-100 mb-5"
              >
                {t("guest:register.continue")}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

const PrivateDataCard = (props: any) => {
 
  return (
    <>
      <div>
        <div className="w-lg-1000px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto mt-5">
          <div className="text-center mb-10">
            <h1 className="text-dark mb-3">Recherche de donnée privée</h1>
          </div>
          <div className="mb-10">
            Ce microservice est conçu pour rechercher activement des données
            privées sur le web, afin de garantir leur protection et d'assurer
            que les informations personnelles ne sont pas exposées de manière
            non autorisée. Entrez votre donnée privée pour commencer la
            recherche
          </div>

          <div className="text-center">
            <button
              type="submit"
              className="btn btn-flex flex-center btn-primary btn-lg w-50 mb-10 mt-5"
              onClick={props.openModal}
            >
              Ajouter une donnée privée
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const ResultPrivateData = (props: any) => {
  const { diagUUID } = useParams();
  const navigate = useNavigate();
  console.log("private data", props.privateData)
  const getPrivateDataRequest = () => {
    navigate(
      `/result/${props.privateData?.privateDataUuid}/${diagUUID}/single-private-data`
    );
  };

  return (
    <>
   
      <div className="card card-stretch card-bordered m-5">
        <div className="card-body fs-5">
          <div className="row">
            <div className="col"> {props.privateData?.privateData}</div>
            <div className="col">{props.privateData?.privateDataType}</div>
            <div className="col">
              {props.privateData?.isPrivateDataPremium === true ? (
                <div>Donée privée Premium</div>
              ) : (
                <div>basique</div>
              )}
            </div>
            <div className="col">
              {" "}
              <button
                className="btn btn-primary btn-sm"
                onClick={getPrivateDataRequest}
              >
                {" "}
                voir{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
