import { AxiosServicesByName, AxiosServicesGuestUser } from "./AxiosServices";

/**
 * ping
 *
 * @return {*}
 */
export const pingRequest = async () => {
  return await AxiosServicesGuestUser.get("/ping");
};

/**
 * authRegister
 * - Pour inscrire une personne
 * @param {*} payload
 * @return {*}
 */
export const authRegisterRequest = async (payload: any) => {
  return await AxiosServicesGuestUser.post("/auth/register", payload);
};

/**
 * authValideEmail
 * - On valide l'email
 * @param {*} payload
 * @return {*}
 */
export const authValideEmailRequest = async (payload: any) => {
  return await AxiosServicesGuestUser.post(
    "/auth/user/validate-email",
    payload
  );
};

/**
 * authForgotPassword
 * - reset du password
 * @param {*} payload
 * @return {*}
 */
export const authForgotPasswordRequest = async (payload: any) => {
  return await AxiosServicesGuestUser.post("/auth/password-reset", payload);
};

/**
 * authResetPassword
 * - reset du password
 * @param {*} payload
 * @return {*}
 */
export const authResetPasswordRequest = async (payload: any) => {
  return await AxiosServicesGuestUser.post("/auth/new-password", payload);
};

/**
 * authLogin
 * - Pour logué une personne
 * @param {*} payload
 * @return {*}
 */
export const authLoginRequest = async (payload: any) => {
  return await AxiosServicesGuestUser.post("/auth/login", payload);
};

/**
 * resetUserPassword
 * - Pour reset le password d'un utilisateur
 * @param {*} payload
 * @return {*}
 */
export const resetUserPasswordRequest = async (payload: any) => {
  return await AxiosServicesGuestUser.post("/auth/new-password", payload);
};

/**
 *
 *
 * @return {*}
 */
export const updateUserLanguageRequest = async (payload: any) => {
  return await AxiosServicesByName.put("/auth/change-language/" + payload);
};

/**
 *
 *
 * @return {*}
 */
export const updateUserInfosRequest = async (payload: any) => {
  return await AxiosServicesByName.post("/auth/update-profile", payload);
};

/**
 *
 *
 * @return {*}
 */
export const getUserInfosRequest = async () => {
  return await AxiosServicesByName.get("/auth/user-profile");
};

/**
 * getUser
 *
 * @return {*}
 */
export const getUserRequest = async () => {
  let user = {
    id: 0,
    email: "",
    firstName: "",
    lastName: "",
    clientId: "",
  };
  try {
    const response = await AxiosServicesByName.get("/auth/user");
    return response.data;
  } catch (error) {
    console.log("error");
    return user;
  }
};

/**
 * getUser
 *
 * @return {*}
 */
export const editUserPasswordRequest = async (payload: any) => {
  const { status } = await AxiosServicesByName.post(
    "/auth/password-edit",
    payload
  );
  return status;
};

export const getUser = async () => {
  const user = await AxiosServicesByName.get("/auth/user");
  return user;
};

export const acceptCgu = async () => {
  const res = await AxiosServicesByName.post("/auth/accept-cgu", {
    isAccepted: true,
  });
  return res;
};

export const acceptCgv = async () => {
  const res = await AxiosServicesByName.post("/auth/accept-cgv", {
    isAccepted: true,
  });
  return res;
};

/**
 * documentsUploadRequest
 *
 * @return {*}
 */
export const alertRequest = async () => {
  const res = await AxiosServicesByName.get("/alert/alerts");
  return res;
};

/**
 * googleLoginRequest
 * - Pour loguer une personne via Google
 * @param {string} token
 * @return {*}
 */

export const googleLoginRequest = async (token: string) => {
  const response = await AxiosServicesGuestUser.post(
    "auth/google/verify",
    {},
    {
      headers: {
        "google-token-id": token,
      },
    }
  );
  console.log(response);
  return response.data;
};
