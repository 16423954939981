import React from "react";
import SentimentBadgeHelpers from "../../../../helpers/SentimentBadgeHelpers";

function GoogleNewsCard(props: any) {
  return (
    <div className="row mt-10">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            
            <div className="row">
            <div className="col-md-1" style={{ fontSize: "25px" }}>
                <div className="text-center">
                  <b>{props.news?.position}</b>
                </div>
              </div>
              <div className="col-md-2" onClick={() => window.location.replace(props.news?.url)}>
                <img
                  src={props.news?.image}
                  alt=""
                  className="rounded image-yt"
                />
              </div>
              <div className="col-md-2">
                <div className="text-center mt-5">
                  <div className="m-3">
                    <div className="miniTexte">Sentiment</div>
                    <SentimentBadgeHelpers
                      uuid={props.news?.uuid}
                      tonality={props.news?.tonality}
                      type={"news"}
                    />
                    <div className="miniTexte greymini">
                      Cliquez pour changer
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="row" onClick={() => window.location.replace(props.news?.url)}>
                  <h5 className="title-yt ">{props.news?.title}</h5>
                </div>
                <div style={{ color: "grey" }}>
                  <span>{props.news?.domain}</span> -{" "}
                  <span>{props.news?.timeAgo}</span>{" "}
                </div>
                <div className="row">
                  <p>{props.news?.description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GoogleNewsCard;
