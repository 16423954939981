
import * as Yup from "yup";
import { firstNameRules, lastNameRules, phoneRules, cityRules, addressRules, zipCodeRules, countryRules, languageRules } from "./rules/UserRules";
const accountEditSchema = Yup.object().shape({
  firstName: firstNameRules.required("validator:global.required"),
  lastName: lastNameRules.required("validator:global.required"),
  phone: phoneRules.required("validator:global.required"),
  city: cityRules.required("validator:global.required"),
  address: addressRules.required("validator:global.required"),
  zipCode: zipCodeRules.required("validator:global.required"),
  country: countryRules.required("validator:global.required"),
  language: languageRules.required("validator:global.required"),
});
export default accountEditSchema;