
import * as Yup from "yup";

/** PrivateData */
export const PrivateDataRules = Yup.string()
  .required("validator:rules.PrivateDataRules.PrivateData.oneOf")


/** PrivateDataType */
export const PrivateDataTypeRules = Yup.string()
.required("validator:rules.PrivateDataRules.PrivateDataType.oneOf")