/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect, useState } from "react";
import ResultsLayout from "./results-layout";
import CoreLayout from "../../../layouts/core-layout";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Modal, Table } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  getAllPseudoRequest,
  getPseudoRemainingRequest,
  getPseudoRequest,
} from "../../../api/PseudoApi";
import PseudoInterface from "../../../interfaces/PseudoInterface";
import PseudoSchema from "../../../validators/PseudoSchema";
import { Tooltip as ReactTooltip } from "react-tooltip";

export default function PseudoResults() {
  const { t } = useTranslation(); // Multi Language
  const { diagUUID } = useParams();
  const navigate = useNavigate();

  const [showHelpModal, setShowHelpModal] = React.useState(false);
  const handleCloseHelpModal = () => setShowHelpModal(false);
  const handleShowHelpModal = () => setShowHelpModal(true);
  const [isCliqued, setIsCliqued] = useState(false);
  const [showLoadingAdvertisingModal, setShowLoadingAdvertisingModal] =
    useState(false);

  const [allPseudo, setAllPseudo] = useState<any[]>([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PseudoInterface>({
    //mode: "onBlur",
    resolver: yupResolver(PseudoSchema),
  });

  useEffect(() => {
    const fetchData = async () => {
      if (diagUUID) {
        const res = await getAllPseudoRequest(diagUUID);

        setAllPseudo(res.data.data);
      }
    };
    fetchData();
  }, [diagUUID]);
  const [isLoading, setLoading] = useState(false);

  const onSubmit = async (data: any) => {
    setLoading(true);
    data.pseudoType = "PRO";
    setIsCliqued(true); // Activez l'état isCliqued
    setShowLoadingAdvertisingModal(true);
    const pseudo = await getPseudoRequest(diagUUID, data);

    if (pseudo.data.statusCode === 201)
      console.log(pseudo.data.data?.pseudoUuid);

    navigate(`/result/${pseudo.data.data?.pseudoUuid}/single-pseudo`);
  };

  return (
    <>
      <CoreLayout template="main">
        <ResultsLayout description="Trouver les sites où vous avez potentiellement des comptes utilisateur">
          <div className="row">
            <div className="col">
              <PseudoCard openModal={handleShowHelpModal} />
            </div>
            <div className="col">
              <ResultPseudo allPseudo={allPseudo} />
            </div>
          </div>
        </ResultsLayout>
      </CoreLayout>

      <Modal
        dialogClassName="modal-Help"
        show={showHelpModal}
        onHide={handleCloseHelpModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Recherchez ou se trouve votre pseudonyme</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p>Entrez votre pseudonyme</p>
          </div>
          <form
            className="form w-100"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="fv-row mb-7">
              <label
                className="form-label fw-bolder text-dark fs-6"
                htmlFor="pseudonyme"
              >
                Pseudonyme
              </label>
              <input
                className="form-control form-control-lg form-control-solid"
                type="text"
                placeholder="Pseudonyme"
                required
                id="pseudonyme"
                {...register("pseudonyme")}
              />

              <ErrorMessage
                errors={errors}
                name="pseudonyme"
                render={({ message }) => (
                  <p className="errorMsg">{t(message)}</p>
                )}
              />
            </div>

            <div className="text-center mt-10">
              <button
                type="submit"
                className="btn btn-lg btn-primary w-100 mb-5"
                disabled={isCliqued}
              >
                {t("guest:register.continue")}
                {isCliqued ? (
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                ) : null}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

const PseudoCard = (props: any) => {
  const { isCliqued } = props;
  const { diagUUID } = useParams();
  const [pseudoRemaining, setPseudoRemaining] = useState<number>();
  const { t } = useTranslation(); // Multi Language
  useEffect(() => {
    const fetchData = async () => {
      if (diagUUID) {
        const pseudoRemainingFetch = await getPseudoRemainingRequest(diagUUID);
        setPseudoRemaining(pseudoRemainingFetch.data.data?.pseudo);
      }
    };
    fetchData();
  }, [diagUUID]);

  return (

    <div>
      <div className=" bg-body rounded shadow-sm p-10 p-lg-15 mx-auto mt-5">
        <div className="text-center mb-10">
          <h1 className="text-dark mb-3">Recherche de Pseudonyme</h1>
        </div>
        <div className="mb-10">
          Ce microservice est conçu pour effectuer une recherche approfondie sur
          Internet, en utilisant un pseudonyme spécifié comme clé de requête,
          afin d'identifier et de cataloguer tous les sites web et plateformes
          en ligne où ce pseudonyme a été utilisé, fournissant ainsi un aperçu
          complet de l'empreinte numérique associée à cet identifiant unique.
        </div>

        <div className="text-center">
          <button
            type="submit"
            className="btn btn-flex flex-center btn-primary btn-lg w-50 mb-10 mt-5"
            onClick={props.openModal}
            disabled={pseudoRemaining === 0}
          >
            Ajouter un pseudonyme
          </button>
          {pseudoRemaining === 0
            ? " Vous avez épuisé votre cota de pseudo"
            : ""}
        </div>
      </div>
    </div>
  );
};

const ResultPseudo = (props: any) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="bg-body rounded shadow-sm p-10 p-lg-15 mx-auto mt-5">
        <div className="table-responsive m-5">
          <table className="table">
            <thead>
              <tr className="fw-bold fs-6 text-gray-800">
                <th>Pseudonyme</th>
                <th>Urls</th>
              </tr>
            </thead>
            <tbody>
              {props.allPseudo?.map((pseudo: any) => {
                return (
                  <tr>
                    <td> {pseudo.pseudonyme}</td>
                    <td>
                      <div className="btn btn-sm"
                        onClick={() =>
                          navigate(
                            `/result/${pseudo?.pseudoUuid}/single-pseudo`
                          )
                        }
                      >
                        {" "}
                        voir
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
