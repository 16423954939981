import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";

import { NavLink, useNavigate } from "react-router-dom";
import ButtonGoogle from "./SocialButton/ButtonGoogle";
import ButtonFacebook from "./SocialButton/ButtonFacebook";
import CoreLayout from "../../layouts/core-layout";
import RegisterSchema from "../../validators/RegisterSchema";
import RegisterInterface from "../../interfaces/RegisterInterface";
import { getScore } from "../../helpers/PasswordStrongHelpers";

import { authRegisterRequest, googleLoginRequest } from "../../api/AuthApi";

import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import { GoogleLogin } from "@react-oauth/google";
import axios from "axios";

export default function Register() {
  const { t } = useTranslation(); // Multi Language
  const navigate = useNavigate(); //Permet de changer de page après validation
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordHasValidLength, setPasswordHasValidLength] = useState(false);
  const [passwordHasValidLowercaseLetter, setPasswordHasValidLowercaseLetter] =
    useState(false);
  const [passwordHasValidUppercaseLetter, setPasswordHasValidUppercaseLetter] =
    useState(false);
  const [
    passwordHasValidSpecialCharacter,
    setPasswordHasValidSpecialCharacter,
  ] = useState(false);
  const [passwordHasValidNumber, setPasswordHasValidNumber] = useState(false);

  useEffect(() => {
    //const pageview = window.location.pathname + window.location.search;
    //ReactGA.send({ hitType: "pageview", page: pageview });

    // Send GA4 Event
    ReactGA.event({
      category: "Auth",
      action: "register",
      label: window.location.pathname + window.location.search,
    });
  }, []);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const {
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm<RegisterInterface>({
    //mode: "onBlur",
    resolver: yupResolver(RegisterSchema),
  });

  /**
   * Barre de socring du password
   *
   * @param {*} e
   */
  const handlePasswordStrong = (e: any) => {
    const items = document.querySelector("#password_meter")
      ? [].slice.call(
          document.querySelector("#password_meter")!.querySelectorAll("div")
        )
      : [];
    let index = 0;
    const score = getScore(e.target.value);

    const hasValidLength = e.target.value.length >= 8;
    setPasswordHasValidLength(hasValidLength);
    const hasValidNumber = /[0-9]/.test(e.target.value);
    setPasswordHasValidNumber(hasValidNumber);
    const hasValidSpecialCharacter =
      /[!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?]/.test(e.target.value);
    setPasswordHasValidSpecialCharacter(hasValidSpecialCharacter);
    const hasValidUppercaseLetter = /[A-Z]/.test(e.target.value);
    setPasswordHasValidUppercaseLetter(hasValidUppercaseLetter);
    const hasValidLowercaseLetter = /[a-z]/.test(e.target.value);
    setPasswordHasValidLowercaseLetter(hasValidLowercaseLetter);

    items.map((item: HTMLElement) => {
      index++;
      if (index <= score) {
        return item.classList.add("active");
      } else {
        return item.classList.remove("active");
      }
    });
  };

  /**
   * Envoie du formulaire
   *
   * @param {*} data
   */
  const onSubmit = async (data: any) => {
    data.password = data.password.trim();
    data.confirmPassword = data.confirmPassword.trim();

    authRegisterRequest(data)
      .then(function (response: any) {
        localStorage.clear();
        navigate("/register-confirm");
      })
      .catch(function (err: any) {
        if (err.code === "ERR_BAD_REQUEST") {
          // Ici on explique que l'email est deja pris
          if (
            err.response.data.message ===
            "There is already an account associated to this email"
          ) {
            setError("email", {
              type: "custom",
              message: t("guest:register.SignInHere"),
            });
          }
        }

        ReactGA.event({
          category: "Auth",
          action: "registred",
          label: window.location.pathname + window.location.search,
        });

        console.log(err.response.data);
      });
  };

  return (
    <>
      <CoreLayout template="auth">
        <div className="">
          <div className="platform-topBar w-lg-600px mx-auto"></div>
          <div className="w-lg-600px bg-body shadow-sm p-10 p-lg-15 mx-auto">
            <div className="text-center platform-logoBlock mb-10">
              <img
                src={`${process.env.REACT_APP_CDN_PLATFORMS}/logo.png`}
                className="platform-auth-logo"
                alt="Logo"
              />
            </div>
            <form
              className="form w-100"
              onSubmit={handleSubmit(onSubmit)}
              noValidate
            >
              <div className="mb-10 text-center">
                <h1 className="text-dark mb-3">{t("guest:register.title")}</h1>
                <div className="text-gray-400 fw-bold fs-4">
                  {t("guest:register.AlreadyHaveAnAccount")}
                  <NavLink to="/login" className="link-primary fw-bolder">
                    {t("guest:register.SignInHere")}
                  </NavLink>
                </div>
              </div>

              <SocialAuthRegisterBlock />

              {/* <ShowErrorsApi errors={errors} /> */}

              <NumsocRegisterBlock errors={errors} />

              <div className="row fv-row mb-7">
                <div className="col-xl-6">
                  <label
                    className="form-label fw-bolder text-dark fs-6"
                    htmlFor="firstName"
                  >
                    {t("guest:register.forms.input.firstName")}{" "}
                  </label>{" "}
                  *
                  <input
                    className="form-control form-control-lg form-control-solid"
                    type="text"
                    required
                    autoFocus
                    placeholder={t("guest:register.forms.input.firstName")}
                    autoComplete="given-name"
                    id="firstName"
                    {...register("firstName")}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="firstName"
                    render={({ message }) => (
                      <p className="errorMsg">{t(message)}</p>
                    )}
                  />
                </div>
                <div className="col-xl-6">
                  <label
                    className="form-label fw-bolder text-dark fs-6"
                    htmlFor="lastName"
                  >
                    {t("guest:register.forms.input.lastName")}
                  </label>{" "}
                  *
                  <input
                    className="form-control form-control-lg form-control-solid"
                    required
                    type="text"
                    placeholder={t("guest:register.forms.input.lastName")}
                    id="lastName"
                    autoComplete="family-name"
                    {...register("lastName")}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="lastName"
                    render={({ message }) => (
                      <p className="errorMsg">{t(message)}</p>
                    )}
                  />
                </div>
              </div>

              <div className="d-flex align-items-center mb-10">
                <div className="border-bottom border-gray-300 mw-50 w-100"></div>
                <span className="fw-bold text-gray-400 fs-7 mx-2 text-nowrap">
                  <div>{t("guest:register.InformationAuthentication")}</div>
                </span>
                <div className="border-bottom border-gray-300 mw-50 w-100"></div>
              </div>

              <div className="fv-row mb-7">
                <label
                  className="form-label fw-bolder text-dark fs-6"
                  htmlFor="email"
                >
                  {t("guest:register.forms.input.email")}
                </label>
                <input
                  className="form-control form-control-lg form-control-solid"
                  type="email"
                  placeholder={t("guest:register.forms.input.email")}
                  required
                  id="email"
                  autoComplete="email"
                  {...register("email")}
                />
                <ErrorMessage
                  errors={errors}
                  name="email"
                  render={({ message }) => (
                    <p className="errorMsg">{t(message)}</p>
                  )}
                />
              </div>
              <div className="mb-10 fv-row">
                <div className="mb-1">
                  <label
                    className="form-label fw-bolder text-dark fs-6"
                    htmlFor="password"
                  >
                    {t("guest:register.forms.input.password")}
                  </label>
                  <div className="position-relative mb-3">
                    <input
                      className="form-control form-control-lg form-control-solid"
                      required
                      type={passwordShown ? "text" : "password"}
                      placeholder={t("guest:register.forms.input.password")}
                      id="password"
                      autoComplete="off"
                      {...register("password")}
                      onChange={(e) => handlePasswordStrong(e)}
                    />

                    <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2">
                      <div onClick={togglePassword}>
                        {passwordShown ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-eye fs-2"
                            viewBox="0 0 16 16"
                          >
                            <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                            <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-eye-slash fs-2"
                            viewBox="0 0 16 16"
                          >
                            <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                            <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                            <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                          </svg>
                        )}
                      </div>
                    </span>
                  </div>
                  <div
                    className="d-flex align-items-center mb-3"
                    id="password_meter"
                  >
                    <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                    <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                    <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                    <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                  </div>
                  <ErrorMessage
                    errors={errors}
                    name="password"
                    render={({ message }) => (
                      <p className="errorMsg">{t(message)}</p>
                    )}
                  />
                </div>
                <ul>
                  <li
                    style={{
                      color: passwordHasValidLength ? "green" : "red",
                    }}
                  >
                    8 caractères minimum
                  </li>
                  <li
                    style={{
                      color: passwordHasValidLowercaseLetter ? "green" : "red",
                    }}
                  >
                    1 lettre minuscule
                  </li>
                  <li
                    style={{
                      color: passwordHasValidUppercaseLetter ? "green" : "red",
                    }}
                  >
                    1 lettre majuscule
                  </li>
                  <li
                    style={{
                      color: passwordHasValidSpecialCharacter ? "green" : "red",
                    }}
                  >
                    1 caractère spécial (!@#$%^&*()\\[\]{}\-_+=~`|:;"'<></>)
                  </li>
                  <li
                    style={{
                      color: passwordHasValidNumber ? "green" : "red",
                    }}
                  >
                    1 chiffree
                  </li>
                </ul>
                <div className="text-muted"></div>
              </div>
              <div className="fv-row mb-5">
                <label
                  className="form-label fw-bolder text-dark fs-6"
                  htmlFor="confirmPassword"
                >
                  {t("guest:register.forms.input.confirmPassword")}
                </label>

                <input
                  className="form-control form-control-lg form-control-solid"
                  type={passwordShown ? "text" : "password"}
                  placeholder={t("guest:register.forms.input.confirmPassword")}
                  required
                  id="confirmPassword"
                  autoComplete="off"
                  {...register("confirmPassword")}
                />
                <ErrorMessage
                  errors={errors}
                  name="confirmPassword"
                  render={({ message }) => (
                    <p className="errorMsg">{t(message)}</p>
                  )}
                />
              </div>

              <div className="fv-row mb-10 mt-10">
                <label className="form-check form-check-custom form-check-solid form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="true"
                    id="acceptCgu"
                    {...register("acceptCgu")}
                  />
                  <span className="form-check-label fw-bold text-gray-700 fs-6">
                    {t("guest:register.forms.input.acceptCgu.IAgree")}
                    <a href="/legals/cgu.html" target="blank">
                      {t(
                        "guest:register.forms.input.acceptCgu.TermsAndConditions"
                      )}
                    </a>
                    .
                  </span>
                </label>
                <ErrorMessage
                  errors={errors}
                  name="acceptCgu"
                  render={({ message }) => (
                    <p className="errorMsg">{t(message)}</p>
                  )}
                />
              </div>
              <div className="fv-row mb-10 mt-10">
                <label className="form-check form-check-custom form-check-solid form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="true"
                    id="acceptPrivacy"
                    {...register("acceptPrivacy")}
                  />
                  <span className="form-check-label fw-bold text-gray-700 fs-6">
                    {t("guest:register.forms.input.acceptPrivacy.IAgree")}
                    <a href="/legals/privacy.html" target="blank">
                      {t(
                        "guest:register.forms.input.acceptPrivacy.TermsAndConditions"
                      )}
                    </a>
                    .
                  </span>
                </label>
                <ErrorMessage
                  errors={errors}
                  name="acceptPrivacy"
                  render={({ message }) => (
                    <p className="errorMsg">{t(message)}</p>
                  )}
                />
              </div>
              <p> * {t("guest:register.forms.input.rgpdSetence")}</p>

              <div className="text-center">
                <button
                  type="submit"
                  className="btn btn-lg btn-primary w-100 mb-5"
                >
                  {t("guest:register.continue")}
                </button>
                <div className="mt-5">
                  <NavLink to="/login" className="text-primary">
                    {t("guest:register.BackToLogin")}
                  </NavLink>
                </div>
              </div>
            </form>
          </div>
        </div>
      </CoreLayout>
    </>
  );
}

function SocialAuthRegisterBlock() {
  const { t } = useTranslation(); // Multi Language

  const navigate = useNavigate();
  if (process.env.REACT_APP_AUTH_SOCIAL === "true") {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <GoogleLogin
          onSuccess={async (credentialResponse) => {
            const resp = await googleLoginRequest(
              credentialResponse.credential as string
            );
            console.log("resp", resp);

            const accessToken = resp.data.access_token;
            localStorage.setItem("accessToken", accessToken);
            navigate("/dashboard");
          }}
          onError={() => {
            console.log("Login Failed");
          }}
        />
      </div>
    );
  }
  return null;
}

function NumsocRegisterBlock(props: any) {
  const { t } = useTranslation(); // Multi Language
  if (process.env.REACT_APP_AUTH_NUMSOC === "true") {
    return (
      <div className="fv-row mb-7">
        <label
          className="form-label fw-bolder text-dark fs-6"
          htmlFor="num_soc"
        >
          {t("guest:register.forms.input.num_soc")}
        </label>
        <input
          className="form-control form-control-lg form-control-solid"
          type="text"
          placeholder={t("guest:register.forms.input.num_soc")}
          required
          id="num_soc"
          autoComplete="num_soc"
          name="num_soc"
        />
        <ErrorMessage
          errors={props.errors}
          name="num_soc"
          render={({ message }) => <p className="errorMsg">{t(message)}</p>}
        />
      </div>
    );
  } else {
    return <></>;
  }
}
