import { AxiosServicesByName } from "./AxiosServices";

export const getOffersByPlatform = async () => {
    return await AxiosServicesByName.get(`/platforms/offers?client_id=${process.env.REACT_APP_API_CLIENT_ID}`);
  };

export const getSubscription = async () => {
    return await AxiosServicesByName.get("/offers/subscriptions")
}

export const createCustomer = async () => {
  return await AxiosServicesByName.post("/payments/create-customer")
}

export const getStripeOrders = async (priceId: string) => {
  return await AxiosServicesByName.get(`/payments/orders?priceId=${priceId}`)
}

export const checkConditions = async (orderId: string) => {
  return await AxiosServicesByName.put(`/payments/orders/${orderId}/check-conditions`)
}

export const subscriptionTest = async (payload: any) => {
  return await AxiosServicesByName.post("/payments/subscribtion-test", payload)
}

export const getStripeOrderByname = async (name: string) => {
  return await AxiosServicesByName.get(`/payments/orders-by-name/${name}`)
}
  
export const getAllStripeOrders = async () => {
  return await AxiosServicesByName.get(`/payments/orders-by-user`)
}

