
import { useState } from 'react';
import ReactGA from "react-ga4";

// Multilanguage
import i18n from './i18n';

const DebugBar = () => {
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const changeLanguage = async (lng: any) => {
    await i18n.changeLanguage(lng);
    setCurrentLanguage(i18n.language);
    console.log(i18n.language);
    i18n.reloadResources();
  }

  // Ici trouver une astuce pour activé desactivé la bar

  if (process.env.REACT_APP_DEBUG === "false") {
    return (<></>);
  }

  const clickGACtest = () => {
    // console.log("send !!!");
    ReactGA.event({
      category: "Test",
      action: "debugbar_test_1",
      label: window.location.pathname + window.location.search
    });
    console.log("pageview debugbar_test_1:");

    // const pageview = window.location.pathname + window.location.search;
    // ReactGA.send({ hitType: "pageview", page: pageview, title: "debugBar8" });
    // console.log("pageview debugBar8:" + pageview);

  }



  return (<>
    <div className="fixed-bottom debugbar">
      <div className="row mt-2">
        <div className="col-md-4 text-center">
          Siwtch langage : {" "}

          <button onClick={() => changeLanguage('en')} className={`btn btn-sm ${currentLanguage === "en" ? "btn-success" : "btn-light"}`}>English
            <img className="w-15px h-15px rounded-1 ms-2" src="/images/flags/united-states.svg" alt="" /></button>{" "}
          <button onClick={() => changeLanguage('fr')} className={`btn btn-sm ${currentLanguage === "fr" ? "btn-success" : "btn-light"}`}>French
            <img className="w-15px h-15px rounded-1 ms-2" src="/images/flags/france.svg" alt="" /></button>


          <button onClick={(e) => clickGACtest()} className="btn btn-sm">Test google analytics</button>


        </div>
        <div className="col-md-4 text-center">
          <a href="http://localhost:1080" className="btn btn-primary btn-sm" target="blank">MailCatcher</a>
        </div>
        <div className="col-md-4 text-center">
          <b>DebugBar V1.2</b>
        </div>
      </div>
    </div>
  </>)
}

export default DebugBar;
