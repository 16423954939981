import { useState } from "react";

import ResultsLayout from "../../results-layout";
import CoreLayout from "../../../../../layouts/core-layout";
import { getAllVideos, getvideos } from "../../../../../api/YoutubeApi";
import { useParams } from "react-router-dom";
import YoutubeVideoComponent from "./YoutubeVideoComponent";
import { useMutation, useQuery, useQueryClient } from "react-query";

export default function SocialYoutubeResults() {
  const { diagUUID } = useParams();
  const [isFetching, setIsFetching] = useState(false);


  const { data } = useQuery("videos", () => getAllVideos(diagUUID));

  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation(getvideos);

  const scrapVideoYoutube = async () => {
    setIsFetching(true);
    try {
      await mutateAsync(diagUUID);
      queryClient.invalidateQueries("videos");
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setIsFetching(false);
    }
  };
  console.log(data?.data.data);

  return (
    <>
      <CoreLayout template="main">
        <ResultsLayout description="Connectez-vous avec vos réseaux sociaux favoris afin de faire des analyses profondes .">
          {data?.data.data.length === 0 && (
            <div>
              <div className="w-lg-1000px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto mt-10">
                <div className="text-center mb-10">
                  <h1 className="text-dark mb-3">Recherche de Video Youtube</h1>
                </div>
                <div className="mb-10">
                  Explorez notre service de pointe alimenté par l'intelligence
                  artificielle pour YouTube : recherchez des vidéos par
                  mots-clés, analysez leur tonalité émotionnelle, identifiez les
                  mots clés, surveillez les commentaires et prenez le contrôle
                  de votre réputation en ligne grâce à la puissance de l'IA.
                </div>

                <div className="text-center">
                  <div
                    className="btn btn-flex flex-center btn-light btn-lg w-50 mb-10 mt-10"
                    onClick={async () => await scrapVideoYoutube()}
                  >
                    {isFetching  && (
                      <div
                        className="spinner-border text-danger m-5"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                    <img
                      alt="Logo"
                      src="/images/youtube-3.svg"
                      className="h-20px me-3"
                    />{" "}
                    Rechercher les videos Youtubes
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="row">
            {data?.data.data.length !== 0 && (
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div>
                      Voici les résultats obtenus lors de votre recherche : des
                      vidéos YouTube pertinentes, une analyse détaillée de leur
                      tonalité émotionnelle, des mots clés mis en évidence, et
                      une surveillance des commentaires en temps réel pour vous
                      fournir une vue complète de votre réputation en ligne.
                    </div>
                    <div>
                      Vous pouvez cliquer sur les mots clés pour lire la video
                      au moment ou se mot à était trouvé, vous pouvez également
                      voir les commentaires de la vidéo et un résumé de la video
                      faite par notre IA.
                    </div>
                  </div>
                </div>
              </div>
            )}
            {data?.data.data?.map((video: any, index: number) => (
              <YoutubeVideoComponent
                diagUUID={diagUUID}
                video={video}
                index={index + 1}
              />
            ))}
          </div>
        </ResultsLayout>
      </CoreLayout>
    </>
  );
}


