import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import ProtectedRoute from "./ProtectedRoute";
import React, { useEffect } from "react";

//Cart Management
import { CartProvider } from "react-use-cart";

// Load Pages
import Home from "./pages/home";
import Faq from "./pages/faq";

// Results
import OverviewResults from "./pages/osculteo/results/OverviewResults";
import ImagesResults from "./pages/osculteo/results/ImagesResults";
import GoogleSuggestResults from "./pages/osculteo/results/GoogleSuggestResults";
import LinksResults from "./pages/osculteo/results/LinksResults";
import DocumentsResults from "./pages/osculteo/results/DocumentsResults";

import SocialResults from "./pages/osculteo/results/SocialResults";
import SocialInstagramResults from "./pages/osculteo/results/social/SocialInstagramResults";

// Diagnosis
import Diagnosis from "./pages/osculteo/Diagnosis";
import DiagnosisAnalysis from "./pages/osculteo/DiagnosisAnalysis";
import DiagnosisSelect from "./pages/osculteo/DiagnosisSelect";
import DirectURL from "./pages/osculteo/DirectURL";

// Auth Pages
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import Logout from "./pages/auth/Logout";
import ResetPassword from "./pages/auth/ResetPassword";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ForgotPasswordConfirm from "./pages/auth/ForgotPasswordConfirm";
import ValideEmail from "./pages/auth/ValideEmail";
import RegisterConfirm from "./pages/auth/RegisterConfirm";
import { GoogleOAuthProvider } from "@react-oauth/google";
// Account
import {
  AccountShow,
  AccountEdit,
  AccountPasswordEdit,
  AccountContact,
} from "./pages/account"; // Account pages

// Shopping Cart
import Dashboard from "./pages/osculteo/DashBoard";
import Cart from "./pages/shopping/Cart";
import CheckoutConfirmation from "./pages/shopping/CheckoutConfirmation";
import CheckoutInfo from "./pages/shopping/CheckoutInfo";
import CheckoutSuccess from "./pages/shopping/CheckoutSuccess";

// Mentions
import Cgu from "./pages/mentions/cgu";
import Legal from "./pages/mentions/legal";
import Privacy from "./pages/mentions/privacy";

//Contact
import Contact from "./pages/contact";

// Pages errors and maintenance
import { Error401, Error404, Error500, Maintenance } from "./pages/errors"; // Errors pages

import ReactGA from "react-ga4";
import { DiagnosisProvider } from "./contexts/DiagnosisContext";
import MeNotMe from "./pages/osculteo/MeNotMe";
import Offers from "./pages/offer/Offers";
import Success from "./pages/offer/Succes";
import Cancel from "./pages/offer/Cancel";
import {
  QuotationExpand,
  Quotations,
} from "./pages/account/account-quotations";
import { Bills } from "./pages/account/account-bills";
import PrivateDataResults from "./pages/osculteo/results/PrivateDataresult";
import SinglePrivateDataResults from "./pages/osculteo/results/SinglePrivateDataResult";
import SocialYoutubeResults from "./pages/osculteo/results/social/youtube/SocialYoutubeResults";
import TwinningFacebook from "./pages/osculteo/TwinnningFacebook";
import PseudoResults from "./pages/osculteo/results/PseudoResults";
import SinglePseudoResults from "./pages/osculteo/results/SinglePseudoResult";
import GoogleNewsSearchCard from "./pages/osculteo/results/news/GoogleNewsSearchCard";
import DarkWebSearchCard from "./pages/osculteo/results/darkweb/DarkWebSearchCard";

//const TRACKING_ID = process.env.REACT_APP_VERSION; // OUR_TRACKING_ID;

function App() {
  // Bout de code pour ping l'api voir si elle répond
  // const [timer, setTimer] = useState(0);
  useEffect(() => {
    //const pageview = window.location.pathname + window.location.search;
    //ReactGA.send({ hitType: "pageview", page: pageview });

    // Send GA4 Event
    ReactGA.event({
      category: "Auth",
      action: "login",
      label: window.location.pathname + window.location.search,
    });
  }, []);

  console.log("version : " + process.env.REACT_APP_VERSION);
  document.title = `${process.env.REACT_APP_SEO_TITLE}`;
  return (
    <>
      <AuthProvider>
        <GoogleOAuthProvider
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}
        >
          <CartProvider id="osculteo">
            <DiagnosisProvider>
              <BrowserRouter>
                <Routes>
                  {/* Auth */}
                  <Route path="/" element={<Home />} />

                  <Route
                    path="/register-confirm"
                    element={<RegisterConfirm />}
                  />
                  <Route path="/register" element={<Register />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/logout" element={<Logout />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route
                    path="/forgot-password-confirm"
                    element={<ForgotPasswordConfirm />}
                  />
                  <Route
                    path="validate-email/:userId/:validation_token"
                    element={<ValideEmail />}
                  />
                  <Route
                    path="password-reset/:userId/:passwordResetToken"
                    element={<ResetPassword />}
                  />
                  <Route path="/contact" element={<Contact />} />
                  {/* --------------- Protected Route ---------------*/}
                  {/* osculteo diagnosis */}
                  <Route
                    path="/dashboard"
                    element={<ProtectedRoute component={Dashboard} />}
                  />
                  <Route
                    path="/faq"
                    element={<ProtectedRoute component={Faq} />}
                  />

                  {/* diagnosis */}

                  <Route
                    path="/diagnosis"
                    element={<ProtectedRoute component={Diagnosis} />}
                  />
                  <Route
                    path="/diagnosis-analysis"
                    element={<ProtectedRoute component={DiagnosisAnalysis} />}
                  />
                  <Route
                    path="/diagnosis-select"
                    element={<ProtectedRoute component={DiagnosisSelect} />}
                  />
                  <Route
                    path="/result/:diagUUID/direct-url"
                    element={<ProtectedRoute component={DirectURL} />}
                  />
                  <Route
                    path="/result/:diagUUID/twinning-facebook"
                    element={<ProtectedRoute component={TwinningFacebook} />}
                  />

                  {/* diagnosis */}
                  <Route
                    path="/result/:diagUUID/overview"
                    element={<ProtectedRoute component={OverviewResults} />}
                  />
                  <Route
                    path="/result/:diagUUID/links"
                    element={<ProtectedRoute component={LinksResults} />}
                  />
                  <Route
                    path="/result/:diagUUID/images"
                    element={<ProtectedRoute component={ImagesResults} />}
                  />
                  <Route
                    path="/result/:diagUUID/suggest"
                    element={
                      <ProtectedRoute component={GoogleSuggestResults} />
                    }
                  />
                  <Route
                    path="/result/:diagUUID/pseudo"
                    element={<ProtectedRoute component={PseudoResults} />}
                  />
                  {/* <Route
                {/* diagnosis */}
                <Route 
                path="/result/:diagUUID/news"
                element={<ProtectedRoute component={GoogleNewsSearchCard} />}
                />
                <Route 
                path="/result/:diagUUID/darkweb"
                element={<ProtectedRoute component={DarkWebSearchCard} />}
                />
                {/* <Route
                  path="/result/:diagUUID/social"
                  element={<ProtectedRoute component={SocialResults} />}
                /> */}
                  <Route
                    path="/result/:diagUUID/documents"
                    element={<ProtectedRoute component={DocumentsResults} />}
                  />
                  <Route
                    path="/result/:diagUUID/menotme"
                    element={<ProtectedRoute component={MeNotMe} />}
                  />
                  <Route
                    path="/result/:diagUUID/social/instagram"
                    element={
                      <ProtectedRoute component={SocialInstagramResults} />
                    }
                  />
                  <Route
                    path="/result/:diagUUID/youtube"
                    element={
                      <ProtectedRoute component={SocialYoutubeResults} />
                    }
                  />

                  <Route
                    path="/result/:diagUUID/private-data"
                    element={<ProtectedRoute component={PrivateDataResults} />}
                  />
                  <Route
                    path="/result/:privateDataUuid/:diagUUID/single-private-data"
                    element={
                      <ProtectedRoute component={SinglePrivateDataResults} />
                    }
                  />

                  <Route
                    path="/result/:pseudoUuid/single-pseudo"
                    element={<ProtectedRoute component={SinglePseudoResults} />}
                  />

                  {/* account */}
                  <Route
                    path="/account"
                    element={<ProtectedRoute component={AccountShow} />}
                  />
                  <Route
                    path="/account/edit"
                    element={<ProtectedRoute component={AccountEdit} />}
                  />
                  <Route
                    path="/account/password/edit"
                    element={<ProtectedRoute component={AccountPasswordEdit} />}
                  />
                  <Route
                    path="/account/contact"
                    element={<ProtectedRoute component={AccountContact} />}
                  />
                  <Route
                    path="/account/quotations"
                    element={<ProtectedRoute component={Quotations} />}
                  />
                  <Route
                    path="/account/quotations/quotation/:quotationUuid"
                    element={<ProtectedRoute component={QuotationExpand} />}
                  />

                  <Route
                    path="/account/bills"
                    element={<ProtectedRoute component={Bills} />}
                  />

                  {/* Order and Cart */}
                  <Route
                    path="/cart"
                    element={<ProtectedRoute component={Cart} />}
                  />
                  <Route
                    path="/order/checkout-success"
                    element={<ProtectedRoute component={CheckoutSuccess} />}
                  />
                  <Route
                    path="/order/checkout-info"
                    element={<ProtectedRoute component={CheckoutInfo} />}
                  />
                  <Route
                    path="/order/checkout-confirmation"
                    element={
                      <ProtectedRoute component={CheckoutConfirmation} />
                    }
                  />
                  {process.env.REACT_APP_OFFER_VISIBLE === "true" && (
                    <Route
                      path="/offers"
                      element={<ProtectedRoute component={Offers} />}
                    />
                  )}
                  <Route
                    path="/success"
                    element={<ProtectedRoute component={Success} />}
                  />
                  <Route
                    path="/cancel"
                    element={<ProtectedRoute component={Cancel} />}
                  />

                  {/* mentions */}
                  <Route path="/mentions/legal" element={<Legal />} />
                  <Route path="/mentions/cgu" element={<Cgu />} />
                  <Route path="/mentions/privacy" element={<Privacy />} />

                  {/* errors */}
                  <Route path="/errors/401" element={<Error401 />} />
                  <Route path="/errors/404" element={<Error404 />} />
                  <Route path="/errors/404" element={<Error500 />} />
                  <Route path="/maintenance" element={<Maintenance />} />
                </Routes>
              </BrowserRouter>
            </DiagnosisProvider>
          </CartProvider>
        </GoogleOAuthProvider>
      </AuthProvider>
    </>
  );
}

export default App;
