import React, { useState } from "react";

import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

import { useCart } from "react-use-cart";
//Multi language
import { useTranslation } from "react-i18next";
import { alertRequest } from "../../api/AuthApi";
import { Button } from "react-bootstrap";
import { readAlert } from "../../api/AlertApi";

import { Robot } from "react-bootstrap-icons";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

/**
 * MainLayout
 * Template principale du site
 * @param {*} props
 */

const MainLayout = (props) => {
  document.body.classList.remove("auth-body-background-color"); // On eleve la couleur de fond de la platforme

  // React.useEffect(() => {
  //   setInterval(() => {
  //     pingRequest().then(function (res: any) {
  //       console.log("ping success");
  //     }).catch(() => {
  //       //console.log("error");
  //     })
  //   }, 3000);
  // }, []);

  // function handleSubmit(e) {
  //   e.preventDefault();
  //   console.log('You clicked submit.');
  // }

  const { t } = useTranslation(); // Multi Language
  const { totalUniqueItems } = useCart();

  return (
    <>
      <Navbar bg="dark" expand="lg" id="menu-bootstrap">
        <Container>
          <Navbar.Brand as={NavLink} to="/dashboard">
            <img
              alt="Logo"
              src={`${process.env.REACT_APP_CDN_PLATFORMS}/logo-white.png`}
              className="logo-main"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={NavLink} to="/dashboard/">
                {t("core:layout.menu.dashboard")}
              </Nav.Link>
              <Nav.Link as={NavLink} to="/account/">
                {t("core:layout.menu.myaccount")}
              </Nav.Link>
              {process.env.REACT_APP_OFFER_VISIBLE === "true" &&
              <Nav.Link as={NavLink} to="/offers/">
                {t("core:layout.menu.offers")}
              </Nav.Link>
}
              <Nav.Link as={NavLink} to="/cart">
                {t("core:layout.menu.cart")}{" "}
                <CountCartItems count={totalUniqueItems} />
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div className="background-body-main-layout">
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <div className="flex-column-fluid align-items-start container-xxl">
                {/* <AlertBar /> */}
                {props.children}
              </div>
              <FooterSmall />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

MainLayout.propTypes = {
  children: PropTypes.any,
};

/**
 * NavTopBarElement
 *
 */

const FooterSmall = () => {
  const { t } = useTranslation(); // Multi Language

  return (
    <>
      <div className="footer py-4 d-flex flex-lg-column">
        <div className="text-center">
          <div className="text-dark order-2 order-md-1">
            <span className="text-muted fw-bold me-1">2022©</span>
            <span className="text-gray-800 text-hover-primary">
              Iprotego v.{process.env.REACT_APP_VERSION}
            </span>
          </div>
          <div>
            <a href="/legals/cgu.html" target="blank" className="text-dark">
              {t("core:layout.footer.cgu")}
            </a>{" "}
            |{" "}
            <a href="/legals/cgv.html" target="blank" className="text-dark">
              {t("core:layout.footer.cgv")}
            </a>
            |{" "}
            <a href="/legals/privacy.html" target="blank" className="text-dark">
              {t("core:layout.footer.privacy")}
            </a>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
};

const CountCartItems = (props) => {
  const { t } = useTranslation(); // Multi Language
  if (props.count < 1) {
    return <> ({t("core:layout.menu.empty")})</>;
  }

  //supprimer les diag id etc et faire des useparams
  return (
    <>
      <span
        style={{ fontSize: "8px", marginLeft: "8px" }}
        className="badge badge-primary"
      >
        {props.count}
      </span>
    </>
  );
};

//***********************************************
// Gesiton des alertes
//***********************************************
/**
 * le visuel de l'alerte
 *
 * @param {*} props
 * @return {*}
 */
export const AlertPopup = (props) => {
  const [clickOk, setClickOk] = React.useState(false);
  return (
    <>
      {clickOk === false && (
        <div className="alert alert-danger d-flex align-items-center p-5 mt-5">
          <div className="row">
            <div className="col">
              <span className="svg-icon svg-icon-2hx svg-icon-danger me-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    opacity="0.3"
                    d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                    fill="black"
                  ></path>
                  <path
                    d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z"
                    fill="black"
                  ></path>
                </svg>
              </span>
            </div>
          </div>
          <div className="col">
            <h4 className="mb-1 text-danger">{props.title}</h4>
            <span>{props.text}</span>
          </div>
          <div className="col text-end">
            <Button
              className="btn btn-danger btn-sm end"
              onClick={() => {
                readAlert(props.id);
                setClickOk(true);
              }}
            >
              Marquer comme lu
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

/**
 * Recupere les alerte et les affiche
 *
 * @param {*} props
 * @return {*}
 */
const AlertBar = () => {
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    alertRequest()
      .then(function (res) {
        const unread = res.data.data.filter((el) => el.hasBeenRead === false);
        setData(unread);
      })
      .catch(() => {
        console.log("error");
      });
  }, []);

  return (
    <>
      {data?.map((item) => (
        <AlertPopup
          title={item.title}
          key={item.id}
          text={item.message}
          id={item.id}
        />
      ))}
    </>
  );
};

export default MainLayout;
