import { NavLink } from "react-router-dom";
import CoreLayout from '../../layouts/core-layout';

import { useTranslation } from 'react-i18next';
import ReactGA from "react-ga4";

export default function RegisterConfirm() {
  const { t } = useTranslation(); // Multi Language
  ReactGA.event({
    category: "Auth",
    action: "Validated",
    label: window.location.pathname + window.location.search
  });

  return (
    <>
      <CoreLayout template="auth">
        <div className="">
          <div className="platform-topBar w-lg-900px mx-auto"></div>
          <div className="w-lg-900px bg-body shadow-sm p-10 p-lg-20 mx-auto text-center">
            <div className='text-center platform-logoBlock mb-10' ><img src={`${process.env.REACT_APP_CDN_PLATFORMS}/logo.png`} className='platform-auth-logo' alt="logo" /></div>
           <div><h1>{t('guest:RegisterConfirm.Successfuly')}  👍</h1> </div> 
            {t('guest:RegisterConfirm.text')}
          </div>
        </div>
      </CoreLayout>
    </>
  );
}
