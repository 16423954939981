/* eslint-disable array-callback-return */
import CoreLayout from "../../layouts/core-layout";
import { NavLink, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useCart } from "react-use-cart";
import SentimentBadgeHelpers from "../../helpers/SentimentBadgeHelpers";
import ExtensionsHelpers from "../../helpers/ExtensionsHelpers";
import ButtonRemove from "../osculteo/components/ButtonRemove";
import { useEffect } from "react";
import ReactGA from "react-ga4";

const Cart = () => {
  const { isEmpty, items, emptyCart } = useCart();
  const { t } = useTranslation(); // Multi Language

  useEffect(() => {
    //const pageview = window.location.pathname + window.location.search;
    //ReactGA.send({ hitType: "pageview", page: pageview });

    // Send GA4 Event
    ReactGA.event({
      category: "Core",
      action: "show-cart",
      label: window.location.pathname + window.location.search
    });
  }, []);

  if (isEmpty) {
    return (
      <CoreLayout template="main">
        <div className="card mt-10">
          <div className="card-body">
            <div className="text-center mt-10 mb-10">
              <b style={{ fontSize: "32px" }}>{t("core:pages.shopping.cart.cartIsEmpty")}</b>
              <div className="text-center mt-10 mb-10"><NavLink to="/dashboard/" className="btn btn-secondary">{t("core:pages.shopping.cart.BackToDashboard")}</NavLink></div>

            </div>
          </div>
        </div>
      </CoreLayout>);
  }

  return (
    <>
      <CoreLayout template="main">
        <div className="row mt-10">
          <div className="col-md-12">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bolder text-dark" style={{ fontSize: "24px" }}>
                {t('core:pages.shopping.cart.title')} {!isEmpty && <button onClick={emptyCart} className="btn btn-warning btn-sm">{t("core:pages.shopping.cart.EmptyCart")}</button>}
              </span>
            </h3>
            <div>{t('core:pages.shopping.cart.text')}</div>
          </div>
        </div>
        {items.map((item) => {
          switch (item.type) {
            case "link":
              return (<ResultRowLink key={item.id} item={item} />)
            case "image":
              return (<ResultRowImages key={item.id} item={item} />)
            case "custom":
              return (<ResultRowCustom key={item.id} item={item} />)
            case "suggest":
              return (<ResultRowSuggest key={item.id} item={item} />)

            case "pseudo":
              return (<ResultRowPseudo key={item.id} item={item} />)

            case "documents":
              return (<ResultRowDocuments key={item.id} item={item} />)

          }
        })}
        <div className="mt-10">
          <div className="text-end">
            <Link to="/order/checkout-info" className="btn btn-success">{t("core:pages.shopping.cart.button.deleteRequest")}</Link>
          </div>
        </div>
      </CoreLayout>
    </>
  );
}

// /**
//  * ResultRowLink
//  *
//  * @param {*} props
//  */
const ResultRowLink = (props: any) => {
  const { t } = useTranslation(); // Multi Language
  const item = props.item; // Multi Language

  return (
    <>
      <div className="row mt-10">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-9">
                  <div>
                    <b>
                      <img
                        alt="img"
                        src={
                          "https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=" +
                          item.url +
                          "&size=16"
                        }
                      />
                      &nbsp;&nbsp;<span className="resultsRowTitle">{item.title}</span>
                    </b>
                  </div>
                  <div>{item.description}</div>
                  <div><b>{t("core:pages.shopping.cart.row.type")}</b> : {item.type}</div>
                  {/* <div><b>{t("core:pages.shopping.cart.row.keyword")}</b> : xxxxxxxxxxxxxxxx</div> */}
                  <div><b>{t("core:pages.shopping.cart.row.position")}</b> : {item.position}</div>
                  <div><b>{t("core:pages.shopping.cart.row.page")}</b> : {item.page}</div>
                  <div><b>{t("core:pages.shopping.cart.row.url")}</b> : {item.url}</div>
                  <div><b>mots clés recherché</b> : {item.keyword ? item.keyword : ""}</div>
                  <div><b>mots clés negatif</b> : {item.negativeKeyword ? item.negativeKeyword : ""}</div>
                </div>
                <div className="col-md-3"><div className="text-center">
                  <ButtonRemove itemId={item.id} />
                </div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};



// /**
//  * ResultRowImages
//  *
//  * @param {*} props
//  */
const ResultRowImages = (props: any) => {
  const { t } = useTranslation(); // Multi Language
  const item = props.item; // Multi Language
  return (
    <>
      <div className="row mt-10">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-9">

                  <div>
                    <b>
                      <img
                        alt="img"
                        src={
                          "https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=" +
                          item.url +
                          "&size=16"
                        }
                      />
                      &nbsp;&nbsp;<span className="resultsRowTitle">{item.title}</span>
                    </b>
                  </div>
                  <div><b>{t("core:pages.shopping.cart.row.type")}</b> : {item.type}</div>
                  {/* <div><b>{t("core:pages.shopping.cart.row.keyword")}</b> : xxxxxxxxxxxxxxxx</div> */}
                  <div><b>{t("core:pages.shopping.cart.row.url")}</b> : {item.href}</div>
                </div>
                <div className="col-md-3"><div className="text-center">
                  <ButtonRemove itemId={item.id} />
                </div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


// /**
//  * ResultRowSuggest
//  *
//  * @param {*} props
//  */
const ResultRowSuggest = (props: any) => {
  const { t } = useTranslation(); // Multi Language
  const item = props.item; // Multi Language
  return (
    <>
      <div className="row mt-10">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-9">
                  <div>
                    <b>
                      <span className="resultsRowTitle">{item.suggest}</span>
                    </b>
                  </div>
                  <div><b>{t("core:pages.shopping.cart.row.type")}</b> : {item.type}</div>
                </div>
                <div className="col-md-3"><div className="text-center">
                  <ButtonRemove itemId={item.id} />
                </div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


// /**
//  * ResultRowPseudo
//  *
//  * @param {*} props
//  */
const ResultRowPseudo = (props: any) => {
  const item = props.item;
  return (
    <>
      <div className="row mt-10">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-9">
                  <h3>                      <img
                    alt="img"
                    src={
                      "https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=" +
                      item.url +
                      "&size=16"
                    }
                  />
                    &nbsp;&nbsp;{item.domain} <SentimentBadgeHelpers tonality={item.tonality} /></h3>

                  <div>URL : {item.url}</div>
                  <div>Type : {item.type}</div>
                  <div>Tags : {item.tags}</div>
                </div>
                <div className="col-md-3"><div className="text-center">
                  <ButtonRemove itemId={item.id} />
                </div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};



// /**
//  * ResultRowCustom
//  *
//  * @param {*} props
//  */
const ResultRowCustom = (props: any) => {
  const { t } = useTranslation(); // Multi Language
  const item = props.item; // Multi Language
  return (
    <>
      <div className="row mt-10">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-9">
                  <div>
                    <b>
                      <img
                        alt="img"
                        src={
                          "https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=" +
                          item.url +
                          "&size=16"
                        }
                      />
                      &nbsp;&nbsp;<span className="resultsRowTitle">{t("core:pages.shopping.cart.order.custom-url.title")}</span>
                    </b>
                  </div>
                  <div>{t("core:pages.shopping.cart.order.custom-url.description")}</div>
                  <div><b>{t("core:pages.shopping.cart.row.type")}</b> : {item.type}</div>
                  <div><b>{t("core:pages.shopping.cart.row.url")}</b> : {item.url}</div>
                </div>
                <div className="col-md-3"><div className="text-center">
                  <ButtonRemove itemId={item.id} />
                </div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};





// /**
//  * ResultRowDocuments
//  *
//  * @param {*} props
//  */
const ResultRowDocuments = (props: any) => {
  const { t } = useTranslation(); // Multi Language
  const item = props.item; // Multi Language
  const { removeItem, } = useCart();
  return (
    <>
      <div className="row mt-10">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-9">
                  <div>
                    <h3><img
                      alt="img"
                      src={
                        "https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=" +
                        item.url +
                        "&size=16"
                      }
                    />
                      &nbsp;&nbsp;{item.domain} <SentimentBadgeHelpers tonality={item.tonality} /></h3>
                    <div>URL : <a href={item.url} target="blank">{item.url}</a></div>
                    <div>Type : {item.type}</div>
                    <div>Extension : <ExtensionsHelpers ext={item.ext} />{item.ext}</div>
                    <div className="text-center">

                    </div>
                  </div>
                </div>
                <div className="col-md-3"><div className="text-center">
                  <button onClick={() => removeItem(item.id)} className="btn btn-danger btn-sm">{t("core:pages.osculteo.results.button.removeToCart")}</button>
                </div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};



export default Cart;

