import { Link, useNavigate, useParams } from "react-router-dom";
import CoreLayout from "../../layouts/core-layout";
import { useTranslation } from 'react-i18next';
import Moment from "react-moment";
import AccountLayout from "./account-layout";


//Multi language

import {

  useEffect,

  useState,
} from "react";
import { getAllQuotations, getQuotationByUuid } from "../../api/OrderApi";
import { Button, ButtonGroup, ToggleButton } from "react-bootstrap";

export const Quotations = () => {
  const { t } = useTranslation(); // Multi Language
  const [quotations, setQuotations] = useState<any[]>([]);

  useEffect(() => {
    getAllQuotations()
      .then(function (res: any) {
        setQuotations(res.data.data);
      })
      .catch(() => {
        console.log("error");
      });
  }, []);

  return (
    <CoreLayout template="main">
      <AccountLayout>
        <div className="card mb-5 mb-xl-10 mt-10">
          <div
            className="card-header border-0 cursor-pointer"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_account_profile_details"
            aria-expanded="true"
            aria-controls="kt_account_profile_details"
          >
            <div className="card-title m-0">
              <h3 className="fw-bolder m-0">
                {t("core:pages.account.layout.menu.quotations")}
              </h3>
            </div>
          </div>
        </div>
        {quotations?.map((quotation) => (
          <QuotationResume
            quotation={quotation}
            uuid={quotation?.uuidQuotation}
          />
        ))}
      </AccountLayout>
    </CoreLayout>
  );
};

const QuotationResume = (props: any) => {
  const { t } = useTranslation(); // Multi Language
  return (
    <div className="card card-bordered mb-10">
      <div className="card-header ribbon ribbon-end ribbon-clip">
        <div className="ribbon-label">
        {t('core:pages.account.account-quotation.quotationResume.state')}
         
          <span className="ribbon-inner bg-success"></span>
        </div>
        <div className="card-title">
          <Link to={`quotation/${props.uuid}`}>
          {t('core:pages.account.account-quotation.quotationResume.quoteNumber')} {props.quotation?.uuidQuotation}
          </Link>
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col">{t('core:pages.account.account-quotation.quotationResume.createdOn')} <Moment format="DD/MM/YYYY">{props.quotation?.createdAt}</Moment> </div>
          <div className="col">
            {" "}
            {props.quotation?.userQuotationInfos?.linkToDelete?.length}{" "}{t('core:pages.account.account-quotation.quotationResume.link')}
          </div>
        </div>
      </div>
    </div>
  );
};

export const QuotationExpand = () => {
  const { quotationUuid } = useParams();
  const [links, setLinks] = useState<any[]>([]);
  const [images, setImages] = useState<any[]>([]);
  const [suggest, setSuggest] = useState<any[]>([]);
  const [urls, setUrls] = useState<any[]>([]);
  const [radioValue, setRadioValue] = useState("1");
  const { t } = useTranslation(); // Multi Language

  const radios = [
    { name: "Liens", value: "1" },
    { name: "Images", value: "2" },
    { name: "Suggests ", value: "3" },
    { name: "Urls personalisées", value: "4" },
  ];
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      const res = await getQuotationByUuid(quotationUuid);
      setLinks(res.data.data.userQuotationInfos?.linkToDelete);
      setImages(res.data.data.userQuotationInfos?.imageToDelete);
      setSuggest(res.data.data.userQuotationInfos?.suugestToDelete);
      setUrls(res.data.data.userQuotationInfos?.urlToDelete);
    };
    fetchData();
  }, [quotationUuid]);
  return (
    <CoreLayout template="main">
      <AccountLayout>
        <div className="mb-10"> <h3> {t('core:pages.account.account-quotation.quotationResume.title')}  </h3></div>
        <div className="card">
          <ButtonGroup>
            {radios.map((radio, idx) => (
              <ToggleButton
                key={idx}
                id={`radio-${idx}`}
                type="radio"
                variant={"outline-secondary"}
                name="radio"
                value={radio.value}
                checked={radioValue === radio.value}
                onChange={(e) => setRadioValue(e.currentTarget.value)}
              >
                {radio.name}
              </ToggleButton>
            ))}
          </ButtonGroup>
        </div>

        {radioValue === "1" && (
          <div className="row">
            {links?.map((l) => (
              <ResultRowLink item={l} />
            ))}
          </div>
        )}

        {radioValue === '2' &&<div className="row">
          {images?.map((l) => (
            <ResultRowImage item={l} />
          ))}
        </div>}
        {radioValue === "3" && (
          <div className="row">
            {" "}
            {suggest?.map((suggest) => (
              <ResultRowSuggest item={suggest} />
            ))}
          </div>
        )}
        {radioValue === "4" && (
          <div className="row">
            {" "}
            {urls?.map((url) => (
              <ResultRowUrl item={url} />
            ))}
          </div>
        )}

        <div className="btn m-5">
          <Button onClick={() => navigate("/account/quotations")}>
            {" "}
            {t('core:pages.account.account-quotation.navigate.showDetails')}
          </Button>
        </div>
      </AccountLayout>
    </CoreLayout>
  );
};

const ResultRowLink = (props: any) => {
  const { t } = useTranslation(); // Multi Language
  const item = props.item;
  return (
    <>
      <div className="row mt-10">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <div>
                    <b>
                      <img
                        alt="img"
                        src={
                          "https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=" +
                          item.url +
                          "&size=16"
                        }
                      />
                      &nbsp;&nbsp;
                      <span className="resultsRowTitle">{item.title}</span>
                    </b>
                  </div>
                  <div>{item.description}</div>
                  <div>
                    <b>{t('core:pages.account.account-quotation.navigate.position')}</b> : {item.position}
                  </div>
                  <div>
                    <b>{t('core:pages.account.account-quotation.navigate.page')}</b> : {item.page}
                  </div>
                  <div>
                    <b>{t('core:pages.account.account-quotation.navigate.url')}</b> : <a href={item.url}>{item.url}</a>
                  </div>
                  <div>
                    <b>{t('core:pages.account.account-quotation.navigate.researchKeyword')}</b> : {item.keyword ? item.keyword : ""}
                  </div>
                  <div>
                    <b>{t('core:pages.account.account-quotation.navigate.negativeKeyword')}</b> : {item.negativeKeyword ? item.negativeKeyword : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ResultRowImage = (props: any) => {
  const { t } = useTranslation(); // Multi Language
  const item = props.item;
  return (
    <>
      <div className="row mt-10">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <div>
                    <b>
                      <img
                        alt="img"
                        src={
                          "https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=" +
                          item.url +
                          "&size=16"
                        }
                      />
                      &nbsp;&nbsp;
                      <span className="resultsRowTitle">{item.title}</span>
                    </b>
                  </div>
                  <div>
                    <b>{t('core:pages.account.account-quotation.ResultRowImage.position')}</b> : {item.position}
                  </div>
                  <div>
                    <b>{t('core:pages.account.account-quotation.ResultRowImage.url')}</b> : <a href={item.href}>{item.href}</a>
                  </div>
                  <div>
                    <b>{t('core:pages.account.account-quotation.ResultRowImage.researchKeyword')}</b> : {item.keyword ? item.keyword : ""}
                  </div>
                  <div>
                    <b>{t('core:pages.account.account-quotation.ResultRowImage.negativeKeyword')}</b> : {item.negativeKeyword ? item.negativeKeyword : ""}
                  </div>
                  <div>
                    <b>{t('core:pages.account.account-quotation.ResultRowImage.image')}</b> :{" "}
                    <div
                      className="imgResults"
                      style={{
                        backgroundImage: `url(${item.src})`,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ResultRowUrl = (props: any) => {

  const item = props.item;
  return (
    <div className="row mt-10">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <div>
                  <b>URL</b> : <a href={item.url}>{item.url}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ResultRowSuggest = (props: any) => {
  const { t } = useTranslation(); // Multi Language
  const item = props.item;
  return (
    <div className="row mt-10">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <div>
                  <b>{t('core:pages.account.account-quotation.ResultRowSuggest.suggest')}</b> : {item.suggest}
                </div>
                <div>
                    <b>{t('core:pages.account.account-quotation.ResultRowSuggest.researchKeyword')}</b> : {item.keyword ? item.keyword : ""}
                  </div>
                  <div>
                    <b>{t('core:pages.account.account-quotation.ResultRowSuggest.negativeKeyword')}</b> : {item.negativeKeyword ? item.negativeKeyword : ""}
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
