import { useState, useEffect } from "react";

import { NavLink, useNavigate } from "react-router-dom";
import CoreLayout from "../../layouts/core-layout";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountEditSchema from "../../validators/AccountEditSchema";
import AccountEditInterface from "../../interfaces/AccountEditInterface";
import Swal from "sweetalert2";

import { getUserInfosRequest, updateUserInfosRequest, updateUserLanguageRequest } from '../../api/AuthApi' // Errors pages

//Multi language
import { useTranslation } from 'react-i18next';

export const AccountEdit = () => {
  const { t } = useTranslation(); // Multi Language
  const [user, setUser] = useState<any>({ firstName: "", lastName: "" });
  const [infos, setInfos] = useState<any>();
  const navigate = useNavigate();


  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<AccountEditInterface>({
    mode: "onBlur",
    defaultValues: { ...user, ...infos },
    resolver: yupResolver(AccountEditSchema),
  });

  useEffect(() => {
    let data = {
      firstName: "",
      lastName: "",
      phone: "",
      city: "",
      address: "",
      zipCode: "",
      country: "",
      language: "",
    };
    getUserInfosRequest().then(function (res: any) {
      setUser(res.data.data);
      setInfos(res.data.data);
      data.firstName = res.data.data.firstName;
      data.lastName = res.data.data.lastName;
      data.language = res.data.data.language;
      data.phone = res.data.data.phone;
      data.city = res.data.data.city;
      data.address = res.data.data.address;
      data.zipCode = res.data.data.zipCode;
      data.country = res.data.data.country;

      reset(data);
    });
  }, [reset]);

  let countries = [
    { label: t('core:global.languages.iso.countries.FR'), value: "fr", isDefault: true },
    { label: t('core:global.languages.iso.countries.DE'), value: "de", isDefault: false },
    { label: t('core:global.languages.iso.countries.EN'), value: "en", isDefault: false },
    { label: t('core:global.languages.iso.countries.IT'), value: "it", isDefault: false },
  ];
  let languages = [
    { label: t('core:global.languages.iso.language.FR'), value: "FR", isDefault: true },
    { label: t('core:global.languages.iso.language.EN'), value: "EN", isDefault: false }
  ];

  const onSubmit = (data: any) => {

    updateUserInfosRequest(data).then(function (res: any) {

      if (user.language !== data.language) {
        updateUserLanguageRequest(data.language).then(function (res: any) {
        })
      }

      Swal.fire({
        title: t('core:pages.account.AccountEdit.Swal.success'),
        icon: "success",
        showCancelButton: false,
        showConfirmButton: false,
        //buttons: false,
        timer: 1000
      });

      navigate("/account/");
    }).catch(() => {

      Swal.fire({
        title: t('core:global.error.generic'),
        icon: "error",
        showCancelButton: false,
        showConfirmButton: false,
        //buttons: false,
        timer: 1000
      });
    });
  };

  return (
    <CoreLayout template="main">
      <div className="card mb-5 mb-xl-10 mt-10">
        <div
          className="card-header border-0 cursor-pointer"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_account_profile_details"
          aria-expanded="true"
          aria-controls="kt_account_profile_details"
        >
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">{t('core:pages.account.AccountEdit.title')}</h3>
          </div>
        </div>
        <div className="collapse show">
          <form
            className="form w-100"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <div className="card-body border-top p-9">
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label required fw-bold fs-6">
                  {t('core:pages.account.AccountEdit.forms.input.FullName')}
                </label>
                <div className="col-lg-8">
                  <div className="row">
                    <div className="col-lg-6 fv-row">
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        placeholder={t('core:pages.account.AccountEdit.forms.input.lastName')}
                        {...register("lastName")}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="lastName"
                        render={({ message }) => (
                          <p className="errorMsg">{t(message)}</p>
                        )}
                      />
                    </div>
                    <div className="col-lg-6 fv-row">
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        placeholder={t('core:pages.account.AccountEdit.forms.input.firstName')}
                        {...register("firstName")}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="firstName"
                        render={({ message }) => (
                          <p className="errorMsg">{t(message)}</p>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="required">{t('core:pages.account.AccountEdit.forms.input.Phone')}</span>
                </label>
                <div className="col-lg-8 fv-row">
                  <input
                    type="tel"
                    className="form-control form-control-lg form-control-solid"
                    placeholder={t('core:pages.account.AccountEdit.forms.input.Phone')}
                    {...register("phone")}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="phone"
                    render={({ message }) => (
                      <p className="errorMsg">{t(message)}</p>
                    )}
                  />
                </div>
              </div>


              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="required">{t('core:pages.account.AccountEdit.forms.input.Address')}</span>
                </label>
                <div className="col-lg-8 fv-row">
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    placeholder={t('core:pages.account.AccountEdit.forms.input.Address')}
                    {...register("address")}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="address"
                    render={({ message }) => (
                      <p className="errorMsg">{t(message)}</p>
                    )}
                  />
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="required">{t('core:pages.account.AccountEdit.forms.input.City')}</span>
                </label>
                <div className="col-lg-8 fv-row">
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    placeholder={t('core:pages.account.AccountEdit.forms.input.City')}
                    {...register("city")}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="city"
                    render={({ message }) => (
                      <p className="errorMsg">{t(message)}</p>
                    )}
                  />
                </div>
              </div>
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="required">{t('core:pages.account.AccountEdit.forms.input.zipCode')}</span>
                </label>
                <div className="col-lg-8 fv-row">
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    placeholder={t('core:pages.account.AccountEdit.forms.input.zipCode')}
                    {...register("zipCode")}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="zipCode"
                    render={({ message }) => (
                      <p className="errorMsg">{t(message)}</p>
                    )}
                  />
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="required">{t('core:pages.account.AccountEdit.forms.input.Country')}</span>
                </label>
                <div className="col-lg-8 fv-row">
                  <select
                    {...register("country")}
                    aria-label={t('core:pages.account.AccountEdit.forms.input.SelectCountry')}
                    data-control="select2"
                    data-placeholder={t('core:pages.account.AccountEdit.forms.input.SelectCountry')}
                    className="form-select form-select-solid form-select-lg fw-bold"
                  >
                    <option value="">{t('core:pages.account.AccountEdit.forms.input.SelectCountry')}</option>
                    {countries.map((country) => (
                      <option value={country.value}>{country.label}</option>
                    ))}
                  </select>
                  <ErrorMessage
                    errors={errors}
                    name="country"
                    render={({ message }) => (
                      <p className="errorMsg">{t(message)}</p>
                    )}
                  />
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="required">{t('core:pages.account.AccountEdit.forms.input.Language')}</span>
                </label>
                <div className="col-lg-8 fv-row">
                  <select
                    {...register("language")}
                    aria-label={t('core:pages.account.AccountEdit.forms.input.SelectLanguage')}
                    data-control="select2"
                    data-placeholder={t('core:pages.account.AccountEdit.forms.input.SelectLanguage')}
                    className="form-select form-select-solid form-select-lg fw-bold"
                  >
                    <option value="">{t('core:pages.account.AccountEdit.forms.input.SelectLanguage')}</option>
                    {languages.map((language) => (
                      <option value={language.value}>{language.label}</option>
                    ))}
                  </select>
                  <ErrorMessage
                    errors={errors}
                    name="language"
                    render={({ message }) => (
                      <p className="errorMsg">{t(message)}</p>
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="card-footer d-flex justify-content-end py-6 px-9">
              <NavLink
                to="/account"
                className="btn btn-light btn-active-light-primary me-2"
              >
                {t('core:pages.account.AccountEdit.forms.button.cancel')}
                
              </NavLink>
              <button type="submit" className="btn btn-primary px-6">
                {t('core:pages.account.AccountEdit.forms.button.continue')}
              </button>
            </div>
          </form>
        </div>
      </div>

      
    </CoreLayout>
  );
};
