
import { NavLink, useLocation } from "react-router-dom";
import CoreLayout from "../../layouts/core-layout";
import { useTranslation } from 'react-i18next';

const AccountEdit = () => {
  const { t } = useTranslation(); // Multi Language
  const location = useLocation()
  console.log("in success", location.state)
  return (
    <CoreLayout template="main">
      <div className="w-lg-600px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto text-center mt-20">
        <h1 className="mb-5">{t('core:pages.shopping.cart.order.checkout-success.title')}</h1>
        {t('core:pages.shopping.cart.order.checkout-success.text1')}. {" "}
        <div className=" m-5"><h2><b>{location.state.ref}</b></h2></div>
        <div>{location.state.sentence}</div>
        {location.state.contact && <div  className=" m-5"><h2>{location.state.contact}</h2></div>}
        <div className="mt-5"><NavLink to="/dashboard/" className="btn btn-primary">{t('core:pages.shopping.cart.order.checkout-success.button')}</NavLink></div></div>
    </CoreLayout>
  );
};

export default AccountEdit;
