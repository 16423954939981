import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import DebugBar from "./DebugBar";
import Maintenance from "./Maintenance";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "react-query";
import i18n from "i18next";
import "./i18n";
import { Helmet } from "react-helmet"

import { I18nextProvider } from "react-i18next";
import ReactGA from "react-ga4";

import "./styles/index.css";

// import i18next from "i18next";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

ReactGA.initialize(`${process.env.REACT_APP_GA_TRACKING_ID}`);
console.log("Tracking number : " + process.env.REACT_APP_GA_TRACKING_ID);
// const pageview = window.location.pathname + window.location.search;
// ReactGA.send({ hitType: "pageview", page: pageview });
//ReactGA.send({ hitType: "pageview", page: pageview, title: "Custom Title" });

const queryClient = new QueryClient();
if (process.env.REACT_APP_MAINTENANCE === "true") {
  root.render(
    <I18nextProvider i18n={i18n}>
      <Maintenance />
      <DebugBar />
    </I18nextProvider>
  );
} else {
  root.render(
    <I18nextProvider i18n={i18n}>
      <React.StrictMode>
        <Helmet>
          <script type="text/javascript" src="//cdn.cookie-script.com/s/fc2140055234ff70b823acca2206cb5f.js"></script>
        </Helmet>
        <QueryClientProvider client={queryClient}>
          <React.Suspense fallback="Loading...">
            <App />
            <DebugBar />
          </React.Suspense>
        </QueryClientProvider>
      </React.StrictMode>
    </I18nextProvider>
  );
}


reportWebVitals();
