import { AxiosServicesByName } from "./AxiosServices";

/**
 * sendMessageRequest
 * - on envoie un message a l'API
 * @param {*} payload
 * @return {*}
 */
export const sendMessageRequest = async (data: any) => {
  return AxiosServicesByName.post('/emails/contact', data)
};

export const sendMessageWithoutLog = async (payload: any) => {
  return await AxiosServicesByName.post(`/emails/unauthenticate-contact`, payload);
};
