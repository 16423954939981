import { AxiosServicesByName } from "./AxiosServices";


/**
 * createOrder
 * -  Création d'un devis
 * 
 * @return {*} 
 */
export const createOrderRequest = async (payload: any) => {
  return await AxiosServicesByName.post("/order/create-quotation", payload);
};


export const getAllQuotations = async () => {
  return await AxiosServicesByName.get("order/get-all-quotations");
}

export const getQuotationByUuid = async (quotationUuid: any) => {
  return await AxiosServicesByName.get(`order/get-quotation/${quotationUuid}`);
}