import React, { FC } from "react";
import { Navigate } from "react-router-dom";

interface PropType {
  component: React.FC;
}

const ProtectedRoute: FC<PropType> = ({ component: Component }) => {
  const accesToken = localStorage.getItem("accessToken");
  if (accesToken !== null) {
    return <Component />;
  }
  return <Navigate to="/login" />;
};

export default ProtectedRoute;
