import React from 'react';


import ResultsLayout from './results-layout';
import CoreLayout from "../../../layouts/core-layout";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { useCart } from "react-use-cart";
import SentimentBadgeHelpers from "../../../helpers/SentimentBadgeHelpers";
import ExtensionsHelpers from "../../../helpers/ExtensionsHelpers";

import { getDocumentsRequest } from "../../../api/LinksApi";
import LoadingResults from './elements/LoadingResults';
import { Trash } from 'react-bootstrap-icons';



export default function DocumentsResults() {
  const { t } = useTranslation(); // Multi Language
  const { diagUUID } = useParams();
  const { addItem, inCart, removeItem } = useCart();
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    getDocumentsRequest(diagUUID).then(function (res: any) {
      setData(res)
    }).catch(() => {
      console.log("error");
    })
  }, [diagUUID]);


  return (
    <>
      <CoreLayout template="main">
        <ResultsLayout description="Internet regorge d'information qui sont issue de documents de type PDF, Word ou Excel.">
          {data.length ? (
            data?.map((item: any) => (
              <div className="card mb-3" key={item.uuid}>
                <div className="card-body">
                  <div className="row" >
                    <div className="col-md-10" >
                      <div>
                        <h3><img
                          alt="img"
                          src={
                            "https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=" +
                            item.url +
                            "&size=16"
                          }
                        />
                          &nbsp;&nbsp;{item.title} <SentimentBadgeHelpers tonality={item.tonality} /></h3>
                        <div>URL : <a href={item.url} target="blank">{item.url}</a></div>
                        <div>Type : {item.type}</div>
                        <div>Extension : <ExtensionsHelpers ext={item.ext} />{item.ext}</div>
                        <div className="text-center">

                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 text-right" >{inCart(item.id)
                      ? <button onClick={() => removeItem(item.id)} className="btn btn-danger btn-sm mt-5">{t('core:pages.osculteo.results.button.removeToCart')} <Trash /></button>
                      : <button onClick={() => addItem(item)} className="btn btn-success btn-sm mt-5">{t('core:pages.osculteo.results.button.addToCart')}</button>
                    }</div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <>
              <div className='text-center mt-20 mb-20'>
                <LoadingResults />
              </div>
            </>
          )}

        </ResultsLayout>
      </CoreLayout>
    </>
  );
}

