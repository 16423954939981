import { Button } from "react-bootstrap";
import CoreLayout from "../../layouts/core-layout";
import { ArrowLeft, Check, X } from "react-bootstrap-icons";
import { useEffect, useState } from "react";
import { getOffersByPlatform, getSubscription } from "../../api/PlatformApi";
import { loadStripe } from "@stripe/stripe-js";
import { acceptCgv, getUser } from "../../api/AuthApi";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import OfferSubscriptionInterface from "../../interfaces/OfferSuscribtioninterface";
import OfferSubscriptionSchema from "../../validators/OfferSubscriptionShema";
import { ErrorMessage } from "@hookform/error-message";
import { useNavigate } from "react-router-dom";


let stripePromise: any;
const stripeConfig = {
  price: process.env.REACT_APP_STRIPE_SUBCRIPTION_VEILLE_KEY_PRICE,
  publicKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
};

const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(`${stripeConfig.publicKey}`);
  }
  return stripePromise;
};

const Offers = () => {
  const [offers, setOffers] = useState<offersContent[]>([]);
  const [subscription, setSubscription] = useState<any[]>([]);
  const navigate = useNavigate()
  const { t } = useTranslation(); 

  useEffect(() => {
    getOffersByPlatform()
      .then(function (res: any) {
        setOffers(res.data.data[0].offers);
      })
      .catch(() => {
        console.log("error");
      });
    getSubscription()
      .then(function (res: any) {
        setSubscription(res.data.data);
      })
      .catch(() => {
      });
  }, []);
  return (
    <CoreLayout template="main">
      <div className="mt-5">
      <Button variant="light" onClick={() => navigate(-1)}> <ArrowLeft/>{" "}{t('core:pages.account.account-quotation.Offers.goBack')}</Button>
      </div>
      <div className="row g-5 p-10">
        {offers.map((el) => (
          <ContentOffer offer={el} subscription={subscription} />
        ))}
      </div>
    </CoreLayout>
  );
};
export default Offers;

const ContentOffer = (props: any) => {
  const { t } = useTranslation(); // Multi Language

  const [stripeError, setStripeError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [user, setuser] = useState<any>({});

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<OfferSubscriptionInterface>({
    //mode: "onBlur",
    resolver: yupResolver(OfferSubscriptionSchema),
  });

  useEffect(() => {
    getUser()
      .then(function (res: any) {
        setuser(res.data.data);
      })
      .catch(() => {
        console.log("error");
      });
  }, []);

  const item = {
    price: stripeConfig.price,
    quantity: 1,
  };

  const checkoutOptions = {
    lineItems: [item],
    mode: "subscription",
    successUrl: `${window.location.origin}/success`,
    cancelUrl: `${window.location.origin}/cancel`,
    clientReferenceId: `${user.id}`,
  };

  const onSubmit = async () => {
    setLoading(true);
    await acceptCgv();
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout(checkoutOptions);
    console.log("Stripe checkout error", error);
   

    if (error) setStripeError(error.message);
    setLoading(false);
  };

  if (stripeError) alert(stripeError);

  const offerContent = {
    price: props.offer.slug === "osc_free" ? 0 : 49,
    keyword: props.offer.content.nbKeywords,
    pages: props.offer.content.nbPage,
    veille: props.offer.content.veille === "true" ? true : false,
    files: props.offer.content.scrapFiles === "true" ? true : false,
    privateData: props.offer.content.nbPrivateData,
    isPrivateDataPremium:
      props.offer.content.isPremiumPrivateData === "true" ? true : false,
  };

  const found = props.subscription.find(
    (element: { offer: { slug: any } }) =>
      element.offer.slug === props.offer.slug
  );
 
  return (
    <div className="col-lg-6">
      <div className="card card-stretch card-bordered mb-5">
        <div className="card-header row g-5 p-10">
          <div className="col-lg-6">
            <h3 className="card-title d-flex align-items-center fs-1 fw-bold flex-wrap">
            {t('core:pages.account.account-quotation.Offers.offre')}  {props.offer.name}
            </h3>
          </div>
          <div className="col-lg-4">
            <div className="ms-5">
              <span className="fs-2x fw-bold">{offerContent.price}</span>
              <span className="mb-2">€</span>
              <span className="fs-7 opacity-50">
                /<span data-kt-element="period">{t('core:pages.account.account-quotation.Offers.year')}</span>
              </span>
            </div>
          </div>
        </div>
        <div className="card-body fs-5">
          <div>
            <Check color="green" size={35} />
            {offerContent.keyword} {t('core:pages.account.account-quotation.Offers.keywordNumber')} {" "}
            {offerContent.pages} {t('core:pages.account.account-quotation.Offers.google')}
          </div>
          <div>
            <Check color="green" size={35} />
            {offerContent.privateData} {t('core:pages.account.account-quotation.Offers.privateData')}
          </div>

          <div>
            {offerContent.veille ? (
              <Check color="green" size={35} />
            ) : (
              <X color="red" size={35} />
            )}
            {t('core:pages.account.account-quotation.Offers.watches')}
          </div>
          <div>
            {offerContent.isPrivateDataPremium ? (
              <Check color="green" size={35} />
            ) : (
              <X color="red" size={35} />
            )}
            {t('core:pages.account.account-quotation.Offers.extensiveResearch')}
          </div>
          <div>
            {offerContent.files ? (
              <Check color="green" size={35} />
            ) : (
              <X color="red" size={35} />
            )}
            {t('core:pages.account.account-quotation.Offers.researchFolderData')}
          </div>
        </div>
        <div className="card-footer">
          <form
            className="form w-100"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            {found ? (
              <Button className="primary" disabled>
                {" "}
                {t('core:pages.account.account-quotation.Offers.alreadySubscribed')}{" "}
              </Button>
            ) : (
              <>
                <div className="fv-row mb-10 mt-10">
                  <label className="form-check form-check-custom form-check-solid form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="true"
                      id="acceptCgv"
                      {...register("acceptCgv")}
                    />
                    <span className="form-check-label fw-bold text-gray-700 fs-6">
                      {t("guest:register.forms.input.acceptCgv.IAgree")}
                      <a href="/legals/cgv.html" target="blank">
                        {t(
                          "guest:register.forms.input.acceptCgv.TermsAndConditions"
                        )}
                      </a>
                      .
                    </span>
                  </label>
                  <ErrorMessage
                    errors={errors}
                    name="acceptCgv"
                    render={({ message }) => (
                      <p className="errorMsg">{t(message)}</p>
                    )}
                  />
                </div>

                <Button type="submit" className="primary" disabled={isLoading}>
                  {" "}
                  {t('core:pages.account.account-quotation.Offers.subscribed')}{" "}
                </Button>
              </>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export type offersContent = {
  slug: string;
  name: string;
  content: Content;
  stripeProductId: string;
  id: number;
  durationInDays: null;
  createdAt: Date;
};

export type Content = {
  nbPage: string;
  veille: string;
  nbPseudo: string;
  nbKeywords: string;
  scrapFiles: string;
  nbPrivateData: string;
  isPremiumPrivateData: string;
};
