
import * as Yup from "yup";

/** keyword */
export const keywordRules = Yup.string()
  .min(2, "validator:rules.KeywordsRules.keywordRules.min")
  .max(100, "validator:rules.KeywordsRules.keywordRules.max");

/** negativeKeyword */
export const negativeKeywordRules = Yup.string()
  .min(0, "validator:rules.KeywordsRules.negativeKeywordRules.min")
  .max(100, "validator:rules.KeywordsRules.negativeKeywordRules.max");