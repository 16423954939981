import { Navigate, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import { NavLink } from "react-router-dom";
import ButtonGoogle from "./SocialButton/ButtonGoogle";
import ButtonFacebook from "./SocialButton/ButtonFacebook";
import CoreLayout from "../../layouts/core-layout";
import LoginSchema from "../../validators/LoginSchema";
import LoginInterface from "../../interfaces/LoginInterface";
import ReactGA from "react-ga4";
//Axios Instance
import { authLoginRequest, getUserInfosRequest, googleLoginRequest } from "../../api/AuthApi"; // Errors pages

// Multilanguage
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useCart } from "react-use-cart";
import { getAllDiagnosisRequest } from "../../api/DiagnosisApi";
import React from "react";
import { DiagnosisContext } from "../../contexts/DiagnosisContext";
import { getSubscription } from "../../api/PlatformApi";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";


/**
 *
 *
 * @export
 * @return {*}
 */

export default function Login() {
  const { t } = useTranslation(); // Multi Language
  const [passwordShown, setPasswordShown] = useState(false);
  const { emptyCart } = useCart();
  emptyCart();

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  const value = React.useContext(DiagnosisContext);
  let navigate = useNavigate(); //Permet de changer de page après validation
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginInterface>({
    //mode: "onBlur",
    resolver: yupResolver(LoginSchema),
  });

  /**
   * Envoied du formulaire de login
   *
   * @param {*} data
   */
  const onSubmit = (data: any) => {
    data.password = data.password.trim();
    authLoginRequest(data).then(function (response: any) {
      localStorage.setItem("accessToken", response.data.data.access_token);
      getUserInfosRequest().then(function (res: any) {
        // On recupere la langue et on la set
        if (res.data.user.language) {
          localStorage.setItem(
            "i18nextLng",
            response.data.language.toLowerCase()
          );
        }
      });

      ReactGA.event({
        category: "Auth",
        action: "logged",
        label: window.location.pathname + window.location.search,
      });

      getAllDiagnosisRequest().then(function (diagnosisResponse: any) {
        let offerSlug: any;
        getSubscription()
          .then(function (res: any) {
            offerSlug = res.data.data[0].offer.slug;
            console.log("offerSlugthen", offerSlug);
          })
          .catch(() => {});

        if (diagnosisResponse.data.data.length === 0) {
          value.offerSlug = offerSlug;
          console.log("offerSlugif", offerSlug);

          navigate("/diagnosis");
        } else {
          value.offerSlug = offerSlug;
          navigate("/dashboard");
        }
      });
    });
  };

  return (
    <CoreLayout template="auth">
      <div className="">
        <div className="platform-topBar w-lg-500px mx-auto"></div>
        <div className="w-lg-500px bg-body  shadow-sm p-10 p-lg-15 mx-auto">
          <div className="text-center platform-logoBlock mb-10">
            <img
              src={`${process.env.REACT_APP_CDN_PLATFORMS}/logo.png`}
              className="platform-auth-logo"
              alt="Logo"
            />
          </div>
          <form
            className="form w-100"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <div className="text-center mb-10">
              <h1 className="text-dark mb-3">{t("guest:login.title")}</h1>
              <div className="text-gray-400 fw-bold fs-4">
                {t("guest:login.NewHere")}{" "}
                <NavLink to="/register" className="link-primary fw-bolder">
                  {t("guest:login.CreateAnAccount")}
                </NavLink>
              </div>
            </div>

            <div className="fv-row mb-10">
              <label className="form-label fs-6 fw-bolder text-dark">
                {t("guest:login.forms.input.email")}
              </label>
              <input
                required
                className="form-control form-control-lg form-control-solid"
                type="text"
                id="email"
                placeholder={t("guest:login.forms.input.email")}
                autoComplete="email"
                autoFocus
                tabIndex={Number(1)}
                {...register("email")}
              />
              <ErrorMessage
                errors={errors}
                name="email"
                render={({ message }) => (
                  <p className="errorMsg">{t(message)}</p>
                )}
              />
            </div>

            <div className="fv-row mb-10">
              <div className="mb-1">
                <label className="form-label fw-bolder text-dark fs-6 mb-0">
                  {t("guest:login.forms.input.password")}{" "}
                </label>{" "}
                <NavLink
                  to="/forgot-password"
                  className="link-primary fs-6 fw-bolder"
                >
                  {t("guest:login.ForgotPassword")}
                </NavLink>
              </div>
              <div className="position-relative mb-3">
                <input
                  required
                  className="form-control form-control-lg form-control-solid"
                  placeholder={t("guest:login.forms.input.password")}
                  type={passwordShown ? "text" : "password"}
                  id="password"
                  autoComplete="current-password"
                  tabIndex={Number(2)}
                  {...register("password")}
                />
                <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2">
                  <div onClick={togglePassword}>
                    {passwordShown ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-eye fs-2"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-eye-slash fs-2"
                        viewBox="0 0 16 16"
                      >
                        <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                        <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                        <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                      </svg>
                    )}
                  </div>
                </span>
              </div>

              <ErrorMessage
                errors={errors}
                name="password"
                render={({ message }) => (
                  <p className="errorMsg">{t(message)}</p>
                )}
              />
            </div>

            <div className="text-center">
              <button
                type="submit"
                className="btn btn-lg btn-primary w-100 mb-5"
              >
                {t("guest:login.continue")}
              </button>
            </div>
            <SocialAuthBlock />
          </form>
        </div>
      </div>
    </CoreLayout>
  );
}

function SocialAuthBlock() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (process.env.REACT_APP_AUTH_SOCIAL === "true") {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
         <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}>

        <GoogleLogin
          onSuccess={async (credentialResponse) => {
            const resp = await googleLoginRequest(credentialResponse.credential as string);
            console.log("resp", resp);
        
            const accessToken = resp.data.access_token;
            localStorage.setItem("accessToken", accessToken);
            navigate("/dashboard");
          }}
          onError={() => {
            console.log("Login Failed");
          }}
        />
        </GoogleOAuthProvider>
      </div>

    );
  }
  return <></>;
}