import { AxiosServicesByName } from "./AxiosServices";

/**
 * createDiagnosisBasicRequest
 * - pour generé un diag
 * @param {*} payload
 * @return {*}
 */
export const createDiagnosisBasicRequest = async (payload: any) => {
  return await AxiosServicesByName.post("/basic-diagnosis/basic", payload);
};


/**
 * createDiagnosisRequest
 * - pour generé un diag
 * @param {*} payload
 * @return {*}
 */
export const createDiagnosisRequest = async (payload: any) => {
  return await AxiosServicesByName.post("/diagnosis/diagnosis", {payload});
};


/**
 * getDiagnosisById 
 * - Recupere les diagnostique par son ID
 * @param {*} diagId
 * @return {*} 
 */
export const getDiagnosisByUIIDRequest = async (diagUUID: any) => {
  return await AxiosServicesByName.get(`/diagnosis/diagnosis/${diagUUID}`);
};

/**
 * getDiagnosisById 
 * - Recupere les diagnostique par son ID
 * @param {*} diagId
 * @return {*} 
 */
export const getDiagnosisByGroupUuid = async (groupuuid: any) => {
  return await AxiosServicesByName.get(`/diagnosis/diagnosis/group/${groupuuid}`);
};

/**
 * getAllDiagnosis
 *
 * @return {*} 
 */
export const getAllDiagnosisRequest = async () => {
  return await AxiosServicesByName.get(`/diagnosis/get-all`);
};

/**
 * getAvailableDiagnosis
 *
 * @return {*} 
 */
export const getAvailableDiagnosisRequest = async () => {
  return await AxiosServicesByName.get(`/diagnosis/get-available`);
};


/**
 * getAllOffersSubscriptionsRequest
 *
 * @return {*} 
 */
export const getAllOffersSubscriptionsRequest = async () => {
  return await AxiosServicesByName.get(`/offers/subscriptions`);
};


/**
 * getSocialInstagramRequest
 *
 * @return {*} 
 */
export const getSocialInstagramRequest = async () => {
  return await AxiosServicesByName.get(`/social/instagram`);
};

/**
 * CountTonality by diag
 * - Recupere les diagnostique par son ID
 * @param {*} diagId
 * @return {*} 
 */
export const countTonality = async (diagUUID: any) => {
  return await AxiosServicesByName.get(`/basic-diagnosis/count-tonality/${diagUUID}`);
};

export const meNotme = async (payload: any) => {
  return await AxiosServicesByName.post("/link/meNotMe", payload);
};

